import { useState } from "react";
import { close_icon, info_icon } from "../../assets/images";
import { languages } from "../utils";
import styled from "styled-components";

function RetryPopup({ setPopupVisible = () => { }, handlePopupSubmit = () => { } }) {

    const [language, setLanguage] = useState('en')

    const onSubmit = () => {
        const lang = language
        handlePopupSubmit(lang);
        setLanguage('en');
        setPopupVisible(false);
    }

    return <Popup>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div></div>
            <div style={{ fontSize: "20px", fontWeight: 600, color: "#fff" }}>Not the right lyrics?</div>
            <span onClick={() => { setPopupVisible(false) }} style={{ cursor: "pointer" }}>
                <img src={close_icon} alt="" onClick={() => {
                    setLanguage("en");
                    setPopupVisible(false);
                }} />
            </span>
        </div>
        <div style={{padding: "30px 0"}}>
            <div>
                <div style={{ fontSize: "14px", fontWeight: 600, color: "#fff" }}>Retry by selecting the language of the song.</div>
                <select style={{
                    width: "100%",
                    height: "40px",
                    background: "#3A3B3B",
                    color: "#fff",
                    border: "none",
                    marginTop: "10px",
                    borderRadius: "4px",
                    padding: "8px"
                }}
                    value={language}
                    onChange={e => { setLanguage(e.target.value) }}>
                    {languages.map((item, index) => (
                        <option key={index.toString()} value={item.code} >
                            {item.language}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                marginTop: "8px"
            }}>
                <img src={info_icon} alt="" width="12px" />
                <div style={{ color: "#fff", fontSize: "10px", fontWeight: 400, lineHeight: "18px" }}>Language selection can improve the quality of the lyrics.</div>
            </div>
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <button style={{
                background: "#0072D5",
                padding: "18px 50px",
                borderRadius: "4px",
                border: "none",
                color: "#fff",
                cursor: "pointer",
            }}
                onClick={onSubmit} >
                Retry
            </button>
        </div>
    </Popup>
}

const Popup = styled.div`
    max-width: 500px;
    width: 25%;
    background: #282828;
    padding: 16px 24px;
    border-radius: 12px;
    max-height: 90vh;
    overflow-y: auto;

    select:focus{
      outline: none;
    }

    @media (min-width: 769px) and (max-width: 1000px){
      width: 35%;
    }
    @media (min-width: 501px) and (max-width: 768px){
      width: 40%;
    }

    @media (max-width: 500px){
      width: 70%;
    }`

export default RetryPopup;