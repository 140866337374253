
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import { clearToken, getToken } from '../utils';

const useSubscription = () => {
    const [subscription1, setSubscription] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSubscription = async () => {
            const token = getToken();

            try {
                const response = await axios.get(`${config.apiUrl}/subscription/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.status === 200) {
                    if(response.data.subscription_type){
                        setSubscription(response.data.subscription_type);
                        localStorage.setItem("subscription", response.data.subscription_name);
                        localStorage.setItem("subscription_date", response.data.subscribed_at);
                        localStorage.setItem("subscription_enddate", response.data.period_end);
                        localStorage.setItem("subscription_status", response.data.status);
                        localStorage.setItem("subscription_type", response.data.subscription_type);
                       
                        localStorage.setItem("period_end", response.data.period_end);
                    }else{
                        setSubscription(null);
                        localStorage.setItem("subscription", null);
                        localStorage.setItem("subscription_date", null);
                        localStorage.setItem("subscription_enddate",null);
                        localStorage.setItem("subscription_status",null);   
                    }
                    // setSubscription(response.data.subscription_type);
                    // localStorage.setItem("subscription", response.data.subscription_name);
                    // localStorage.setItem("subscription_date", response.data.subscribed_at);
                    // localStorage.setItem("subscription_enddate", response.data.period_end);
                    // localStorage.setItem("subscription_status", response.data.status);
                }
            } catch (error) {
                if(error.response && error.response.status === 401) {
                    clearToken();
                    navigate("/");
                  } 
            }
        };

        fetchSubscription();
    }, []);

    return subscription1;
};

export default useSubscription;
