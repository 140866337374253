import React, { useState, useEffect, useRef } from "react";
import Pre_nav from "../navigation/premium_nav";
import VideoPlayer from "./VideoPlayer";
import Footer from "../footer_lan";
import { getToken, clearToken } from "../utils";
import config from "../../config";
import axios from "axios";
import PlaylistForm from "../playlist/add_playlist_popup";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import styled, { keyframes } from "styled-components";
import CreatePlaylistPopup from "../user-page/playlist_popup";
import useSubscription from "../user-page/subscription";
import html2canvas from "html2canvas";
import copylink from "../../assets/images/copylink.svg";
import tick_snack_bar from "../../assets/images/tick_snack_bar.svg";
import close_snack_bar from "../../assets/images/close_snack_bar.svg";
import {
  add_icon_playlist,
  auto_scroll,
  copy_url,
  delete_button,
  download_blue,
  download_free,
  edit_chords,
  retry_chords,
  save_edit,
  white_close,
  add_to_playlisted,
  arrow,
} from "../../assets/images";
import Snackbar from "@mui/material/Snackbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUser } from "react-icons/fa";

const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
  "& .MuiSnackbarContent-root": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    color: "black",
  },
  "& .MuiSnackbarContent-message": {
    display: "flex",
    alignItems: "center",
  },
}));

const CustomToast = ({ closeToast }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    {/* <FaUser style={{ marginRight: '10px' }} /> */}
    <img
      src={tick_snack_bar}
      alt="check icon"
      style={{ marginRight: "8px", width: "24px", height: "24px" }}
    />

    <span style={{ flex: 1 }}>link copied</span>
    {/* <button onClick={closeToast} style={{
      marginLeft: '10px',
      backgroundColor: '#F0984B',
      color: '#FFFFFF',
      border: 'none',
      borderRadius: '4px',
      padding: '5px 10px',
      cursor: 'pointer'
    }}>Close</button> */}
  </div>
);

const showToast = () => {
  toast(<CustomToast />, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
const MusicApp2 = () => {
  const token = getToken();
  const { id } = useParams();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 991;
  const [loading, setLoading] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [result_Cords, setresult_Cords] = useState([]);
  const [uniqueChords, setUniqueChords] = useState([]);
  const [imageSrc, setImageSrc] = useState(edit_chords);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  // const [subscription1, setSubscription] = useState("null");
  // let result_Cords;
  const navigate = useNavigate();
  if (token == null) {
    navigate("/");
  }
  const [chordsTs, setchordsTs] = useState([]);
  const playlist_added_successfully = localStorage.getItem(
    "playlist_added_successfully"
  );
  let isUserScrolling = false;
  const [activeButton, setActiveButton] = useState(null);
  const scrollIntervalRef = useRef(null);
  const [title1, setTitle1] = useState("");
  const [thumb_img, setThumbImg] = useState("");
  const [result1, setResult1] = useState("");
  const [url, setUrl] = useState("");
  const [id0, setId0] = useState("");
  const [myVariable, setMyVariable] = useState([]);
  const [transposedValue, setTransposedValue] = useState(0);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const [scrollSpeed, setScrollSpeed] = useState(0); 
  const [combinedRowsWithMaxWidths, setCombinedRowsWithMaxWidths] = useState(
    []
  );
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [down, setDown] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [wordsTs, setwordsTs] = useState([]);
  const [selectedName, setSelectedName] = useState("formattedName");
  const [scrollDelay, setScrollDelay] = useState(50);
  const [dragging, setDragging] = useState(false);
  const [showplaylist_popup, setshowplaylist_popup] = useState(false);
  const [PopupVisible, setPopupVisible] = useState(false);
  const hiddenParam = location.state?.hiddenParam;
  // const [link, setlink] = useState("");

  let link = `https://www.youtube.com/embed/${id}`;
  let videoUrl;
  let videoId;
  const subscription1 = useSubscription();
  const [open, setOpen] = useState(false);

  function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowWidth;
  }

  const showToast1 = () => {
    toast(<CustomToast1 message={"Playlist Updated Successfully"} />, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const CustomToast1 = ({ message }) => {
    return (
      <div>
        <p>{message}</p>
      </div>
    );
  };

  const add_playlist1 = async (playlistName) => {
    try {
      const response = await axios.post(
        `${config.apiUrl}/playlists/${playlistName}/add`,
        { records: [id0] }, // Replace `id1` with your actual record ID(s)
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const textToCopy = `Added into ${playlistName} playlist`;

        // Copy text to clipboard
        navigator.clipboard
          .writeText(textToCopy)
          .then(() => {
            // Show toast after successful copy with a dynamic message
            // showToast1(textToCopy); // Pass the message to the toast
          })
          .catch((err) => {
            // console.error("Failed to copy text: ", err);
          });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  useEffect(() => {
    if (playlist_added_successfully) {
      showToast1();
      localStorage.removeItem("playlist_added_successfully");
    }
  }, [playlist_added_successfully]);

  useEffect(() => {
    setLoading(true);
    const fetchPlaylists = async () => {
      try {
        const response = await axios.get(
          `${config.apiUrl}/playlists/__default_playlist__`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          const records = response.data.records;

          if (records.length === 0) {
            // setCount(0);
          } else {
            const mappedPlaylists = records
              .filter((record) => record.state === "failed")
              .map((record) => (
                
                {
                title: record.title,
                url: record.url,
                id: record.request_id,
                image: record.thumbnail_url,
              }));

            setPlaylists(mappedPlaylists.slice(0, 11));
          }
          setLoading(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
      }
    };

    fetchPlaylists();
  }, []);

  useEffect(() => {
    // Clear the previous interval if speed changes
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
    }

    // If scrollSpeed is greater than 0, set up a new interval
    if (scrollSpeed > 0) {
      scrollIntervalRef.current = setInterval(() => {
        window.scrollBy(0, scrollSpeed); // Scroll the page vertically based on the scrollSpeed
      }, 100); // Adjust this value to control the smoothness of scrolling
    }

    // Cleanup interval when the component unmounts or scrollSpeed is 0
    return () => {
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
        scrollIntervalRef.current = null;
      }
    };
  }, [scrollSpeed]);

  const handleyouClick_playlist = () => {
    setshowplaylist_popup(true);
  };
 
  const handleyouClick_playlist1 = () => {
    setshowplaylist_popup(false);
  };

  useEffect(() => {
    return () => {
      stopAutoScroll(); // Stop auto-scroll when the component is unmounted or navigating away
    };
  }, []);

  const toggleAutoScroll = () => {
    if (isAutoScrolling) {
      setIsAutoScrolling(false);
      stopAutoScroll();
    } else {
      setIsAutoScrolling(true);
      startAutoScroll();
    }
  };
  const handleMouseDown = (e) => {
    if (!isMobile) {
      setDragging(true);
      setOffset({
        x: e.clientX - position.x,
        y: e.clientY - position.y,
      });
    }
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    setPosition({
      x: e.clientX - offset.x,
      y: e.clientY - offset.y,
    });
  };

  const handleMouseUp = () => {
    if (!isMobile) {
      setDragging(false);
    }
  };

  const processRows = () => {
    const rows = [];

    const groupedChords = {};

    // Group chords by their timing (overwrite if multiple chords have the same timing)
    chordsTs.forEach((chord) => {
      const chordTime = chord.time === 0 ? 0.1 : chord.time;
      groupedChords[chordTime] = chord; // Keeps the last chord for the same timing
    });

    // Extract the list of chords without timing
    const gp = Object.values(groupedChords).map((chord) => ({
      time: chord.time,
      value: chord.chord,
    }));

    gp.forEach((chord) => {
      rows.push({
        type: "chord",
        time: chord.time === 0 ? 0.1 : chord.time, // Use time as alignment point
        value: chord.value,
      });
    });

    wordsTs.forEach((word) => {
      rows.push({
        type: "word",
        time: word.start, // Use word start time for alignment
        value: word.word,
      });
    });

    // Sort rows by time
    rows.sort((a, b) => a.time - b.time);

    // Initialize current rows for chords and words
    let currentChordsRow = [];
    let currentWordsRow = [];
    let finalRows = [];

    rows.forEach((row) => {
      if (row.type === "chord") {
        // Add chord to the current row, aligned with its time
        currentChordsRow.push(row.value);
        currentWordsRow.push(""); // Leave a gap in words row for this chord
      } else if (row.type === "word") {
        if (row.value.includes("\n")) {
          // Split the word by `\n` and process each part
          const wordParts = row.value.split("\n");

          // Add the first part of the word before the `\n`
          currentWordsRow.push(wordParts[0]);
          currentChordsRow.push(""); // Leave a gap for the chord

          // Push the current row of chords and words to finalRows
          finalRows.push({
            type: "chords",
            row: currentChordsRow,
          });
          finalRows.push({
            type: "words",
            row: currentWordsRow,
          });

          // Start new rows after `\n`
          currentChordsRow = [];
          currentWordsRow = [];

          // If there are additional parts after the `\n`, continue processing
          if (wordParts.length > 1) {
            currentWordsRow.push(wordParts[1]);
            currentChordsRow.push(""); // Keep the gap in chords row for the new line
          }
        } else {
          // Append the word to the current row
          currentWordsRow.push(row.value);
          currentChordsRow.push(""); // Leave a gap in the chords row for this word
        }
      }
    });

    // Push any remaining rows if the loop finishes and there's still content
    if (currentChordsRow.length > 0 || currentWordsRow.length > 0) {
      finalRows.push({
        type: "chords",
        row: currentChordsRow,
      });
      finalRows.push({
        type: "words",
        row: currentWordsRow,
      });
    }

    return finalRows;
  };
        
  useEffect(() => {
    const fetchPublicSongs = async () => {
      try {
        const response_status = await axios.get(
          `${config.apiUrl}/public/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response_status.status === 200) {
          if (response_status.data.state === "complete") {
            const thumbnailUrl =
            response_status.data.thumbnail_url.split("?")[0];
            setTitle1(response_status.data.title);
            setId0(response_status.data.request_id);
            setThumbImg(thumbnailUrl);
            setchordsTs(response_status.data.chords_ts);
            setwordsTs(response_status.data.words_ts_newline);
            setResult1(response_status.data.result);
            setUrl(response_status.data.url);
            setresult_Cords(response_status.data.chords);
            link = `https://www.youtube.com/embed/${url}`;
          } else if (
            response_status.data.state === "pending" ||
            response_status.data.state === "in_progress"
          ) {
            setTimeout(fetchPublicSongs, 1000);
          } else {
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
      }
    };

    fetchPublicSongs();
  }, []);

  const calculateWidth = (text) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = "15px Arial"; // Set the font size and style to match your UI
    const metrics = context.measureText(text);
    return metrics.width;
  };

  const sharp_to_flat = () => {
    const updatedRows = combinedRowsWithMaxWidths.map((row) => {
      if (row.type === "chords") {
        const updatedChords = row.row.map((chord) => {
          const rootNote = Object.keys(sharpToFlatRootMap).find((sharp) =>
            chord.startsWith(sharp)
          );
          if (rootNote) {
            return chord.replace(rootNote, sharpToFlatRootMap[rootNote]);
          }
          return chord;
        });

        return { ...row, row: updatedChords };
      }
      return row;
    });

    setCombinedRowsWithMaxWidths(updatedRows);
    setMyVariable(createMyVariable(updatedRows));
  };

  const flat_to_sharp = () => {
    const updatedRows = combinedRowsWithMaxWidths.map((row) => {
      if (row.type === "chords") {
        const updatedChords = row.row.map((chord) => {
          const rootNote = Object.keys(flatToSharpRootMap).find((flat) =>
            chord.startsWith(flat)
          );
          if (rootNote) {
            return chord.replace(rootNote, flatToSharpRootMap[rootNote]);
          }
          return chord;
        });
        return { ...row, row: updatedChords };
      }
      return row;
    });

    setCombinedRowsWithMaxWidths(updatedRows);
    setMyVariable(createMyVariable(updatedRows));
  };

  useEffect(() => {
    const combinedRows = processRows();
    const combinedRowsWithMaxWidths = [];
    let tempChordsRow = [];
    let isGroupedChords = false;
    combinedRows.forEach((row, index, array) => {
      if (row.type === "chords") {
        const nextRow = array[index + 1];
        const isNextChordsWithoutWords =
          nextRow &&
          nextRow.type === "chords" &&
          (!array[index + 2] || array[index + 2].type !== "words");

        if (!row.row.some(Boolean)) return;

        if (isNextChordsWithoutWords) {
          tempChordsRow = [...tempChordsRow, ...row.row];
          isGroupedChords = true;
        } else {
          tempChordsRow = [...tempChordsRow, ...row.row];

          const combinedWidths = tempChordsRow.map((chord) =>
            calculateWidth(chord)
          );

          combinedRowsWithMaxWidths.push({
            type: "chords",
            row: tempChordsRow,
            widths: combinedWidths,
            grouped: isGroupedChords,
          });

          tempChordsRow = [];
          isGroupedChords = false;
        }
      } else if (row.type === "words") {
        const wordWidths = row.row.map((word) => calculateWidth(word));

        const prevRow =
          combinedRowsWithMaxWidths[combinedRowsWithMaxWidths.length - 1];
        if (prevRow && prevRow.type === "chords") {
          const chordWidths = prevRow.row.map((chord) => calculateWidth(chord));
          const maxWidths = wordWidths.map((wordWidth, i) =>
            Math.max(wordWidth, chordWidths[i] || 0)
          );
          prevRow.widths = maxWidths;
          row.widths = maxWidths;
        } else {
          row.widths = wordWidths;
        }

        combinedRowsWithMaxWidths.push(row);
      }
    });

    setCombinedRowsWithMaxWidths(combinedRowsWithMaxWidths);
    setMyVariable(createMyVariable(combinedRowsWithMaxWidths));
  }, [chordsTs, wordsTs, isEditing]);

  const edit_chord = () => {
    setIsEditing((prevIsEditing) => {
      const newIsEditing = !prevIsEditing;
      setImageSrc(newIsEditing ? save_edit : edit_chords);
      return newIsEditing;
    });
  };
  let startIndex = "";
  let endIndex = "";
  if (result1.includes("```")) {
    startIndex = result1.indexOf("```") + 3;
    endIndex = result1.lastIndexOf("```");
  } else {
    startIndex = result1.indexOf("") + 3;
    endIndex = result1.lastIndexOf("");
  }

  const transposeOptions = [
    { label: "- 1", value: -1 },
    { label: "+ 1", value: 1 },
  ];

  let lines = [];
  if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
    const extractedText = result1.substring(startIndex, endIndex).trim();
    lines = extractedText.split("\n");
  }
  let downlines = [];

  const checkAndColorLines = (line) => {
    const hasMoreThan3Spaces = line.match(/\s{4,}/);
    const segments = [];
    let currentSegment = "";
    for (let i = 0; i < line.length; i++) {
      if (line[i] === " ") {
        if (currentSegment !== "") {
          segments.push(currentSegment);
          currentSegment = "";
        }
        segments.push(" ");
      } else {
        currentSegment += line[i];
      }
    }
    if (currentSegment !== "") {
      segments.push(currentSegment);
    }
    if (hasMoreThan3Spaces || line.length < 4) {
      downlines.push(line);
      // let spaceCount = segments.filter(segment => segment === ' ').length;
      return (
        <Verse>
          {segments.map((segment, index) =>
            segment === " " ? (
              <React.Fragment key={index}>&nbsp;</React.Fragment>
            ) : (
              <span
                key={index}
                style={{
                  backgroundColor: "#F0984B",
                  padding: "2px 4px",
                  borderRadius: "4px",
                  color: "#FFFFFF",
                }}
              >
                {segment}
              </span>
            )
          )}
        </Verse>
      );
    } else {
      downlines.push(line);
      return <Verse style={{ color: "#FFFFFF" }}>{line}</Verse>;
    }
  };
  downlines.push(myVariable);
  const retry_chord = async () => {
    const encodedRequestId1 = encodeURIComponent(id);
    try {
      setLoading(true);
      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/retry`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        navigate("/user");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      // setLoading(false);
    }
  };

  const sharpToFlatRootMap = {
    "C#": "Db",
    "D#": "Eb",
    "F#": "Gb",
    "G#": "Ab",
    "A#": "Bb",
  };
  const flatToSharpRootMap = {
    Db: "C#",
    Eb: "D#",
    Gb: "F#",
    Ab: "G#",
    Bb: "A#",
  };

  const createMyVariable = (rows) => {
    return rows.map((row, rowIndex) => (
      <Line key={rowIndex} type={row.type}>
        {row.row.map((item, itemIndex) => (
          <Box
            key={itemIndex}
            type={row.type}
            width={row.widths[itemIndex]}
            editMode={isEditing}
            isGrouped={row.grouped}
          >
            {item}
          </Box>
        ))}
      </Line>
    ));
  };

  const convertSharpToFlat = (chord) => {
    const root = chord.slice(0, 2);
    const rest = chord.slice(2);
    const flatRoot = sharpToFlatRootMap[root] || root;
    return flatRoot + rest;
  };

  function transposeChord(chord, semitones) {
    // Extract root note (C, D#, Bb, etc.) and suffix (maj7, m, dim, etc.)
    const regex = /^([A-G][b#]?)(.*)$/; // Match root note (A-G with optional # or b) and suffix
    const matches = chord.match(regex);

    if (!matches) {
      return chord; // Return original chord if it doesn't match the regex
    }

    const root = matches[1]; // Root note
    const suffix = matches[2]; // Chord suffix (e.g., m7, maj, dim, etc.)
    function transposeChordsArray1(chords, semitones) {
      return chords.map((chord) => transposeChord(chord, semitones));
    }
    const sharpScale = [
      "C",
      "C#",
      "D",
      "D#",
      "E",
      "F",
      "F#",
      "G",
      "G#",
      "A",
      "A#",
      "B",
    ];
    const flatScale = [
      "C",
      "Db",
      "D",
      "Eb",
      "E",
      "F",
      "Gb",
      "G",
      "Ab",
      "A",
      "Bb",
      "B",
    ];
    // Determine whether to use sharp or flat scale
    let scale;
    if (sharpScale.includes(root)) {
      scale = sharpScale;
    } else if (flatScale.includes(root)) {
      scale = flatScale;
    } else {
      return chord; // If root is not found, return the original chord
    }

    // Find the root note's index in the scale and transpose it
    let index = scale.indexOf(root);
    let newIndex = (index + semitones + scale.length) % scale.length;

    // Return the transposed chord (transposed root + original suffix)
    return scale[newIndex] + suffix;
  }

  function transposeChords(chords, semitones) {
    return chords.map((chordData) => ({
      time: chordData.time,
      chord: transposeChord(chordData.chord, semitones),
    }));
  }
  function transposeChordsArray1(chords, semitones) {
    return chords.map((chord) => transposeChord(chord, semitones));
  }

  const minus_one1 = async () => {
    let transposedUp = transposeChords(chordsTs, -1);
    let transup = transposeChordsArray1(uniqueChords, -1);

    setUniqueChords(transup);
    setchordsTs(transposedUp);
  };

  const [position, setPosition] = useState({
    x: window.innerWidth / 2 - 100, // Center horizontally
    y: window.innerHeight - 100, // 100px from the bottom
  });

  const minus_one2 = async (newTransposedValue) => {
    let transposedUp = transposeChords(chordsTs, -1);
    let transup = transposeChordsArray1(uniqueChords, -1);

    setUniqueChords(transup);
    setchordsTs(transposedUp);
  };

  const plus_one2 = async (newTransposedValue) => {
    let transposedDown = transposeChords(chordsTs, 1);
    let transdown = transposeChordsArray1(uniqueChords, 1);

    setUniqueChords(transdown);
    setchordsTs(transposedDown);
  };
  const closepopup = () => {
    setPopupVisible(false);
    window.location.reload();
  };

  const handleClick = async (value) => {
    let newTransposedValue;

    if (value === 1) {
      newTransposedValue = (transposedValue + value) % 12;
    } else if (value === -1) {
      newTransposedValue = transposedValue - 1;
      if (newTransposedValue < -11) {
        newTransposedValue = 0;
      }
    }
    setTransposedValue(newTransposedValue);
    setActiveButton(value);

    if (value === -1) {
      await minus_one2(newTransposedValue);
    } else if (value === 1) {
      await plus_one2(newTransposedValue);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF("landscape"); // Set orientation to landscape
    doc.setFontSize(10); // Set a smaller font size
    doc.text(title1, 10, 10);

    const lineHeight = 12; // Adjusted line height
    const margin = 10; // Adjust margins
    const maxLinesPerPage = Math.floor(
      (doc.internal.pageSize.height - margin) / lineHeight
    );
    const pageWidth = doc.internal.pageSize.width - 2 * margin;
    let y = margin + 10;
    let lineIndex = 0;

    // Function to extract text from myVariable preserving the layout
    const extractTextFromJSX = (jsxElements) => {
      let lines = [];

      // Iterate over each Line component
      React.Children.forEach(jsxElements, (lineElement) => {
        if (React.isValidElement(lineElement)) {
          let lineContent = [];
          let totalWidth = 0;

          // Iterate over each Box component within the Line
          React.Children.forEach(lineElement.props.children, (boxElement) => {
            if (React.isValidElement(boxElement)) {
              const boxText = boxElement.props.children; // Get the text in the box
              const boxWidth = boxElement.props.width; // Default width if not provided

              // Calculate the text width based on the content
              const textWidth = doc.getTextWidth(boxText) + 10; // Add some padding
              const finalWidth = boxWidth; //Math.min(boxWidth, textWidth); // Use the greater of defined width or text width

              // Collect the text and width
              lineContent.push({ text: boxText, width: finalWidth });
              totalWidth += finalWidth; // Increment total width for alignment
            }
          });

          // Store the line's content and total width
          lines.push({ content: lineContent, totalWidth });
        }
      });

      return lines;
    };

    // Extract text content from myVariable
    const extractedLines = extractTextFromJSX(myVariable);

    // Now use the extracted lines to generate the PDF
    extractedLines.forEach((line) => {
      let yOffset = y; // Reset yOffset for each line
      let totalWidth = 0; // Reset total width for each line

      line.content.forEach(({ text, width }) => {
        // Ensure text is positioned correctly considering the total width so far
        doc.text(text, margin + totalWidth, yOffset);
        totalWidth += width; // Increment the total width for the next text
      });

      y += lineHeight; // Move down for the next line
      lineIndex++;

      // Check if we need to add a new page
      if (lineIndex >= maxLinesPerPage) {
        doc.addPage();
        y = margin;
        lineIndex = 0;
      }
    });

    const pdfBlob = doc.output("blob");
    saveAs(pdfBlob, title1 + ".pdf");
  };
  const startAutoScroll = () => {
    setIsAutoScrolling(true);
    scrollIntervalRef.current = setInterval(() => {
      window.scrollBy(0, scrollSpeed);

      // Stop scrolling at the end of the page
      if (
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight
      ) {
        stopAutoScroll();
      }
    }, scrollDelay);
  };

  // Stop the auto-scroll by clearing the interval
  const stopAutoScroll = () => {
    clearInterval(scrollIntervalRef.current);
    setIsAutoScrolling(false);
  };
  const increaseScrollSpeed = () => {
    setScrollSpeed((prevSpeed) => Math.min(prevSpeed + 1, 5));
  };

  // Adjust scroll speed (decrease)
  const decreaseScrollSpeed = () => {
    setScrollSpeed((prevSpeed) => Math.max(0, prevSpeed - 1, 0));
  };

  const handleSongCardClick = async (requestId, url) => {
    setLoading(true);
    try {
      const encodedRequestId = encodeURIComponent(requestId);
      const response_status = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response_status.status === 200) {
        // setLoading(true);

        if (response_status.data.state === "complete") {
          navigate("/chords", { state: { result1: response_status.data } });
          // setLoading(false);
        } else if (
          response_status.data.state === "pending" ||
          response_status.data.state === "in_progress"
        ) {
          setTimeout(() => handleSongCardClick(requestId, url), 1000);
        } else {
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
      // console.error("Status check failed:", error);
    }
  };
  const formatChordName = (chord) => {
    let processedChord = chord
      .replace(/Major/g, "maj")
      .replace(/major/g, "maj")
      .replace(/Minor/g, "min")
      .replace(/minor/g, "min")
      .replace(/diminished/g, "dim")
      .replace(/augmented/g, "aug")
      .replace(/add/g, "add")

      .replace(/m/g, "min")
      .replace(/#/g, "__")
      .replace(/dimin/g, "dim")
      .replace(/\/[0-9]+/g, "_");

    // Handle single letter or sharp chords (e.g., E -> Emaj, C# -> C#maj)
    if (
      processedChord.length === 1 ||
      (processedChord.length === 3 && processedChord.includes("__"))
    ) {
      processedChord += "maj";
    }

    return processedChord;
  };

  const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => {
      images[item.replace("./", "").replace(".svg", "")] = r(item);
    });

    return images;
  };

  const images = importAll(
    require.context("../../assets/cords", false, /\.svg$/)
  );
  const View_all_Songs = (event) => {
    navigate("/all_songs");
  };

  const handleCopyLink = () => {
    const textToCopy = url;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        // setOpen(true);
        showToast();
      })
      .catch((err) => {
        // console.error("Failed to copy text: ", err);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const retry = async (id0) => {
    const encodedRequestId1 = encodeURIComponent(id0);
    try {
      const response = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/pdf`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          responseType: "arraybuffer",
        }
      );

      if (response.status === 200) {
        const outputFileName = title1 + `.pdf`;
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = outputFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  return (
    <div>
      <Pre_nav />
      <Overlay loading={loading}>
        <Spinner />
        {/* <LoadingText>Loading... Please wait</LoadingText> */}
      </Overlay>
      <AppContainer1>
     
        {isMobile ? (
          <SongSection>
            <Songs1>
              <AlbumCover src={thumb_img} alt="Album cover" />
              <SongTitle>{title1}</SongTitle>
            </Songs1>
            <SongInfo3>
            <SongActions2>
               {/* <DownloadButton5 onClick={handleyouClick_playlist}>
                <ButtonImage1 src={add_to_playlisted} />
              </DownloadButton5> */}
              <DownloadButton onClick={generatePDF}>
                <ButtonImage src={download_blue} />
               </DownloadButton>
               <DownloadButton1 onClick={handleCopyLink}>
                <ButtonImage1 src={copy_url} />
              </DownloadButton1>
            </SongActions2>
            <SongActions1>
              {/* <DownloadButton4 onClick={retry_chord}>
                <ButtonImage src={retry_chords} />
              </DownloadButton4> */}
            </SongActions1>
            </SongInfo3>
          </SongSection>
        ) : (
          <SongSection>
            <AlbumCover src={thumb_img} alt="Album cover" />
            <SongInfo>
              <SongTitle id="pdf1">{title1}</SongTitle>
              <SongActions>
              {/* <DownloadButton5 onClick={handleyouClick_playlist}>
                <ButtonImage1 src={add_to_playlisted} />
              </DownloadButton5> */}
              <DownloadButton onClick={generatePDF}>
                <ButtonImage src={download_blue} />
              </DownloadButton>
              <DownloadButton1 onClick={handleCopyLink}>
                <ButtonImage1 src={copy_url} />
              </DownloadButton1>
              {/* <DownloadButton4 onClick={retry_chord}>
                <ButtonImage src={retry_chords} />
              </DownloadButton4> */}
              </SongActions>
            </SongInfo>
          </SongSection>
        )}
        {PopupVisible && (
          <PopupOverlay>
            <CreatePlaylistPopup onClose={closepopup} />
          </PopupOverlay>
        )}
        {down && (
          <PopupOverlay>
            <Downgrade
              onClose={() => setDown(false)}
              id1={id0}
              hiddenParam={hiddenParam}
            />
          </PopupOverlay>
        )}
        {showDropdown && (
          <DropdownMenu>
            {playlists.map((playlist, index) => (
              <MenuItem
                key={index}
                onClick={() => add_playlist1(playlist.name)}
              >
                {playlist.name}
              </MenuItem>
            ))}
          </DropdownMenu>
        )}

        {showplaylist_popup && (
          <PopupOverlay>
            <PlaylistForm onClose={handleyouClick_playlist1} id1={id0} />
            {/* {console.log(id0)} */}
          </PopupOverlay>
        )}
        <AppContainer>
          <MainContent>
            <LyricsSection>
              <Header>Chords used in this song</Header>
              <ChordList>
                {result_Cords.map((chord, index) => {
                  const formattedName = formatChordName(chord); // Format the chord if needed
                  const flatName = convertSharpToFlat(chord); // Convert sharp to flat
                  const imgSrc = images[formattedName];
                  const isSharpChord = chord.includes("#"); // Check if the chord is sharp
                  const chordDisplay = isSharpChord
                    ? `${chord}/${flatName}`
                    : chord; // Show sharp/flat if sharp chord

                  return imgSrc ? (
                    <div className="chord-item" style={{ textAlign: "center" }}>
                      <StyledImage
                        key={index}
                        src={imgSrc}
                        alt={formattedName}
                      />

                      {/* Display chord names */}
                      <p
                        style={{
                          color: "white",
                          fontSize: "14px",
                          marginTop: "10px",
                        }}
                      >
                        {/* Sharp and flat names, or just chord */}
                        {isSharpChord ? (
                          <>
                            {/* Sharp name, clickable */}
                            <span
                              onClick={() => {
                                flat_to_sharp();
                                setSelectedName("formattedName");
                              }}
                              style={{
                                fontSize:
                                  selectedName === "formattedName"
                                    ? "16px"
                                    : "14px",
                                fontWeight:
                                  selectedName === "formattedName"
                                    ? "bold"
                                    : "normal",
                                cursor: "pointer",
                              }}
                            >
                              {chord}
                            </span>
                            /{/* Flat name, clickable */}
                            <span
                              onClick={() => {
                                sharp_to_flat();
                                setSelectedName("flatName");
                              }}
                              style={{
                                fontSize:
                                  selectedName === "flatName" ? "16px" : "12px",
                                fontWeight:
                                  selectedName === "flatName"
                                    ? "bold"
                                    : "normal",
                                cursor: "pointer",
                              }}
                            >
                              {flatName}
                            </span>
                          </>
                        ) : (
                          // For non-sharp chords, just display the chord name
                          <span style={{ fontSize: "14px" }}>{chord}</span>
                        )}
                      </p>
                    </div>
                  ) : null;
                })}
              </ChordList>
              <Rows>
                <Header>Lyrics with Chords</Header>
                {/* <Header1 onClick={edit_chord} src={imageSrc} /> */}
              </Rows>
              <Container>{myVariable}</Container>
              {/* <div id="pdf" style={{ whiteSpace: "pre-wrap" }}>
                {lines.map((line, index) => (
                  <React.Fragment key={index}>
                    {checkAndColorLines(line)}
                  </React.Fragment>
                ))}
              </div> */}
            </LyricsSection>
          </MainContent>
          {/* <Sidebar>
            <Ad>
              <VideoPlayer videoUrl={link} />
            </Ad>
            <Header3> Recently Played songs </Header3>
            <RecentlyPlayedList>
              {playlists.map(
                (song, index) =>
                  song.title !== title1 && (
                    <div
                      key={index}
                      onClick={() => {
                        setLoading(true);
                        handleSongCardClick(song.id, song.url);
                      }}
                    >
                      <RecentlyPlayedItem>
                        <SongThumbnail src={song.image} alt={song.title} />
                        <SongDetails>
                          <SongName>{song.title}</SongName>
                        </SongDetails>
                      </RecentlyPlayedItem>
                    </div>
                  )
              )}
              <ViewAllLink onClick={View_all_Songs}>View all</ViewAllLink>
            </RecentlyPlayedList>
          </Sidebar> */}
        </AppContainer>
      </AppContainer1>
      <ToastContainer />
      <Footer />
    </div>
  );
};

function Downgrade({ onClose, id1, hiddenParam }) {
  const token = getToken();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const delete_song = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${config.apiUrl}/playlists/__default_playlist__/remove`,
        { records: [id1] },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        if (hiddenParam == "all_songs") {
          navigate("/all_songs");
        } else if (hiddenParam == "user") {
          navigate("/user");
        } else {
          navigate("/all_songs");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  const ActionButton = ({ text, primary, onClick }) => {
    return (
      <Button primary={primary} onClick={onClick}>
        {text}
      </Button>
    );
  };

  return (
    <Modal>
      <HeaderContainer1>
        <WarningText>Warning</WarningText>
        <CloseIcon
          onClick={onClose}
          loading="lazy"
          src={white_close}
          alt="Close"
        />
      </HeaderContainer1>
      <Content1>Are you sure you want to delete this song?</Content1>
      <ActionButton text="Confirm" primary onClick={delete_song} />
      <ActionButton text="Cancel" onClick={onClose} />
    </Modal>
  );
}

const ViewAllLink = styled.a`
  color: #428bca;
  margin: auto 0;
  font: 16px Poppins, sans-serif;
  margin-right: 35px;
  cursor: pointer;
  @media (max-width: 991px) {
    margin-right: 0px;
    flex-direction: row;
  }
`;

const AppContainer1 = styled.div`
  flex-direction: column;
  background-color: #282828;
  display: flex;
  gap: 0px;
  align-items: flex-start;
  overflow: hidden;
`;
const Header3 = styled.h3`
  color: #fff;
`;
const Content1 = styled.p`
  color: #3e3635;
  letter-spacing: 0.1px;
  margin-top: 37px;
  font: 500 18px/21px Poppins, sans-serif;
`;
const RecentlyPlayedList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 12px 0 0;
`;

const DownloadButton0 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  position: relative; /* Needed for positioning the tooltip */

  /* Ensures images inside this button have the correct size */
  img {
    height: 32px; /* Adjust size as needed */
  }
`;

const ButtonImage0 = styled.img`
  cursor: pointer; /* Ensures pointer cursor on hover */
`;

const Tooltip = styled.div`
  position: absolute;
  bottom: 150%; /* Position above the button, adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  border: 2px solid #fff;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 1;
  font-size: 10px;
`;

const Imagearrow = styled.img`
  width: 20px;
  height: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute; /* Position relative to the button */
  top: -30px; /* Adjust position as needed */
  left: 50%;
  transform: translateX(-50%);
`;

const StyledDownloadButton = styled(DownloadButton0)`
  &:hover ${Tooltip} {
    opacity: 1;
  }

  &:hover ${Imagearrow} {
    opacity: 1;
  }
`;
const ButtonImage = styled.img`
  cursor: pointer; /* Ensures pointer cursor on hover */
`;
const Button = styled.button`
  font-family: Poppins, sans-serif;
  border-radius: 4px;
  background-color: ${(props) => (props.primary ? "#428bca" : "transparent")};
  align-self: center;
  margin-top: ${(props) => (props.primary ? "15px" : "5px")};
  width: 100%;
  max-width: 296px;
  color: ${(props) => (props.primary ? "#fff" : "#717171")};
  white-space: nowrap;
  padding: 17px 30px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
`;
const Modal = styled.section`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  max-width: 428px;
  height: 300px;
  padding: 0 0 0px;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  @media (max-width: 991px) {
    width: 95%;
  }
`;
const HeaderContainer1 = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 428px;
  background-color: #428bca;
  max-width: 100%;
  font-size: 24px;
  white-space: nowrap;
  letter-spacing: -0.24px;
  line-height: 130%;
  height: 70px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
`;

const WarningText = styled.h2`
  margin: 0 auto;
  font: 600 24px Poppins, sans-serif;
`;
const CloseIcon = styled.img`
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
`;
const RecentlyPlayedItem = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 12px;
`;
const DownloadButton5 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  margin-left: 3px;
  background: transparent;
  cursor: pointer;

  img {
    height: 32px; /* Adjust size as needed */
  }
`;
const ButtonImage1 = styled.img`
  cursor: pointer; /* Ensures pointer cursor on hover */
`;

const SongThumbnail = styled.img`
  width: 78px;
  height: auto;
`;



const SongDetails = styled.div`
  flex-direction: column;
  justify-content: top;
`;

const SongName = styled.p`
  color: #fff;
  font: 600 14px/19px Poppins, sans-serif;
  letter-spacing: -0.2px;
  margin: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
// const MainContent = styled.main`
//   display: flex;
//   padding: 0 20px;
//   width: 85%;
//   flex-direction: column;
// `;

const Header = styled.h2`
  color: #fff;
`;

const SongSection = styled.section`
  display: flex;
  gap: 20px;
  margin-top: 32px;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 185px;
  left: 200px;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
`;
const MenuItem = styled.div`
  padding: 12px 24px;
  cursor: pointer;
  color: #333;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const AlbumCover = styled.img`
  max-width: 350px;
  height: 151px;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 150px;
    height: 100px;
    margin-left: 10px;
  }
`;


const Rows = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Header1 = styled.img`
  cursor: pointer;
  margin-left: 70px;
`;
const MainContent = styled.main`
  display: flex;
  padding: 0 20px;
  width: 85%;
  flex-direction: column;
  @media (max-width: 991px) {
  }
`;
const Songs1 = styled.div`
  display: flex;
  flex-direction: row;
`;
const SongInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
const SongInfo3 = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const SongActions2 = styled.div`
  display: flex;
  align-items: center;
  gap: 0px; /* No gap between buttons */
  margin-left:  10px;
`;

const SongTitle = styled.h1`
  color: #fff;
  font: 600 18px/140% Poppins, sans-serif;
`;

const StyledImage = styled.img`
  display: inline-block;
  max-width: 120px;
  height: auto;
  margin: 0 10px;

  @media (max-width: 768px) {
    max-width: 80px;
  }
`;

const SongActions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
`;
const SongActions1 = styled.div`
  display: flex;
  align-items: center;
  gap: 0px; /* No gap between buttons */
  margin-top: 12px;
`;
const PlayButton = styled.img`
  width: 26px;
  height: 26px;
  cursor: pointer;
`;

const DownloadButton = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  border-radius: 8px;

  cursor: pointer;
`;
const DownloadButton1 = styled.div`
  display: flex;
  align-items: center;
  border: none;
  padding: 0px;
  background: transparent; /* Ensures button has no background */
  cursor: pointer;
  margin-left: 10px;

  img {
    height: 35px; /* Adjust size as needed */
  }
`;
const DownloadButton4 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  margin-left: 4px;
  background: transparent;
  cursor: pointer;

  img {
    height: 32px;
  }
`;

const DownloadButton3 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  margin-left: 0px;
  background: transparent;
  cursor: pointer;

  img {
    height: 35px;
  }
`;
const LyricsSection = styled.section`
  margin-top: 32px;
`;

const Box = styled.div`
  font-size: ${(props) => (props.type === "words" ? 15 : 15)}px;
  width: ${(props) => props.width}px;
  color: ${(props) => (props.type === "words" ? "#FFFFFF" : "#F0984B")};
  display: inline-block;
  padding: 3px;
  border: ${(props) =>
    props.editMode
      ? `1px solid ${props.type === "words" ? "#FFFFFF" : "#F0984B"}`
      : "none"};
  margin-bottom: ${(props) => (props.isGrouped ? "14px" : "4px")};
  white-space: nowrap;
`;

const Container = styled.div`
  display: inline-block; /* Ensures content stays inline */
  white-space: nowrap; /* Prevent line wrapping */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Disable vertical scrolling */
  width: 100%; /* Take full width of the parent */
  flex-direction: column;
  min-width: 900px;

  /* Add padding or margin as per your design needs */

  /* For mobile responsiveness */
  @media (max-width: 768px) {
    width: 100%; /* Ensure it stays full width on mobile */
  }
`;
const ChordList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

// const ChordList = styled.div`
//   display: flex;
//   flex-wrap: wrap; // Ensure the images wrap if necessary
//   margin-top: 8px;

//   img {
//     width: 150px; // Adjust the width as needed
//     height: 150px; // Adjust the height as needed
//   }
// `;

const ChordImage = styled.img`
  width: 112px;
  height: auto;
`;

const Verse = styled.div`
  margin-top: 24px;
  margin-right: 0px;
`;
const Line = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${(props) => (props.type === "words" ? "10px" : "")};
`;
const Sidebar = styled.aside`
  background-color: #312f2f;
  width: 40%;
  padding: 20px;
  margin-left: 0px;
  justify-content: right;
  margin-top: 200px;
  @media (max-width: 991px) {
    width: 100%;
    display: none;
  }
`;
const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;
const AppContainer = styled.div`s
  background-color: #282828;
  display: flex;
  padding-bottom: 19px;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  overflow-x: auto; /* Enable horizontal scroll */
  overflow-y: hidden; /* Disable vertical scroll */
  white-space: nowrap; /* Prevent shrinking or wrapping */
  width: 100%; /* Set width to 100% */
  /* Add this media query for mobile responsiveness */
  @media (max-width: 991px) {
    justify-content: flex-start;
  }
`;

const Ad = styled.div`
  background-color: #fff;
  border-radius: 2px;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  padding: 16px 0;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  display: ${(props) => (props.loading ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;

export const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;
export const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const Con = styled.div`
  position: fixed;
  display: flex;
  bottom: 15px; /* Distance from the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust to truly center horizontally */
  z-index: 1000;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  flex-direction: row;
  padding: 5px;
  background-color: #312f2f; /* Optional background color */
  border-radius: 8px; /* Optional rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
   height: 65px;

  /* Mobile adjustments */
  @media (max-width: 991px) {
    width: 90%; /* Occupy 90% width on mobile */
    margin: 0 auto; /* Center horizontally on mobile */
    left: 50%; /* Keep horizontal centering */
    transform: translateX(-50%); /* Maintain true centering */
    padding: 5px;
  }
`;
const Con1 = styled.div`
  position: fixed;
  display: flex;
  bottom: 15px; /* Distance from the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust to truly center horizontally */
  z-index: 1000;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  flex-direction: row;
  padding: 5px;
  background-color: #312f2f; /* Optional background color */
  border-radius: 8px; /* Optional rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
   height: 65px;

  /* Mobile adjustments */
  @media (max-width: 991px) {
    width: 100%; /* Occupy 90% width on mobile */
    margin: 0 auto; /* Center horizontally on mobile */
    left: 50%; /* Keep horizontal centering */
    transform: translateX(-50%); /* Maintain true centering */
    padding: 5px;
    bottom: 0px;
  }
`;


const Image3 = styled.img`
  cursor: pointer;
  margin-left: 30px;
`;

const Header5 = styled.div`
  color: #fff;
  font: 550 15px Poppins, sans-serif;

  align-items: center;
  margin-left: 20px;
  @media (max-width: 991px) {
    font-size: 12px;
    margin-left: 5px;
  }
`;

const Header6 = styled.div`
  color: #fff;
  font: 550 15px Poppins, sans-serif;
  align-items: center;
  margin-left: 10px;
  @media (max-width: 991px) {
    font-size: 12px;
    margin-left: 5px;
    white-space: nowrap
  }
`;
const ButtonContainer1 = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: auto 0;
  margin-left: 5px;
  margin-right: 10px;
`;

const TransposeButton1 = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? "blue" : "#242323")};
  color: ${(props) => (props.isActive ? "#fff" : "#fff")};
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  @media (max-width: 991px) {
   padding: 8px 12px;
    font-size: 10px;
    white-space: nowrap
  }
`;

const Header51 = styled.div`
  color: #fff;
  font: 550 15px Poppins, sans-serif;

  align-items: center;
  margin-left: 10px;
  @media (max-width: 991px) {
    font-size: 12px;
    margin-left: 10px;
    white-space: nowrap
  }
`;

const Header7 = styled.div`
  color: #fff;
  font: 550 15px Poppins, sans-serif;

  align-items: center;
  margin-left: 8px;
   margin-right: 8px;
  @media (max-width: 991px) {
    font-size: 12px;
    margin-left: 5px;
  }
`;

const SpeedControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left:0px;
  margin-right:20px;
  gap: 12px;
`;

const SpeedButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? "blue" : "#242323")};
  color: ${(props) => (props.isActive ? "#fff" : "#fff")};
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  @media (max-width: 991px) {
    font-size: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: auto 0;
  margin-left: 10px;
  margin-right: 30px;
`;
const TransposeButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? "blue" : "#ddd")};
  color: ${(props) => (props.isActive ? "#fff" : "#000")};
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
export default MusicApp2;
