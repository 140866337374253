import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import {
  lan_music,
  landing_guitar,
  close,
  white_close,
  feature_unlimited,
  feature_loop,
  feature_pdf,
  feature_playback,
  guitar_svg,
} from "../../assets/images";
import useSubscription from "../user-page/subscription";
import {
  getToken,
  clearToken,
  getsubscription_enddate,
  getsubscription_status,
} from "../utils";
import axios from "axios";
import { useLocation } from "react-router-dom";
import config from "../../config";
import WarningModal from "./warning_popup";
import { useNavigate } from "react-router-dom";
import Setcard from "../user-page/set_card";

import After_set_cards from "../user-page/after_set_cards";

function Pricing1() {
  const subscription2 = useSubscription();
  const [down, setDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showcard, setshowcard] = useState(false);
  const [showyearcard, setshowyearcard] = useState(false);
  const [showaftercard, setshowaftercard] = useState(false);
  const end_date1 = getsubscription_enddate();
  const end_date = new Date(end_date1);
  const status = getsubscription_status();

  const formattedDate = end_date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  useEffect(() => {
    if (location.pathname === "/cards") {
      setTimeout(() => {
        const element = document.getElementById("pricing-section");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }

        setshowaftercard(true);
      }, 100);
    } else {
      setshowaftercard(false);
    }
  }, [location.pathname, navigate]);

  const downgrade1 = () => {
    setDown(true);
  };

  const token = getToken();
  const sub = async () => {
    try {
      const response = await axios.post(
        `${config.apiUrl}/subscription/`,
        {
          subscription_type: "yearly",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const checkoutUrl = response.data.checkout_url;
        setLoading(true);
        window.open(checkoutUrl);
        setLoading(false);
        // setLoading(true);
        // navigate("/thankyou");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
      // else if (error.response && error.response.status === 400 && error.response.data.message === "No payment method set!") {
      //   setshowcard(true);
      // }
    }
  };

  const sub1 = async () => {
    try {
      const response = await axios.post(
        `${config.apiUrl}/subscription/`,
        {
          subscription_type: "monthly",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const checkoutUrl = response.data.checkout_url;
        setLoading(true);
        window.open(checkoutUrl);
        setLoading(false);
        // setLoading(true);
        // navigate("/thankyou");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
      // else if (error.response && error.response.status === 400 && error.response.data.message === "No payment method set!") {
      //   setshowcard(true);
      // }else if (error.response && error.response.status === 400 && error.response.data.message === "You are already on yearly plan") {
      //   setshowyearcard(true);
      // }
    }
  };

  return (
    <MainContainer>
      <Overlay loading={loading}>
        <Spinner />
      </Overlay>
      <Section>
        <Article>
          <ImageWrapper>
            <Image loading="lazy" src={lan_music} alt="Musical instrument" />
          </ImageWrapper>
          <Content>
            <Title>
              Unlock Your Musical
              <br /> Potential
            </Title>
            {/* <Description>
              Unleash your inner musician with Jammin.ai, the revolutionary
              platform that makes
              <br /> learning music fun and accessible! We offer a variety of
              pricing plans and content to
              <br /> fit your musical goals and budget.
            </Description> */}
            <Description>
              Unleash your inner musician with Jammin.ai, the revolutionary platform that makes learning music fun and accessible! We offer a variety of pricing plans and content to fit your musical goals and budget.
            </Description>
          </Content>
        </Article>
      </Section>
      <PlansSection>
        <PlansWrapper>
          <Section1>
            {/* <PlanCardsContainer>
              <Card className="plan-card">
                <Header className="plan-header">
                  <PlanDetails className="plan-details">
                    <PlanType className="plan-type">Free</PlanType>
                    <PlanPrice2 className="plan-price">$0</PlanPrice2>
                  </PlanDetails>
                  {subscription2 === null ? (
                    <CurrentPlanBadge
                      className="current-plan-badge"
                      onClick={sub}
                    >
                      Current Plan
                    </CurrentPlanBadge>
                  ) : (
                    <CurrentPlanBadge
                      className="current-plan-badge"
                      onClick={downgrade1}
                    >
                      Change Plan
                    </CurrentPlanBadge>
                  )}
                </Header>
                <FeaturesList className="features-list">
                  <FeatureItem className="feature-item">5 songs</FeatureItem>
                  <FeatureItem className="feature-item">
                    No PDF Downloads
                  </FeatureItem>
                </FeaturesList>
              </Card>


              <Card className="plan-card">
                <Header1 className="plan-header">
                  <PlanDetails className="plan-details">
                    <PlanType className="plan-type">Yearly</PlanType>
                    <PlanPrice2 className="plan-price">$79.99</PlanPrice2>
                  </PlanDetails>
                  {subscription2 === "yearly" ? (
                    <CurrentPlanBadge
                      className="current-plan-badge"
                      onClick={sub}
                    >
                      Current Plan
                    </CurrentPlanBadge>
                  ) : (
                    <CurrentPlanBadge
                      className="current-plan-badge"
                      onClick={sub}
                    >
                      Change Plan
                    </CurrentPlanBadge>
                  )}
                </Header1>
                <FeaturesList className="features-list">
                  <FeatureItem1 className="feature-item">
                    Unlimited Songs
                  </FeatureItem1>
                  <FeatureItem1 className="feature-item">
                    Unlimited PDF Downloads
                  </FeatureItem1>
                </FeaturesList>
              </Card>

              <Card className="plan-card">
                <Header2 className="plan-header">
                  <PlanDetails className="plan-details">
                    <PlanType className="plan-type">Monthly</PlanType>
                    <PlanPrice2 className="plan-price">$9.99</PlanPrice2>
                  </PlanDetails>
                  {subscription2 === "monthly" ? (
                    <CurrentPlanBadge
                      className="current-plan-badge"
                      onClick={sub1}
                    >
                      Current Plan
                    </CurrentPlanBadge>
                  ) : (
                    <CurrentPlanBadge
                      className="current-plan-badge"
                      onClick={sub1}
                    >
                      Change Plan
                    </CurrentPlanBadge>
                  )}
                </Header2>
                <FeaturesList className="features-list">
                  <FeatureItem className="feature-item"> Unlimited Songs </FeatureItem>
                  <FeatureItem1 className="feature-item">
                  Unlimited PDF Downloads
                  </FeatureItem1>
                </FeaturesList>
              </Card>
            </PlanCardsContainer> */}
            <FreePlanNew>
              <HiddenDiv></HiddenDiv>
              <FreeFeatures type="free">
                <div style={{ fontSize: "28px", fontWeight: "700", textAlign: "center" }}>Free Plan</div>
                <div style={{ fontSize: "28px", fontWeight: "700" }}>$0</div>
                <ul>
                  <li>
                    <div>
                      <img src={feature_unlimited} alt="" />
                    </div>
                    <span>Unlimited Songs</span>
                  </li>
                  <li>
                    <div>
                      <img src={feature_loop} alt="" />
                    </div>
                    <span>Advanced Looping</span>
                  </li>
                  <li>
                    <div>
                      <img src={feature_pdf} alt="" />
                    </div>
                    <span>First Month Free PDF Downloads</span>
                  </li>
                  <li>
                    <div>
                      <img src={feature_playback} alt="" />
                    </div>
                    <span>Advance Playback</span>
                  </li>
                </ul>
                <button onClick={subscription2 !== null ? downgrade1 : undefined}>{subscription2 === null ? 'Current Plan' : 'Change Plan'}</button>
              </FreeFeatures>
              <FreeFeatures type="premium">
                <div style={{ fontSize: "28px", fontWeight: "700", textAlign: "center" }}>Premium Plan</div>
                <div style={{ fontSize: "28px", fontWeight: "700" }}>$ 9.99/month</div>
                <ul>
                  <li>
                    <div>
                      <img src={feature_unlimited} alt="" />
                    </div>
                    <span>Unlimited Songs</span>
                  </li>
                  <li>
                    <div>
                      <img src={feature_loop} alt="" />
                    </div>
                    <span>Advanced Looping</span>
                  </li>
                  <li>
                    <div>
                      <img src={feature_pdf} alt="" />
                    </div>
                    <span>Unlimited PDF Downloads</span>
                  </li>
                  <li>
                    <div>
                      <img src={feature_playback} alt="" />
                    </div>
                    <span>Advance Playback</span>
                  </li>
                </ul>
                <button onClick={sub1}>{subscription2 ? 'Current Plan' : 'Upgrade Now'}</button>
              </FreeFeatures>
              <GuitarImage>
                <img src={guitar_svg} alt="" loading="lazy" width={"70%"} />
              </GuitarImage>
            </FreePlanNew>
          </Section1>
          {/* <Aside>
            <AsideImage
              loading="lazy"
              src={landing_guitar}
              alt="Musical note"
            />
          </Aside> */}
        </PlansWrapper>
      </PlansSection>
      {down && (
        <PopupOverlay>
          {status === "active" ? (
            <Downgrade
              onClose={() => setDown(false)}
              formattedDate={formattedDate}
            />
          ) : status === "trail" ? (
            <Downgrade4
              onClose={() => setDown(false)}
              formattedDate={formattedDate}
            />
          ) : (
            <Downgrade1
              onClose={() => setDown(false)}
              formattedDate={formattedDate}
            />
          )}
        </PopupOverlay>
      )}

      {showcard && (
        <PopupOverlay>
          <Setcard onClose={() => setshowcard(false)} />
        </PopupOverlay>
      )}
      {showaftercard && (
        <PopupOverlay>
          <After_set_cards onClose={() => setshowaftercard(false)} />
        </PopupOverlay>
      )}
      {showyearcard && (
        <PopupOverlay>
          <Downgrade2
            onClose={() => setshowyearcard(false)}
            formattedDate={formattedDate}
          />
        </PopupOverlay>
      )}
    </MainContainer>
  );
}

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
}

function Downgrade({ onClose, formattedDate }) {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 991;
  const token = getToken();
  const navigate = useNavigate();

  const del_sub = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/subscription/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.status === 200) {
        onClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  return (
    <Modal>
      <ModalContainer1>
        <Header9>
          {isMobile ? (
            <Title2>
              Are you sure you want to<br></br> cancel auto-renewal?
            </Title2>
          ) : (
            <Title2>Are you sure you want to cancel auto-renewal?</Title2>
          )}

          <Icon2 onClick={onClose} src={white_close} alt="Close icon" />
        </Header9>
        <Message>
          Your subscription will remain active until {formattedDate}. After this
          date, you'll need to manually renew your subscription to continue
          using our service.
        </Message>

        <ButtonContainer>
          <PrimaryButton onClick={onClose}>No, keep auto-renewal</PrimaryButton>
          <SecondaryButton onClick={del_sub}>
            Yes, cancel auto-renewal
          </SecondaryButton>
        </ButtonContainer>
      </ModalContainer1>
    </Modal>
  );
}


function Downgrade4({ onClose, formattedDate }) {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 991;
  const token = getToken();
  const navigate = useNavigate();

  const del_sub = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/subscription/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.status === 200) {
        onClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  return (
    <Modal10>
      <ModalContainer1>
        <Header91>

          <Title2>
            Hey Jammer
          </Title2>

          <Icon2 onClick={onClose} src={white_close} alt="Close icon" />
        </Header91>
        <Message>
          You've already used your free trial. If you continue, your subscription will start immediately, and you'll be charged the full amount for the selected plan. Do you wish to proceed?
        </Message>

        <ButtonContainer>
          <PrimaryButton onClick={del_sub}>Proceed</PrimaryButton>
          <SecondaryButton onClick={onClose} >
            Cancel
          </SecondaryButton>
        </ButtonContainer>
      </ModalContainer1>
    </Modal10>
  );
}

function Downgrade1({ onClose, formattedDate }) {
  return (
    <Modal1>
      <ModalContainer1>
        <Header9>
          <Title2>Subscription Cancellation Confirmation</Title2>
          <Icon2 onClick={onClose} src={white_close} alt="Close icon" />
        </Header9>
        <Message>
          You have already canceled your auto-renewal. Your subscription will
          remain active until {formattedDate}.
        </Message>

        <ButtonContainer1>
          <PrimaryButton onClick={onClose}>OK</PrimaryButton>
        </ButtonContainer1>
      </ModalContainer1>
    </Modal1>
  );
}

function Downgrade2({ onClose, formattedDate }) {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 991;

  return (
    <Modal1>
      <ModalContainer1>
        <Header9>
          <Title2>Annual Plan Active</Title2>
          <Icon2 onClick={onClose} src={white_close} alt="Close icon" />
        </Header9>

        {isMobile ? (
          <Message>
            Your current subscription is an annual plan that expires on{" "}
            {formattedDate}. To switch to a monthly plan, please wait until your
            annual plan expires.
          </Message>
        ) : (
          <Message>
            Your current subscription is an annual plan that expires on{" "}
            <br></br>
            {formattedDate}. To switch to a monthly plan, please<br></br> wait
            until your annual plan expires.
          </Message>
        )}

        <ButtonContainer1>
          <PrimaryButton onClick={onClose}>OK</PrimaryButton>
        </ButtonContainer1>
      </ModalContainer1>
    </Modal1>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 18px;
  min-height: 128px;
  width: 100%;
  max-width: 296px;
  flex-direction: column;
  justify-content: start;
  @media (max-width: 991px) {
    margin-top: 6px;
  }
`;

const ButtonContainer1 = styled.div`
  display: flex;
  margin-top: 18px;
  min-height: 60px;
  width: 100%;
  max-width: 296px;
  flex-direction: column;
  justify-content: start;
  @media (max-width: 991px) {
    margin-top: 8px;
  }
`;

const Button = styled.button`
  align-self: stretch;
  flex: 1;
  border-radius: 4px;
  min-width: 240px;
  width: 100%;
  padding: 17px 30px;
  font: inherit;
  cursor: pointer;
  border: none;
`;
const PrimaryButton = styled(Button)`
  background-color: #0072d5;
  color: #fff;
`;

const SecondaryButton = styled(Button)`
  background-color: transparent;
  color: #717171;
  margin-top: 5px;
`;

const Message = styled.p`
  color: #3e3635;
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.15px;
  margin-top: 28px;
  margin-left: 15px;
  margin-right: 15px;
`;
const Header9 = styled.header`
  background-color: #0072d5;
  align-self: stretch;
  position: relative;
  display: flex;
  min-height: 64px;
  align-items: flex-start;
  gap: 10px;
  color: #fff;
  letter-spacing: -0.18px;
  line-height: 23px;
  justify-content: center;
  padding: 19px 10px;
`;

const Header91 = styled.header`
  background-color: #0072d5;
  align-self: stretch;
  position: relative;
  display: flex;
  min-height: 44px;
  align-items: flex-start;
  gap: 10px;
  color: #fff;
  letter-spacing: -0.18px;
  line-height: 23px;
  justify-content: center;
  padding: 19px 10px;
`;
const Title2 = styled.h3`
  z-index: 0;
  width: 350px;
  margin: auto 0;
`;

const Icon2 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: start;
  position: absolute;
  z-index: 0;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  cursor: pointer;
`;

const Modal = styled.div`
  border-radius: 12px;
  box-shadow: 0 0 16px rgba(255, 255, 255, 0.16);
  display: flex;
  max-width: 428px;
  max-height: 385px;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  font: 600 18px Poppins, sans-serif;
  @media (max-width: 991px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;



const Modal10 = styled.div`
  border-radius: 12px;
  box-shadow: 0 0 16px rgba(255, 255, 255, 0.16);
  display: flex;
  max-width: 428px;
  max-height: 355px;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  font: 600 18px Poppins, sans-serif;
  @media (max-width: 991px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const Modal1 = styled.div`
  border-radius: 12px;
  box-shadow: 0 0 16px rgba(255, 255, 255, 0.16);
  display: flex;
  max-width: 428px;
  max-height: 340px;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  font: 600 18px Poppins, sans-serif;
  @media (max-width: 991px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const ModalContainer1 = styled.div`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  width: 100%;
  padding-bottom: 36px;
  flex-direction: column;
  align-items: center;
`;

// const Modal = styled.section`
//   border-radius: 12px;
//   background-color: #fff;
//   display: flex;
//   max-width: 428px;
//   height:310px;
//   padding: 0 0 0px;
//    flex-direction: column;
//   align-items: center;
//   font-size: 18px;
//   color: #fff;
//   font-weight: 600;
//   text-align: center;
// `;

// const Content1 = styled.p`
//   color: #3e3635;
//   letter-spacing: -0.14px;
//   margin-top: 37px;
//   font: 500 14px/21px Poppins, sans-serif;
// `;

// const StyledButton = styled.button`
//   font: 400 18px/21px Poppins, sans-serif;
//   border-radius: 4px;
//   align-self: center;
//   white-space: nowrap;
//   padding: 17px 30px;
//   width:50%;
//   margin-top: ${props => props.secondary ? "10px" : "24px"};
//   background-color: ${props => props.primary ? "#428bca" : "transparent"};
//   color: ${props => props.primary ? "#fff" : "#717171"};
//   border: none;
//   cursor: pointer;
// `;

const MessageText = styled.p`
  letter-spacing: -0.16px;
  margin-top: 24px;
  font: 500 16px/24px Poppins, sans-serif;
`;

// const HeaderContainer1 = styled.header`

//   display: flex;
//   width: 428px;
//    background-color: #428bca;
//   max-width: 100%;
//   gap: 0px;
//   font-size: 24px;
//   white-space: nowrap;
//   letter-spacing: -0.24px;
//   line-height: 130%;
//   border-top-left-radius: 12px;
//   border-top-right-radius: 12px;
// `;

// const WarningText = styled.h2`

//   flex-grow: 1;
//   flex-basis: auto;
//  font: 600 24px Poppins, sans-serif;

// `;

const ModalContainer = styled.section`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  max-width: 428px;
  flex-direction: column;
  font-size: 18px;
  color: #0e0402;
  height: 300px;
  font-weight: 600;
  text-align: center;
  padding: 29px 24px 77px;
`;

const MainContainer = styled.main`
  background-color: #282828;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  overflow-x: hidden;
  overflow-y: hidden;
  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Section = styled.section`
  width: 100%;
  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Article = styled.article`
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const ImageWrapper = styled.div`
  width: 26%;
  @media (max-width: 991px) {
    width: 26%;
    display: none;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 54%;
  gap: 0px;
  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
    margin-right: 0px;
  }
`;

const Title = styled.h1`
  font: 700 48px/62px Montserrat, sans-serif;
  color: #fff;
  padding: 0px;
  margin-bottom: 12px;
  text-align: center;
  @media (max-width: 991px) {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }
`;

const Description = styled.p`
  font: 400 16px/26px Poppins, sans-serif;
  color: #fff;
  text-align: center;
  margin-top: 0px;
  padding: 0px;
  @media (max-width: 991px) {
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const PlansSection = styled.section`
  background-color: #282828;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; /* Center contents vertically and horizontally */

  @media (max-width: 991px) {
    padding: 0;
    justify-content: center;
    align-items: center;
  }
`;

const PlansWrapper = styled.div`
  display: flex;
  justify-content: center; /* Ensure the wrapper is centered horizontally */
  align-items: center; /* Ensure the wrapper is centered vertically */
  height: 100%; /* Full height for centering */
  width: 100%;

  @media (max-width: 991px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto; /* Adjust for mobile screens */
  }
`;

const Section1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%; /* Full height for centering */

  @media (max-width: 991px) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

const PlanCardsContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;

  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
`;

const Aside = styled.aside`
  display: flex;
  width: 16%;
  flex-direction: row;
  margin: 0;
  padding: 0;

  @media (max-width: 991px) {
    width: 100%;
    display: none;
  }
`;

const AsideImage = styled.img`
  aspect-ratio: 0;
  width: 188px;
  max-width: 100%;
  margin-right: 0px;
  flex-grow: 0;
  @media (max-width: 991px) {
    margin-top: 32px;
  }
`;

const Card = styled.div`
  align-self: stretch;
  background-color: #fff;
  display: flex;
  max-width: 328px;
  max-height: 285px;
  flex-direction: column;
  border-radius: 8px;
  // box-shadow: 0 -4px 8px rgba(255, 255, 255, 0.5),
  //             0 4px 8px rgba(255, 255, 255, 0.5);
  @media (max-width: 991px) {
  }
`;

const Header = styled.header`
  background-color: #0072d5;
  display: flex;
  max-width: 328px;
  max-height: 187px;
  flex-direction: column;
  padding: 16px 12px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;

  justify-content: center;
  align-items: center;
`;

const Header1 = styled.header`
  background-color: #020f12;
  display: flex;
  max-width: 328px;
  max-height: 187px;
  flex-direction: column;
  padding: 16px 12px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  justify-content: center;
  align-items: center;
`;

const Header2 = styled.header`
  background-color: #4542ca;
  display: flex;
  max-width: 328px;
  max-height: 187px;
  flex-direction: column;
  padding: 16px 12px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  justify-content: center;
  align-items: center;
`;

const PlanDetails = styled.div`
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center children vertically */
  align-items: center; /* Center children horizontally */
  color: #fff; /* Set text color */
`;

const PlanType = styled.h2`
  letter-spacing: -0.28px;
  margin-top: 1px;
  font: 700 28px Montserrat, sans-serif;
`;

const PlanPrice = styled.h1`
  letter-spacing: -0.4px;
  margin-top: 4px;
  font: 600 40px Poppins, sans-serif;
`;

const PlanPrice2 = styled.h1`
  margin-top: -10px;
  margin-bottom: 10px;
  font: 600 40px Poppins, sans-serif;
`;

const PlanPrice1 = styled.div`
  letter-spacing: -0.4px;
  margin-top: 0px;
  font: Source sans pro, sans-serif;
  font-size: 12px;
`;

const CurrentPlanBadge = styled.button`
  border-radius: 999999px;
  background-color: #fff;
  color: #428bca;
  border: none;
  max-width: 159px;
  justify-content: center;

  width: 400px;
  padding: 14px 12px;
  font: 600 14px Poppins, sans-serif;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 1);
  }
`;

const CurrentPlanBadge1 = styled.button`
  border-radius: 999999px;
  background-color: #fff;
  margin-top: 30px;
  max-width: 159px;
  color: #428bca;
  border: none;
  justify-content: center;
  padding: 14px 12px 14px 32px;
  font: 600 14px Poppins, sans-serif;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 1);
  }
`;

const FeaturesList = styled.ul`
  display: flex;
  margin-top: 4px;
  max-width: 100%;
  flex-direction: column;
  font-size: 18px;
  color: #3f3f3f;
  font-weight: 600;
  text-align: center;
  padding: 0 12px;
  list-style: none;
`;

const FeatureItem = styled.li`
  font-family: Source Sans Pro, sans-serif;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 12px 60px;
  border-top: 1px solid rgba(217, 217, 217, 1);

  &:first-child {
    border-top: none;
  }
`;

const FeatureItem1 = styled.li`
  font-family: Source Sans Pro, sans-serif;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 12px 30px;
  border-top: 1px solid rgba(217, 217, 217, 1);

  &:first-child {
    border-top: none;
  }
`;

const FeatureItem2 = styled.li`
  font-family: Source Sans Pro, sans-serif;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  white-space: pre-wrap;
  padding: 12px 30px;
  border-top: 1px solid rgba(217, 217, 217, 1);

  &:first-child {
    border-top: none;
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;

const OTPInput = styled.input`
  border-radius: 4px;
  border: 1px solid #ececec;
  background-color: #fff;
  margin-top: 15px;
  color: #676767;
  padding: 17px 16px;
  font: 500 16px/137.5% "SF Pro Text", -apple-system, Roboto, Helvetica,
    sans-serif;
  width: 90%;
`;

const VerifyButton = styled.button`
  justify-content: center;
  border-radius: 4px;
  background-color: #428bca;
  margin-top: 44px;
  color: #fff;
  text-align: center;
  padding: 17px 10px;
  font: 500 18px Inter, sans-serif;
  width: 80%;
  border: none;
  margin-left: 35px;
  cursor: pointer;
`;

const VerifyButton1 = styled.button`
  justify-content: center;
  border-radius: 4px;
  background-color: #428bca;
  margin-top: 44px;
  color: #fff;
  text-align: center;
  padding: 17px 10px;
  font: 500 18px Inter, sans-serif;
  width: 80%;
  border: none;
  margin-left: 35px;
  cursor: pointer;
`;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  font-size: 20px;
  color: #0e0402;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.14px;
  line-height: 100%;
  width: 100%;
`;

const HeaderText = styled.h1`
  font-family: Poppins, sans-serif;
  text-align: center;
  margin-left: 100px;
`;

// const CloseIcon = styled.img`
//   aspect-ratio: 1;
//   object-fit: auto;

//   width: 24px;
//   margin-top:20px;
//   align-self: flex-start;
//    cursor: pointer;
//    margin-right:5px;
// `;

const VerificationContainer = styled.main`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  max-width: 428px;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  padding: 15px 16px 52px;
  margin: auto; /* Ensure the container is centered on the page */
`;

const InstructionText = styled.p`
  color: #0e0402;
  text-align: center;
  letter-spacing: -0.13px;
  margin-top: 1px;
  font: 13px/130% Poppins, sans-serif;
  width: 100%;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding-left: 40px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  display: ${(props) => (props.loading ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;
const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const FreePlanNew = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 32px;

  @media (max-width: 600px){
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

`
const FreeFeatures = styled.div`
  background-color:${({ type }) => type === 'premium' ? '#9333EA' : '#3333EA'};
  color: #ffffff;
  width: 50%;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 32px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 16px;

  @media (max-width: 600px){
    width: 60%;
  }

  ul{
    list-style-type: none;
    padding: 0;
    width: 65%;
    li{
      margin-top: 16px;
      display: flex;
      div{
        width: 15%;
        display: flex;
        justify-content: center;
        img{
          max-height: 20px;
          max-width: 24px;
        }
      }
      span{
        flex: 1;
        text-align: center;
      }
    }

    @media (min-width: 501px) and (max-width: 990px){
      width: 80%;
    }

    @media (max-width: 500px){
      width: 80%;
    }
  }

  button{
    margin-top: 12px;
    width: 60%;
    background-color: ${({ type }) => type === 'premium' ? '#FACC15' : 'transparent'};
    color: ${({ type }) => type === 'premium' ? '#000000' : '#ffffff'};
    padding: 12px;
    border-radius: 4px;
    border: ${({ type }) => type === 'premium' ? 'none' : '1px solid #ffffff'};;
    font-size: 14px;
    font-weight: 600;

    @media (max-width: 800px){
      width: 70%;
    }
  }
`

const HiddenDiv = styled.div`
  width: 20%;
  @media (max-width: 900px){
    width: 0;
  }
`

const GuitarImage = styled.div`
  width: 20%;
  @media (max-width: 900px){
    width: 0;
    img{
      display: none;
    }
  }
`

export default Pricing1;
