import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  new_logo,
  premium,
  Jammin_mob,
  logo_new,
  free_user,
  premium_user_nav,
  lg_white,
  search,
  search_white,
  upload_icon,
  close_icon,
  info_icon,
  jammine_logo_short,
  mp3_thumbnail,
  error_icon,
} from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { getToken, clearToken, getimgurl, getfirstname, getlastname } from "../utils";
import useSubscription from "../user-page/subscription";
import axios from 'axios';
import config from '../../config';
import { css } from "@emotion/react";
import { toast, ToastContainer } from "react-toastify";
import { languages } from "../utils";

function Pre_nav({
  onUploadClicked = () => { },
  onGoClicked = () => { },
  onSearchResultClicked = () => { },
  setLoading = () => { }
}) {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const token = getToken();
  const img_url = getimgurl();
  const firstName = getfirstname();
  const lastName = getlastname();
  const [showDropdown, setShowDropdown] = useState(false);
  const subscription1 = localStorage.getItem("subscription");



  const [imageSrc, setImageSrc] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [popupVisible, setPopupVisible] = useState(null);
  const [uploadFiles, setUploadFiles] = useState(null)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [mobileSearch, setMobileSearch] = useState(false)
  const [previewData, setPreviewData] = useState(null)

  const [language, setLanguage] = useState("en")

  const fetchSubscription = async () => {


    try {
      const response = await axios.get(`${config.apiUrl}/subscription/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        if (response.data && response.data.subscription_type) {
          setImageSrc(premium_user_nav);
        } else {
          setImageSrc(free_user);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const triggerUpload = () => {
    fileInputRef.current.click();
  }

  const fileDownload = async (urls, fileName) => {
    const nameWithoutExt = fileName.substring(0, fileName.lastIndexOf(".")) || fileName;
    try {
      const response = await axios.post(`${config.apiUrl}/url_requests/process_upload`, {
        url: urls.download_url,
        title: nameWithoutExt,
        lang_code: language
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        onUploadClicked();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
    setLanguage("en")
  }

  const fileUpload = async (urls, file) => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.put(urls.upload_url, file, {
        headers: {
          'Content-Type': 'audio/mpeg'
        }
      });

      if (response.status === 200) {
        fileDownload(urls, file.name);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
    setLoading(false);
  }

  const getUploadUrls = async () => {

    if (!uploadFiles) {
      return
    }

    try {
      const response = await axios.get(
        `${config.apiUrl}/url_requests/upload_urls`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        fileUpload(response.data, uploadFiles);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
    // fileInputRef.current.target.value = ''
    setPopupVisible(null)
  }

  useEffect(() => {
    fetchSubscription();

  }, []);

  const showErrorToast = (message) => {
    toast(<div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={error_icon}
        alt="check icon"
        style={{ marginRight: "8px", width: "24px", height: "24px" }}
      />
      <span style={{ flex: 1 }}>{message}</span>
    </div>, { position: "bottom-left" }
    )
  }

  const searchSong = async () => {
    if (searchTerm.trim().length < 3 || searchTerm.trim().startsWith("https://")) {
      setSearchResults([])
    } else {
      try {
        const response = await axios.get(
          `${config.apiUrl}/songs/search?term=${searchTerm.trim()}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setSearchResults(response?.data?.songs)
        } else {
          setSearchResults([])
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
      }
    }
  }

  const handleGoClick = async (e) => {
    e.preventDefault();
    if (!searchTerm) {
      return
    }
    try {
      const url = new URL(searchTerm)
      if (url.protocol !== "http:" && url.protocol !== "https:") {
        throw "Invalid Url"
      }
      const { data } = await axios.get(`https://noembed.com/embed?url=${searchTerm}`)
      setPreviewData({ title: data.title, image: data.thumbnail_url })
      setPopupVisible("convert")
    } catch (error) {
      showErrorToast("Invalid Url")
    }
  }

  const handlePopupSubmit = () => {
    if (popupVisible === "upload") {
      getUploadUrls()
    } else {
      const value = searchTerm;
      const lang = language;
      onGoClicked(value,lang);
      setSearchTerm("");
      setPreviewData(null);
      setLanguage("en")
    }
    setPopupVisible(null)
  }

  useEffect(() => {

    let timer = setTimeout(() => {
      searchSong()
    }, 300)

    return () => {
      clearTimeout(timer)
    }
  }, [searchTerm])

  useEffect(() => {
    if (screenWidth <= 580 && searchTerm !== "") {
      setMobileSearch(true)
    } else {
      setMobileSearch(false)
    }
  }, [screenWidth])

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const logout = () => {

    localStorage.removeItem("share_id_added");
    clearToken();
    navigate("/");
    setShowDropdown(false);
  };

  const myprofile = () => {
    navigate("/myprofile");
  };

  const mybilling = () => {
    navigate("/mybilling");
  };

  const mysetting = () => {
    navigate("/settings");
  };

  const user_navigate = () => {

    navigate("/user");
    window.scrollTo(0, 0);
  };

  const initials = firstName && lastName ? `${firstName[0]}${lastName[0]}`.toUpperCase() : 'U';

  useEffect(() => {
    const updateScreenSize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener("resize", updateScreenSize)

    return () => {
      window.removeEventListener("resize", updateScreenSize)
    }
  }, [])

  return (
    <Header>
      <Nav1 style={{ width: screenWidth <= 580 && mobileSearch ? "100%" : undefined }} >
        {screenWidth > 580 ? <Logo
          loading="lazy"
          src={new_logo}
          alt="Logo"
          onClick={user_navigate}
        /> : !mobileSearch ? <img src={jammine_logo_short} style={{ marginLeft: "24px" }} alt="" /> : null}
        {/* <Logo1 loading="lazy" src={imageSrc} alt={imageSrc} /> */}
        {screenWidth > 580 ?
          <SearchBarContainer>
            <SearchBar>
              <div style={{
                background: '#454343',
                display: "flex",
                alignItems: "center",
                gap: "8px"
              }}>
                <img src={search_white} alt="search" width="16px" />
                <div style={{
                  position: "relative",
                  borderRadius: 0,
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  paddingLeft: 0,
                  paddingRight: '12px',
                  flex: 1,
                }}>
                  <input
                    type="text"
                    placeholder="Search by song name or paste the YouTube url"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && e.target.value.trim().startsWith("https://")) {
                        handleGoClick(e)
                      }
                    }}
                  />
                  {searchTerm.length > 2 && searchResults.length > 0 && <SuggestionContainer>
                    {searchResults.map((result, index) => <SearchresultRow key={index.toString()} onClick={() => onSearchResultClicked(result.youtube_id)}>
                      <img src={result.thumbnail_url} alt={result.youtube_id} />
                      <div>
                        <span style={{
                          fontSize: '14px',
                          fontWeight: 600,
                        }}>{result.title}</span>
                        <span></span>
                      </div>
                    </SearchresultRow>)}
                  </SuggestionContainer>}
                </div>
              </div>
              <button onClick={handleGoClick} >Go</button>
            </SearchBar>
            <UploadButton onClick={() => setPopupVisible("upload")}>
              <img src={upload_icon} alt="" />
              <span>Upload song</span>
            </UploadButton>
          </SearchBarContainer>
          :
          <SearchBarContainer style={{ width: mobileSearch ? "100%" : undefined, padding: mobileSearch ? "0 8px" : undefined }}>
            <SearchBar>
              <div style={{
                background: mobileSearch ? 'transparent' : '#454343',
                display: "flex",
                alignItems: "center",
                gap: "8px",
                borderRadius: !mobileSearch ? '64px' : '0px'
              }}>
                {mobileSearch && <span onClick={() => {
                  setMobileSearch(false);
                  setSearchTerm("")
                }} >
                  <img src={close_icon} alt="" width="12px" />
                </span>}
                <img src={search_white} alt="search" width="16px" />
                <div style={{
                  position: "relative",
                  borderRadius: 0,
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  paddingLeft: 0,
                  paddingRight: '12px',
                  flex: 1,
                }}>
                  <input
                    type="text"
                    placeholder={mobileSearch ? "Search by song name or paste the YouTube url" : "Search"}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    onFocus={() => {
                      setMobileSearch(true)
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && e.target.value.trim().startsWith("https://")) {
                        handleGoClick(e)
                      }
                    }}
                  />
                  {searchTerm.length > 2 && searchResults.length > 0 && <SuggestionContainer>
                    {searchResults.map((result, index) => <SearchresultRow key={index.toString()} onClick={() => onSearchResultClicked(result.youtube_id)}>
                      <img src={result.thumbnail_url} alt={result.youtube_id} />
                      <div>
                        <span style={{
                          fontSize: '14px',
                          fontWeight: 600,
                        }}>{result.title}</span>
                        <span></span>
                      </div>
                    </SearchresultRow>)}
                  </SuggestionContainer>}
                </div>
              </div>
              {mobileSearch && <button style={{ borderRadius: screenWidth <= 580 && mobileSearch ? "8px" : undefined }} onClick={handleGoClick} >Go</button>}
            </SearchBar>
            {!mobileSearch && <UploadButton onClick={() => setPopupVisible("upload")}>
              <img src={upload_icon} alt="" />
            </UploadButton>}
          </SearchBarContainer>
        }
      </Nav1>
      {/* <UserIcon >SI</UserIcon> */}
      {/* <img
        src={img_url}
        style={{
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          backgroundColor: "#f6b704",
          display: "flex",
          width: "48px",
          height: "48px",
          marginTop: "18px",
          marginRight: "15px",
          color: "#fff",
          cursor: "pointer",
          fontFamily: "Poppins, sans-serif",
          fontWeight: "600",
          fontSize: "16px",
        }}
        onClick={toggleDropdown}
      /> */}
      {(screenWidth > 580 || !mobileSearch) && <SearchLayout>
        <div
          onClick={toggleDropdown}
          style={{
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            backgroundColor: img_url ? "transparent" : "#0072D5", // Blue background if no image
            display: "flex",
            width: "48px",
            height: "48px",
            color: img_url ? "transparent" : "#FFFFFF", // White text color if no image
            cursor: "pointer",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: "16px",
            backgroundImage: img_url ? `url(${img_url})` : "none",
            backgroundSize: "cover",
            backgroundPosition: "center",
            marginRight: '24px'
          }}
        >
          {!img_url && initials}
        </div>
      </SearchLayout>}
      {showDropdown && (
        <DropdownMenu>
          <MenuItem onClick={myprofile}>My Profile</MenuItem>
          <MenuItem onClick={mybilling}>Billing and Subscription</MenuItem>
          <MenuItem onClick={mysetting}>Account Settings</MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </DropdownMenu>
      )}

      {popupVisible !== null && <div style={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        zIndex: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <UploadPopup>
          <input ref={fileInputRef} type="file" style={{ display: "none" }} onChange={e => { setUploadFiles(e.target.files[0]) }} />
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div></div>
            <div style={{ fontSize: "20px", fontWeight: 600, color: "#fff" }}>{popupVisible === "upload" ? 'Upload Song' : 'Convert Song'}</div>
            <span onClick={() => setPopupVisible(null)} style={{ cursor: "pointer" }}>
              <img src={close_icon} alt="" onClick={() => {
                setUploadFiles(null);
                setPreviewData(null);
              }} />
            </span>
          </div>
          <FormContainer>
            <div>
              <div style={{ fontSize: "16px", fontWeight: 600, color: "#fff" }}>
                {popupVisible === "upload" ? 'Choose Audio File' : 'Preview'}
              </div>
              {popupVisible === "upload" ? <button style={{
                padding: "14px 24px",
                borderRadius: "4px",
                background: "#fff",
                marginTop: "10px",
                cursor: "pointer",
                border: "none",
                fontSize: "10px",
                fontStyle: "italic"
              }} onClick={triggerUpload} >
                {uploadFiles ? uploadFiles.name : 'Choose a file'}
              </button>
                :
                <VideoPreview>
                  <img src={previewData.image || mp3_thumbnail} alt="" width="100%" />
                  <div style={{ fontWeight: 600, color: "#fff", fontSize: "12px" }}>{previewData.title}</div>
                </VideoPreview>
              }
            </div>
            <div>
              <div>
                <div style={{ fontSize: "16px", fontWeight: 600, color: "#fff" }}>Select Language</div>
                <select style={{
                  width: "100%",
                  height: "40px",
                  background: "#3A3B3B",
                  color: "#fff",
                  border: "none",
                  marginTop: "10px",
                  borderRadius: "4px",
                  padding: "8px"
                }} 
                value={language}
                onChange={e => { setLanguage(e.target.value) }}>
                  {languages.map((item, index) => (
                    <option key={index.toString()} value={item.code} >
                      {item.language}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                marginTop: "8px"
              }}>
                <img src={info_icon} alt="" width="12px" />
                <div style={{ color: "#fff", fontSize: "10px", fontWeight: 400, lineHeight: "18px" }}>Language selection can improve the quality of the lyrics.</div>
              </div>
            </div>
          </FormContainer>
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <button style={{
              background: "#0072D5",
              padding: "18px 50px",
              borderRadius: "4px",
              border: "none",
              color: "#fff",
              cursor: "pointer",
              fontWeight: 600
            }}
              onClick={handlePopupSubmit} >
              {popupVisible === "upload" ? 'Upload Song' : 'Convert Song'}
            </button>
          </div>
        </UploadPopup>
      </div>
      }
    </Header>
  );
}

const Header = styled.header`
  background-color: #312f2f;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  justify-content: space-between;
  padding: 0;
  // @media (min-width:769px) and (max-width: 991px){
  //   max-width: 100%;
  //   flex-wrap: wrap;
  //   padding: 0;
  //   background-color: #312f2f;
  //   flex-direction: row;
  // }
  // @media (max-width: 769px){
  //   height: 120px;
  //   align-items: flex-start;
  //   padding: 18px 0;
  //   flex-direction: column;
  //   position: relative;
  // }
`;

const Nav1 = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 24px;
  width: 35%;
  @media (max-width: 991px) {
    flex-direction: row;
  }
  @media (min-width: 1025px) and (max-width: 2000px) {
    width: 55%;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 70%;
    }
  @media (max-width: 768px) {
    width: 85%;  
  }
`;

const Logo = styled.img`
  object-position: center;
  width: 138px;
  fill: #fff;
  max-width: 100%;
  margin-left: 20px;
  padding: 0;
  cursor: pointer;
  @media (max-width: 991px) {
     margin-left: 10px;
  }
`;

const Logo2 = styled.img`
  display: none;
  @media (max-width: 991px) {
    display: block;
    object-fit: auto;
    object-position: center;
    width: 108px;
    fill: #fff;
    max-width: 100%;
    margin-top: 20px;
  }
`;

const Logo1 = styled.img`
  flex-direction: row;
  margin-left: 6px;
  fill: #fff;
`;

const UserIcon = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f6b704;
  display: flex;
  width: 48px;
  height: 48px;
  margin-top: 18px;
  margin-right: 15px;
  color: #fff;
  cursor: pointer;
  font: 600 16px Poppins, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 70px;
  right: 15px;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
`;

const MenuItem = styled.div`
  padding: 12px 24px;
  cursor: pointer;
  color: #333;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const SearchLayout = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const SearchBarContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 75%;
  height: 44px;
`

const SearchBar = styled.div`
    display: flex;
    flex: 1;
    border-radius: 8px;

    &>div {
        display: flex;
        gap:4px;
        width: 100%;
        padding-left: 12px;
        background-color: #fff;

        @media (min-width: 581px){
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        @media (max-width: 580px){
          border-radius: 8px;
        }
    }

    input {
        flex:1;
        font-size: 12px;
        border: none;
        width: 100%;
        background: transparent;
        color: #fff;
        &:focus {
          outline: none;
        }
        &::placeholder{
          color: #fff;
          opacity: 0.7;
        }
    }
        
    button {
        padding: 10px;
        width: 15%;
        border: none;
        color: #fff;
        background-color: #0072D5;
        cursor: pointer;
        font-weight: 600;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`

const SuggestionContainer = styled.div`
    position: absolute;
    width: 100%;
    background: #fff;
    top: 40px;
    border-radius: 0 0 4px 4px;
    padding: 16px 8px;
    box-sizing: border-box;
    background: #454343;
    z-index: 10;
`
const SearchresultRow = styled.div`
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    color: #fff;

    img{
      height: 40px;
    }
`

const UploadButton = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 12px;
    border-radius: 8px;
    background-color: #0072D5;
    color: #fff;
    border: none;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;

    img {
        height: 16px;
    }
`

const UploadPopup = styled.div`
  max-width: 500px;
  width: 35%;
  background: #282828;
  padding: 16px 24px;
  border-radius: 12px;
  max-height: 90vh;
  overflow-y: auto;

  select:focus{
    outline: none;
  }

  @media (max-width: 1240px) and (min-width: 1025px){
    width: 40%;
  }

  @media (max-width: 1024px) and (min-width: 901px){
    width: 45%;
  }

  @media (max-width: 900px) and (min-width: 601px){
    width: 35%;
  }

  @media (max-width: 600px){
    width: 50%;
  }
`
const FormContainer = styled.div`
  display: flex;
  padding: 30px 0;
  justify-content: space-between;
  gap: 8px;

  @media (max-width: 900px){
    flex-direction: column;
    gap:24px;

    &>div{
      width: 100%;
    }
  }
`

const VideoPreview = styled.div`
width: 150px;
margin-top: 10px;

@media (max-width:900px){
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  text-align: center;
  width: 100%;

  img{
    width: 70%;
  }
}
`
export default Pre_nav;
