import { useEffect, useState } from "react";

function useFreeTrial() {
    const [state, setState] = useState({ isTrial: false, endsOn: null });

    useEffect(() => {
        const value = localStorage.getItem("freeTrialEnds")
        if (value) {
            const date = new Date(value)
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const year = date.getFullYear();
            const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
            setState({ isTrial: new Date() <= date, endsOn: formattedDate })
        }
    }, [])

    return state
}

export default useFreeTrial;