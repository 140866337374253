import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import axios from "axios";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled, { keyframes, css } from "styled-components";
import Cookies from "js-cookie";
import FeedbackPopup from "../user-page/feedback";
import VideoPlayer from "../chords/videoplayer_playlist";
import EditPlaylistForm from "../playlist/edit_playlist_name_popup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


import {
  new_logo_white, video_play_button, video_pause_button, restart, restart1, copy_chords, lyrics_time_orange, lyrics_time, loop_orange, loop,
  green_tick1, loop_start, loop_end, Highlight_chords_enabled, Highlight_chords_disabled, show_timing_enabled, show_timing_disabled,
  white_cancel, add_playlist_new, retry_new, delete_new, copy_youtube_new, free_download_new, ques_new, delete_playlist, edit_playlist,
  Thumbs_up, three_dot, collapse_arrow, youtube_logo, playlist_pdf, playlist_youtube, playlist_retry, playlist_add_playlist, playlist_share, playlist_delete,
  Thumbs_down, playlist_remove, play_mobile,loop_dis,loop_en,loop_disabled,show_clock,show_clock_enabled,high_light_chords_enable,
  save_as_btn,high_light_chords_white,high_light_chords,create_coopy,create_save_as,
  ex_down,
  ex_up,
  add_icon_playlist,
  down_drop_arrow,
  auto_scroll,
  copy_url,
  delete_button,
  download_blue, download_new,
  download_free,
  edit_chords,
  retry_chords,
  save_edit,
  white_close,
  add_to_playlisted,
  new_logo,
  share_btn,
  back_arraow_chords,
  start_scroll,
  edt_variant,
  del_variant,
  green_tick_edit,
  exit_edt_mode,playlist_image,
  mp3_thumbnail,
} from "../../assets/images";


import arrow from "../../assets/images/arrow.svg";
import tick_snack_bar from "../../assets/images/tick_snack_bar.svg";

import config from "../../config";
import Footer from "../footer_lan";
import Pre_nav from "../navigation/premium_nav";
import PlaylistForm from "../playlist/add_playlist_popup";
import CreatePlaylistPopup from "../user-page/playlist_popup";
import useSubscription from "../user-page/subscription";
import useFreeTrial from "../user-page/freeTrial";
import { clearToken, getToken } from "../utils";
import RetryPopup from "./retry_popup";


import Snackbar from "@mui/material/Snackbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getfirstname, getlastname } from "../utils";

import { Pencil, Plus, GripHorizontal, Save } from "lucide-react";
import ShareLinkPopup from "../chords/share_popup";


const CustomToast = ({ closeToast }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    {/* <FaUser style={{ marginRight: '10px' }} /> */}
    <img
      src={tick_snack_bar}
      alt="check icon"
      style={{ marginRight: "8px", width: "24px", height: "24px" }}
    />

    <span style={{ flex: 1 }}>link copied</span>
  </div>
);
const showToast1 = () => {
  toast(<CustomToast1 message={"Playlist Updated Successfully"} />, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const CustomToast1 = ({ message }) => {
  return (
    <div>
      <p>{message}</p>
    </div>
  );
};

const showToast = () => {
  toast(<CustomToast />, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};


const useWindowSize = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    // Detect if the device is mobile
    const checkIfMobile = () => {
      const isMobileDevice = window.matchMedia("(max-width: 991px)").matches;
      setIsMobile(isMobileDevice);
    };

    checkIfMobile(); // Initial check
    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", checkIfMobile); // Update on resize

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const isPortrait = windowHeight > windowWidth;
  const isLandscape = windowWidth > windowHeight;

  return { windowWidth, windowHeight, isMobile, isPortrait, isLandscape };
};



function New_playlist() {






  const [loading, setLoading] = useState(false);
  const token = getToken();
  const navigate = useNavigate();
  if (token == null) {
    navigate("/");
  }
  const subscription1 = useSubscription();
  const { isTrial, endsOn } = useFreeTrial();
  const location = useLocation();
  const cj1Ref = useRef(null);
  const [playlist_img, setplaylist_img] = useState("");
  const [playlist_name, setplaylist_name] = useState("");
  const [playlist_total, setplaylist_total] = useState("");

  const [previousSongName, setpreviousSongName] = useState("");
  const [nextSongName, setnextSongName] = useState("");

  const [songs, setSongs] = useState([]);
  const [songs1, setSongs1] = useState([]);
  const [selectedSongId, setSelectedSongId] = useState(null);
  const [selectedSongId1, setSelectedSongId1] = useState(null);

  const [pageLoaded, setPageLoaded] = useState(false);





  const { windowWidth, windowHeight, isMobile, isPortrait, isLandscape } = useWindowSize();
  const { nav1 } = location.state || {};
  const { initials } = useParams();
  const { result1 } = location.state || {};
  const [playlists, setPlaylists] = useState([]);
  const [timesArray, settimesArray] = useState([]);

  const [loading1, setLoading1] = useState(false);
  //const [subscription1, setSubscription] = useState("null");
  const [title1, settitle1] = useState("");
  const [youtube_id, setyoutube_id] = useState("");
  const [thumb_img, setthumb_img] = useState("");

  const [Text1, setText1] = useState("");
  const [snackbar_name, setsnackbar_name] = useState("");
  const [Percentage, setPercentage] = useState("");

  const [result, setresult] = useState("");
  const [result_Cords, setresult_Cords] = useState([]);
  const [chordsTs, setchordsTs] = useState([]);
  const [state, setstate] = useState([]);
  const [wordsTs, setwordsTs] = useState([]);
  const wordRef = useRef(null);
  const wordRef2 = useRef(null);


  const [hoveredItem, setHoveredItem] = useState(null); // To track hover state

  const [uniqueChords, setUniqueChords] = useState([]);
  const [isAutoTempoEnabled, setIsAutoTempoEnabled] = useState(false);
  const [url, seturl] = useState("");
  const [id, setid] = useState("");
  const first_name = getfirstname();
  const last_name = getlastname();
  const [selectedOption, setSelectedOption] = useState("Scroll: Auto Tempo");
  const initials1 =
    first_name && last_name ? `${first_name}_${last_name}` : "Unknown";
  let isuser = false;

  const playlist_added_successfully = localStorage.getItem(
    "playlist_added_successfully"
  );



  const [open, setOpen] = useState(false);
  const [down, setDown] = useState(false);
  const [activeValue, setActiveValue] = useState(0);
  const [transposedValue, setTransposedValue] = useState(0);
  const [activeButton, setActiveButton] = useState(null);

  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const scrollIntervalRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [imageSrc, setImageSrc] = useState(edit_chords);
  const [combinedRowsWithMaxWidths, setCombinedRowsWithMaxWidths] = useState(
    []
  );
  const [myVariable, setMyVariable] = useState([]);

  const [selectedName, setSelectedName] = useState("formattedName");
  const [showplaylist_popup, setshowplaylist_popup] = useState(false);

  let isUserScrolling = false;
  let lastScrollPosition = window.pageYOffset;

  const hiddenParam = location.state?.hiddenParam;
  const variantName_share_url = location.state?.variantName;
  const [showDropdown, setShowDropdown] = useState(false);
  const [scrollSpeed, setScrollSpeed] = useState(0);

  const [scrollDelay, setScrollDelay] = useState(50);
  const [fontSize1, setFontSize1] = useState(16);

  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [visitCount, setVisitCount] = useState(0);
  const [show_feedback, setshow_feedback] = useState(false);
  const [share_popup, setshare_popup] = useState(false);
  const [share_url1, setshare_url1] = useState("");
  const isFirstMount = useRef(true);
  const [fontSize, setFontSize] = useState(15); // Default font size
  const [activeButton1, setActiveButton1] = useState(null); // Track the active button

  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexsong, setCurrentIndexsong] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showversion_title_popup, setshowversion_title_popup] = useState(false);
  const [saveas_original, setsaveas_original] = useState(false);
  const [showedt_txt_popup, setshowedt_txt_popup] = useState(false);
  const [showdel_chords_popup, setshowdel_chords_popup] = useState(false);
  const [showdel_words_popup, setshowdel_words_popup] = useState(false);
  const [showedt_txt_popup_wrd, setshowedt_txt_popup_wrd] = useState(false);
  const [shownew_txt_popup, setshownew_txt_popup] = useState(false);
  const [shownew_txt_popup1, setshownew_txt_popup1] = useState(false);
  const [showversion_edit_popup, setshowversion_edit_popup] = useState(false);
  const [showversion_del_popup, setshowversion_del_popup] = useState(false);
  const [edt_name, setedt_name] = useState("");
  const [del_name, setdel_name] = useState("");
  const [variants, setVariants] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [currentTime_youtube, setcurrentTime_youtube] = useState(0);
  const playerRef = useRef(null);
  const playerRef2 = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoop, setisLoop] = useState(false);
  const [edt2, setedt2] = useState(false);

  const [highlight_chords, sethighlight_chords] = useState(false);
  const [retryPopupVisible, setRetryPopupVisible] = useState(false);
  const [mp3Url, setMp3Url] = useState(null);
  const [mp3Player, setMp3Player] = useState(null);
  const audioInterval = useRef(null);


  // const [selectedValue, setSelectedValue] = useState(
  //   variantName_share_url === "" ? "original" : variantName_share_url
  // );

  const [selectedValue, setSelectedValue] = useState("original");
  const [version_select, setversion_select] = useState(null);
  const song_added_using_url = localStorage.getItem("song_added_using_url");
  const [hasScrolledToPlay1, setHasScrolledToPlay1] = useState(false);


  const timeoutRef = useRef(null);
  const timeoutRef1 = useRef(null);
  const toastId = useRef(null);
  const [selectedChordIndex, setSelectedChordIndex] = useState(null); // Track which chord is selected
  const [editedChord, setEditedChord] = useState(""); // For editing chord text
  const [editedLyrics, setEditedLyrics] = useState("");

  const [editable, setEditable] = useState({ row: null, index: null });
  const [rowIndex, setrowIndex] = useState("");
  const [itemIndex, setitemIndex] = useState("");
  const [displayedWord, setdisplayedWord] = useState("");
  const [wordIndex, setwordIndex] = useState("");
  const [updatedText, setupdatedText] = useState("");
  const [lastitem, setlastitem] = useState("");

  const [rowIndex1, setrowIndex1] = useState("");
  const [itemIndex1, setitemIndex1] = useState("");
  const [displayedChord, setdisplayedChord] = useState("");
  const [chordIndex, setchordIndex] = useState("");
  const [updatedchord, setupdatedchord] = useState("");
  const [finalRows, setFinalRows] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const maxVisibleImages = 5; // Number of images to show in a single row initially
  const [isClicked, setIsClicked] = useState(false);
  const [isClicked1, setIsClicked1] = useState(false);
  const [downvoted, setdownvoted] = useState("");
  const [downvotes, setdownvotes] = useState("");
  const [upvoted, setupvoted] = useState("");
  const [upvotes, setupvotes] = useState("");
  const [previousWords, setpreviousWords] = useState("");
  const [nextWords, setnextWords] = useState("");
  const contentEditableRef = useRef(null);
  const contentEditableRef1 = useRef(null);

  const [word_empty_time, setword_empty_time] = useState("");
  const [chord_empty_time, setchord_empty_time] = useState("");

  const [totalDuration, setTotalDuration] = useState("");
  const timelineRef = useRef(null);
  const timelineRef2 = useRef(null);

  const [SECONDS_PER_LINE1, set_SECONDS_PER_LINE1] = useState(3);
  // const SECONDS_PER_LINE = 5;
  const LINE_HEIGHT = 50;
  // const [currentTime, setCurrentTime] = useState(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [addchortime, setaddchortime] = useState(null);
  const [addwordtime, setaddwordtime] = useState(null);

  const [editingChord, setEditingChord] = useState(null);
  const [editedChordValue, setEditedChordValue] = useState("");
  const [validationError, setValidationError] = useState(null);

  const [editingWord, setEditingWord] = useState(null);
  const [editedWordValue, setEditedWordValue] = useState("");
  const [wordValidationError, setWordValidationError] = useState(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // New state for dropdown
  const [width, setWidth] = useState("auto");

  const hiddenTextRef = useRef(null);
  const hiddenTextRef1 = useRef(null);
  const [newChords, setNewChords] = useState([]); // New chords state
  const [newWord, setNewWord] = useState([]); // New chords state

  const [activeView, setActiveView] = useState('simpleView');

  const [currentTime, setCurrentTime] = useState(0);

  const [loopStart, setLoopStart] = useState(null);
  const [loopEnd, setLoopEnd] = useState(null);
  const [isSettingLoopStart, setIsSettingLoopStart] = useState(false);
  const [isSettingLoopEnd, setIsSettingLoopEnd] = useState(false);


  const [startLoopTime, setStartLoopTime] = useState(null);
  const [endLoopTime, setEndLoopTime] = useState(null);
  const [loopIntervalId, setLoopIntervalId] = useState(null);
  const [isStartSet, setIsStartSet] = useState(false);

  const [isMinimized, setIsMinimized] = useState(true);
  const [isMinimized1, setIsMinimized1] = useState(true);
  const [isUploaded, setIsUploaded] = useState(true);
  const [editDropdown, seteditDropdown] = useState(false);
  const [delDropdown, setdelDropdown] = useState(false);
  const [remove_song, setremove_song] = useState(false);
  const [show_left_nav_mob, setshow_left_nav_mob] = useState(false);

  const [songList, setSongList] = useState(songs);
  const [isDraggingEnabled, setIsDraggingEnabled] = useState(false);

  const [draggedIndex, setDraggedIndex] = useState(null);
  const [placeholderIndex, setPlaceholderIndex] = useState(null);

  const [showPopup_play_mob, setshowPopup_play_mob] = useState(false);


  const handleImageClick_mob = () => {
    setshowPopup_play_mob(true);
  };

  const handleClosePopup_mob = () => {
    setshowPopup_play_mob(false);
  };


  const show_edit_popup = () => {
    seteditDropdown(true);
  };

  const hide_edit_popup = () => {
    seteditDropdown(false);
  };

 

  const show_left_nav = () => {

     setshow_left_nav_mob((prevState) => !prevState);
    
  };

  const show_del_popup = () => {
    setdelDropdown(true);
  };

  const hide_del_popup = () => {
    setdelDropdown(false);
  };

  const show_remove_popup = () => {
    setremove_song(true);
  };

  const hide_remove_popup = () => {
    setremove_song(false);
  };



  const toggleSidebar = () => {
    setIsMinimized((prev) => !prev);
  };

  const toggleSidebar1 = () => {
    setIsMinimized1((prev) => !prev);
  };


  const handleViewChange = () => {
    setActiveView((prev) => {
      const newView = prev === 'simpleView' ? 'orgChordsView' : 'simpleView';
      if (newView === 'simpleView') {
        setisLoop(false);
        setLoopStart(null);
        setLoopEnd(null);
      }
      return newView;
    });
  };



  const handle_highlight_chords = () => {
    sethighlight_chords((prevState) => !prevState);
  };

  const handleAddChord = (rowIndex, startTime) => {
    setNewChords([{ chord: "New Chord", time: startTime, row: rowIndex }]);
  };

  const handleAddword = (rowIndex, startTime) => {
    setNewWord([
      {
        word: "New word",
        start: startTime,
        row: rowIndex,
      },
    ]);
  };







  useEffect(() => {
    let player = null;

    if (mp3Url) {
      player = new Audio(mp3Url)
      setMp3Player(player)
    }

    return () => {
      player?.pause()
      clearInterval(audioInterval.current)
    }
  }, [mp3Url])




  const handleDragStart1 = (e, item, type, rowIndex) => {
    // console.log('Drag Start:', { type, item, sourceRowIndex: index });
    e.dataTransfer.setData("text/plain", "");

    const itemData = {
      ...item,
      type,
      sourceRowIndex: rowIndex,
      originalTime: type === "word" ? item.start : item.time,
    };
    draggedItemRef.current = itemData; // Store dragged item in the ref

    const dragImage = document.createElement("div");
    dragImage.textContent = type === "chord" ? item.chord : item.word;
    dragImage.style.position = "absolute";
    dragImage.style.top = "-1000px";
    document.body.appendChild(dragImage);
    e.dataTransfer.setDragImage(dragImage, 0, 0);
    document.body.removeChild(dragImage);
  };
  const handleDragEnd = (e, item, type, rowIndex) => {
    setNewChords([]);
    setNewWord([]);
  };

  const toggleDropdown1 = () => setIsDropdownOpen((prevState) => !prevState); // Toggle dropdown open/close

  const isValidChord = (chord) => {
    // Basic validation: Non-empty string, not just whitespace
    return chord && chord.trim().length > 0;
  };

  const handleEditWordStart = (word) => {
    // Set the editing word, including its original index
    setEditingWord({
      ...word,
      originalIndex: word.originalIndexes[0], // Ensure originalIndex is set
    });
    setEditedWordValue(word.word); // Set the edited word value
    setWordValidationError(null); // Reset any previous validation error
  };

  const handleWordSave = (text) => {
    if (!editedWordValue.trim()) {
      setWordValidationError("Word cannot be empty.");
      return;
    }

    const wordIndex = editingWord?.originalIndex;



    // Assuming handleLyricsUpdate is expecting the index:
    handleLyricsUpdate(wordIndex, text);

    // const updatedWords = [...wordsTs];
    // updatedWords[editingWord.index] = {
    //   ...editingWord,
    //   word: editedWordValue.trim(),
    // };
    // setwordsTs(updatedWords);
    // setEditingWord(null);
  };

  const handleWordCancel = () => {
    setEditingWord(null);
    setEditedWordValue("");
    setWordValidationError(null);
  };

  const handleEditChordStart = (chord) => {
    setEditingChord(chord);
    setEditedChordValue(chord.chord);
    setValidationError(null);
  };

  const handleChordSave = (text) => {
    // Validate chord
    if (!isValidChord(text)) {
      setValidationError("Chord cannot be empty");
      return;
    }

    // console.log("Updating chords at index:", index, "with:", newChord);
    const updatedChordsTs = [...chordsTs];
    updatedChordsTs[editingChord.originalIndex] = {
      ...updatedChordsTs[editingChord.originalIndex],
      chord: text,
    };


    setchordsTs(updatedChordsTs);
    submit_chords_and_words1(updatedChordsTs);

    // Update the chord (you'll need to implement this in the parent component)
    // setCurrentChord({
    //   ...editingChord,
    //   chord: editedChordValue
    // });

    // Reset editing state
    setEditingChord(null);
    setEditedChordValue("");
    setValidationError(null);
  };

  const handleChordCancel = () => {
    setEditingChord(null);
    setEditedChordValue("");
    setValidationError(null);
  };

  const [currentChord, setCurrentChord] = useState({
    chord: "",
    time: "",
    index: null,
  });
  const [currentword, setCurrentword] = useState({
    word: "",
    time: "",
    index: null,
  });

  const SECONDS_list = ["3", "4", "5", "6", "7", "8", "9", "10"];


  useEffect(() => {
    if (selectedValue != "original") {
      get_variant_votes();
      setversion_select(false);
    } else {
      setversion_select(true);
    }
  }, [selectedValue]);

  const toggleThumbColor = () => {
    if (selectedValue === "original") {
      if (isClicked1) deletedownvote();

      if (isClicked) {
        deleteupvote();
      } else {
        clickupvote();
      }
    } else {
      if (isClicked1) deletedownvote_variant();
      if (isClicked) {
        deleteupvote_variant();
      } else {
        clickupvote_variant();
      }
    }
  };

  const toggleThumbColor1 = () => {
    if (selectedValue === "original") {
      if (isClicked) deleteupvote();

      if (isClicked1 == true) {
        deletedownvote();
      } else {
        clickdownvote();
      }
    } else {
      if (isClicked) deleteupvote_variant();
      if (isClicked1 == true) {
        deletedownvote_variant();
      } else {
        clickdownvote_variant();
      }
    }
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const showToast4 = (data) => {
    // If the toast is already active, update it
    if (toastId.current) {
      toast.update(toastId.current, {
        render: <CustomToast4 message={data} />,
        // autoClose: 5000, // Keep the toast open but automatically close after 5 seconds of inactivity
      });
    } else {
      // Create a new toast if it doesn't already exist
      toastId.current = toast(<CustomToast4 message={data} />, {
        position: "bottom-left",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: false, // Keep the toast open until manually closed or updated
      });
    }
  };

  const CustomToast4 = ({ message }) => {
    return (
      <div>
        <p>{message}</p>
      </div>
    );
  };

  const showToast5 = (data) => {
    toast(<CustomToast1 message={data} />, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const CustomToast1 = ({ message }) => {
    return (
      <div>
        <p>{message}</p>
      </div>
    );
  };

  const handleToggle2 = () => {
    setIsAutoTempoEnabled(!isAutoTempoEnabled);
  };

  const handleyouClick_retryversion = () => {
    setshowversion_title_popup(true);
  };

  const handleyouClick_retryversion1 = () => {
    setshowversion_title_popup(false);
  };

  const handle_save_as_original_popup = () => {
    setsaveas_original(true);
  };

  const handle_save_as_original_popup1 = () => {
    setsaveas_original(false);
  };

  const handleyouClick_edittext = () => {
    setshowedt_txt_popup(true);
  };

  const handleyouClick_edittext1 = () => {
    setshowedt_txt_popup(false);
  };

  const handleyouClick_delchords = () => {
    setshowdel_chords_popup(true);
  };

  const handleyouClick_delchords1 = () => {
    setshowdel_chords_popup(false);
  };

  const handleyouClick_delwords = () => {
    setshowdel_words_popup(true);
  };

  const handleyouClick_delwords1 = () => {
    setshowdel_words_popup(false);
  };

  const handleyouClick_editword = () => {
    setshowedt_txt_popup_wrd(true);
  };

  const handleyouClick_editword1 = () => {
    setshowedt_txt_popup_wrd(false);
  };

  const handleyouClick_newtext = () => {
    setshownew_txt_popup(true);
  };

  const handleyouClick_newtext1 = () => {
    setshownew_txt_popup(false);
  };

  const handleyouClick_newtext2 = () => {
    setshownew_txt_popup1(true);
  };

  const handleyouClick_newtext3 = () => {
    setshownew_txt_popup1(false);
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    if (value === "Manual") {
      setSelectedOption("Scroll: Manual");
    } else {
      setSelectedOption("Scroll: Auto Tempo");
    }
  };

  //   const handleSelectChange_version_name = (event) => {
  //     const selectedName = event.target.value;
  //     get_variant(selectedName);
  // };

  const handleremove_song = async () => {
    setLoading(true);
    const encodedRequestId1 = encodeURIComponent(id);
    try {
      const response = await axios.post(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/remove_from_playlists`,
        { playlists: [result1] },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {

        setLoading(false); // Set loading to false if the response status is 200
        get_playlist_new();
        hide_remove_popup();

      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      setLoading(false); // Ensure loading is set to false in case of any error or after execution
    }
  };


  const retry_with_name = async (lang_code="") => {
    setLoading(true);
    let title = "";
    let maxCopyNumber = 0;



    // Check for existing copies and find the highest copy number
    variants.forEach(variant => {
      const copyMatch = variant.name.match(/Copy (\d+)/);
      if (copyMatch) {
        const copyNumber = parseInt(copyMatch[1]);
        maxCopyNumber = Math.max(maxCopyNumber, copyNumber);
      }
    });

    // Increment the copy number or start with Copy 1
    const nextCopyNumber = maxCopyNumber + 1;
    title = `${title1} - Copy ${nextCopyNumber}`;

    // Ensure the title is not empty before making the API call
    if (title.trim() === "") {
      setLoading(false);
      console.error("No valid variant names found");
      return;
    }

    const encodedRequestId1 = encodeURIComponent(id);

    try {
      const response = await axios.post(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/retry_variant`,
        {
          name: title,
          lang_code,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_retry_version(id);

        setSelectedValue(title);
        get_variant(title);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          clearToken();
          navigate("/");
        } else if (error.response.status === 400) {
          setLoading(false);
          // Uncomment below line to show error message
          // setErrormsg(error.response.data.message);
        } else {
          setLoading(false);
          // Uncomment below line to handle other errors
          // setErrormsg("An unknown error occurred");
        }
      }
    }
  };



  const handleSelectChange_version_name = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "original") {
      get_original_variant();
    } else {
      get_variant(selectedValue);
    }
  };

  const handleEdit = (variantName) => {
    setedt_name(variantName);
    setIsOpen(false);
    setshowversion_edit_popup(true);
  };

  const handleEdit_close = () => {
    setedt_name("");
    setshowversion_edit_popup(false);
  };

  const handleDelete = (variantName) => {
    setdel_name(variantName);
    setIsOpen(false);
    setshowversion_del_popup(true);
  };

  const handleDelete_close = () => {
    setdel_name("");
    setshowversion_del_popup(false);
  };

  useEffect(() => {
    if (Text1) {
      const text2 = `${snackbar_name} - ${Text1}`;
      showToast4(text2);

      // Close the toast if Text1 is "Complete"
      if (Text1 === "Finalizing") {
        setTimeout(() => {
          if (toastId.current) {
            toast.dismiss(toastId.current); // Dismiss the toast after 5 seconds
            toastId.current = null; // Clear the toast ID
          }
        }, 7000); // 5000 ms = 5 seconds
      }
    }
  }, [Text1]);

  useEffect(() => {
    const maxChordTime = chordsTs.reduce(
      (max, chord) => Math.max(max, chord.time),
      0
    );
    const maxLyricEnd = wordsTs.reduce(
      (max, lyric) => Math.max(max, lyric.end),
      0
    );
    setTotalDuration(Math.ceil(Math.max(maxChordTime, maxLyricEnd)));
  }, [chordsTs, wordsTs]);

  const handleSelectChange4 = (eventOrValue) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Clear any existing timeout
    }

    const selectedValue =
      typeof eventOrValue === "string"
        ? eventOrValue
        : eventOrValue.target.value;

    setSelectedValue(selectedValue);

    if (selectedValue === "original") {
      setIsEditing(false);
      setEditable({ row: null, index: null });
      get_original_variant();
    } else {
      get_variant(selectedValue);
    }
    setIsOpen(false);
  };


  useEffect(() => {
    if (song_added_using_url) {

      localStorage.removeItem("song_added_using_url");



    }
  }, [song_added_using_url]);

  useEffect(() => {
    if (variantName_share_url) {
      setSelectedValue(variantName_share_url);
      handleSelectChange4(variantName_share_url); // Call the handleSelectChange4 function with variantName_share_url
    }
  }, [variantName_share_url]);

  const get_original_votes = async (id) => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/votes`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const { upvoted, downvoted, upvotes, downvotes } = response.data;

        setupvotes(upvotes);
        setdownvotes(downvotes);
        setupvoted(upvoted);
        setdownvoted(downvoted);

        setIsClicked(upvoted);
        setIsClicked1(downvoted);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const get_variant_votes = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}/votes`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const { upvoted, downvoted, upvotes, downvotes } = response.data;

        setupvotes(upvotes);
        setdownvotes(downvotes);
        setupvoted(upvoted);
        setdownvoted(downvoted);

        // Update clicked states
        setIsClicked(upvoted);
        setIsClicked1(downvoted);

        // console.log("Upvotes:", upvotes); // Log after setting state
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const clickupvote = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/upvote`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_original_votes(id);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const deleteupvote = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/upvote`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_original_votes(id);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const clickdownvote = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/downvote`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_original_votes(id);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const deletedownvote = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/downvote`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_original_votes(id);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const clickdownvote_variant = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}/downvote`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_variant_votes();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const deletedownvote_variant = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}/downvote`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_variant_votes();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const clickupvote_variant = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}/upvote`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_variant_votes();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const deleteupvote_variant = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}/upvote`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        get_variant_votes();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const get_variant1 = async (name) => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${name}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        let hasInProgress = false;
        setstate(response.data.state);
        setchordsTs(response.data.chords_ts);
        setwordsTs(response.data.words_ts_newline);

        setLoading1(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const get_variant = async (name) => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${name}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setLoading(false);
        let hasInProgress = false;

        setsnackbar_name(name);

        setstate(response.data.state);
        setchordsTs(response.data.chords_ts);
        setwordsTs(response.data.words_ts_newline);

        if (
          Array.isArray(response.data.chords_ts) &&
          response.data.chords_ts.length === 0
        ) {
          if (
            response.data.state === "pending" ||
            response.data.state === "in_progress"
          ) {
            setLoading1(true);

            const step = response.data.processing_step;
            let progressPercentage;
            let text;

            if (step === "generating_mp3") {
              progressPercentage = 10;
              text = "Generating Mp3";
              setText1(text);
              setPercentage(progressPercentage);
            } else if (step === "generating_midi") {
              progressPercentage = 25;
              text = "Generating Midi";
              setText1(text);
              setPercentage(progressPercentage);
            } else if (step === "transcribing_audio") {
              progressPercentage = 50;
              text = "Transcribing Audio";
              setText1(text);
              setPercentage(progressPercentage);
            } else if (step === "generating_chords") {
              progressPercentage = 75;
              text = "Generating Chords";
              setText1(text);
              setPercentage(progressPercentage);
            } else if (step === "finalizing") {
              progressPercentage = 95;
              text = "Finalizing";
              setText1(text);
              setPercentage(progressPercentage);
            } else if (step === "complete") {
              progressPercentage = 100;
              text = "Complete";
              setText1(text);
              setPercentage(progressPercentage);
            }

            timeoutRef.current = setTimeout(() => get_variant(name), 5000);
          }
        } else {
          setTimeout(() => {
            if (toastId.current) {
              toast.dismiss(toastId.current); // Dismiss the toast after 5 seconds
              toastId.current = null; // Clear the toast ID
            }
          }, 100);
          setLoading1(false);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const get_original_variant = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(youtube_id);
      const response = await axios.get(
        `${config.apiUrl}/url_requests/youtube/${encodedRequestId1}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setchordsTs(response.data.chords_ts);
        setwordsTs(response.data.words_ts_newline);

        get_original_votes(response.data.request_id);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  // const alternativeOption = selectedOption === "Scroll: Manual"
  // ? { value: "Auto Tempo", label: "Scroll : Auto Tempo" }
  // : { value: "Manual", label: "Scroll : Manual" };

  // Effect to update the option text
  // useEffect(() => {
  //   const selectElement = document.getElementById("tempo");
  //   if (selectElement) {
  //     const options = selectElement.options;
  //     for (let i = 0; i < options.length; i++) {
  //       if (options[i].value === "Manual") {
  //         options[i].text = "Scroll : Manual";
  //       } else if (options[i].value === "Auto Tempo") {
  //         options[i].text = "Scroll : Auto Tempo";
  //       }
  //     }
  //   }
  // }, [selectedOption]);

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;

      const today = new Date().toISOString().split("T")[0]; // Get the current date (YYYY-MM-DD)

      // Retrieve the last visit date and visit count from cookies
      const lastVisitDate = Cookies.get("lastVisitDate");

      const visits = Cookies.get("chordsPageVisitCount")
        ? parseInt(Cookies.get("chordsPageVisitCount"))
        : 0;

      // Check if it's a new day to reset the count
      if (lastVisitDate !== today) {
        Cookies.set("chordsPageVisitCount", 0, { expires: 7 });
        Cookies.set("lastVisitDate", today, { expires: 7 });
        setVisitCount(0);
      } else {
        // Use the existing visit count
        setVisitCount(visits);

        // If the visit count is 3 or more
        if (visits >= 3) {
          const feedbackSubmitted = Cookies.get("feedbackSubmitted");
          const feedbackClosedWithoutSubmit = Cookies.get(
            "feedbackClosedWithoutSubmit"
          );

          if (!feedbackSubmitted) {
            // 24 hrs full days
            if (feedbackClosedWithoutSubmit) {
              const lastClosedDate = new Date(feedbackClosedWithoutSubmit);
              const currentDate = new Date();

              const isSameDate =
                lastClosedDate.toDateString() === currentDate.toDateString();

              if (!isSameDate) {
                const timeDifference = currentDate - lastClosedDate;

                const oneDayInMilliseconds = 1000 * 60 * 60 * 24;

                if (timeDifference >= oneDayInMilliseconds) {
                  setshow_feedback(true);
                }
              }
            }

            // Midnight 12
            // if (feedbackClosedWithoutSubmit) {
            //   const lastClosedDate = new Date(feedbackClosedWithoutSubmit);
            //   const currentDate = new Date();

            //   const midnightLastClosedDate = new Date(lastClosedDate);
            //   midnightLastClosedDate.setHours(0, 0, 0, 0);

            //   const nextDayDate = new Date(midnightLastClosedDate);
            //   nextDayDate.setDate(midnightLastClosedDate.getDate() + 1);

            //   if (currentDate >= nextDayDate) {
            //     setshow_feedback(true);
            //   }
            // }
            else {
              // Show feedback popup if it has not been closed or submitted yet
              setshow_feedback(true);
            }
          }
        }
      }

      // Increment visit count and save it to cookies
      const newVisitCount = visits + 1;
      Cookies.set("chordsPageVisitCount", newVisitCount, { expires: 7 });
      setVisitCount(newVisitCount);
    }
  }, []);

  const handleFeedbackSubmit = () => {
    // Set a cookie for 7 days after feedback is submitted
    Cookies.set("feedbackSubmitted", "true", { expires: 7 });
    setshow_feedback(false);
  };

  const handleFeedbackClose = () => {
    // Store the current date when the user closes the feedback without submitting
    const currentDate = new Date().toISOString();
    Cookies.set("feedbackClosedWithoutSubmit", currentDate, { expires: 1 });
    setshow_feedback(false);
  };

  useEffect(() => {
    if (playlist_added_successfully) {
      showToast1();
      localStorage.removeItem("playlist_added_successfully");
    }
  }, [playlist_added_successfully]);

  const toggleDropdown = () => {
    if (!showDropdown) {
      get_playlist(); // Call get_playlist if dropdown is hidden
    } else {
      setShowDropdown(false); // Hide the dropdown if it's currently visible
    }
  };

  const handleyouClick_playlist = () => {
    setshowplaylist_popup(true);
  };

  const Go_back = () => {
    window.history.back();
  };

  const handleyouClick_playlist1 = () => {
    setshowplaylist_popup(false);
  };

  // const toggleAutoScroll = () => {
  //   if (isAutoScrolling) {
  //     stopAutoScroll();
  //   } else {
  //     startAutoScroll();
  //   }
  // };

  // // Start auto-scrolling with current speed
  // const startAutoScroll = () => {
  //   setIsAutoScrolling(true);
  //   scrollIntervalRef.current = setInterval(() => {
  //     window.scrollBy(0, scrollSpeed);

  //     // Stop scrolling at the end of the page
  //     if (
  //       window.innerHeight + window.pageYOffset >=
  //       document.body.offsetHeight
  //     ) {
  //       stopAutoScroll();
  //     }
  //   }, scrollDelay);
  // };

  // // Stop the auto-scroll by clearing the interval
  // const stopAutoScroll = () => {
  //   clearInterval(scrollIntervalRef.current);
  //   setIsAutoScrolling(false);
  // };

  const increaseScrollSpeed = () => {
    setScrollSpeed((prevSpeed) => Math.min(prevSpeed + 1, 5));
  };

  const decreaseScrollSpeed = () => {
    setScrollSpeed((prevSpeed) => Math.max(0, prevSpeed - 1, 0));
  };

  useEffect(() => {
    // Clear the previous interval if speed changes
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
    }

    // If scrollSpeed is greater than 0, set up a new interval
    if (scrollSpeed > 0) {
      scrollIntervalRef.current = setInterval(() => {
        window.scrollBy(0, scrollSpeed); // Scroll the page vertically based on the scrollSpeed
      }, 100); // Adjust this value to control the smoothness of scrolling
    }

    // Cleanup interval when the component unmounts or scrollSpeed is 0
    return () => {
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
        scrollIntervalRef.current = null;
      }
    };
  }, [scrollSpeed]);

  // // Effect to reset the interval when the scroll speed changes and auto-scrolling is active
  // useEffect(() => {
  //   if (isAutoScrolling) {
  //     stopAutoScroll();
  //     startAutoScroll();
  //   }
  //   // Cleanup when component unmounts or auto-scroll is stopped
  //   return () => clearInterval(scrollIntervalRef.current);
  // }, [scrollSpeed]);

  // useEffect(() => {
  //   return () => {
  //     stopAutoScroll(); // Stop auto-scroll when the component is unmounted or navigating away
  //   };
  // }, []);

  const downgrade1 = () => {
    setDown(true);
  };

  let videoUrl = `https://www.youtube.com/embed/${youtube_id}`;
  let videoId;



  const get_retry_version = async (id) => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);
      const response = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const variantData = response.data.variants.map((variant) => ({
          requestId: variant.request_id,
          name: variant.name,
          state: variant.state,
        }));
        setVariants(variantData);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };



  const get_playlist = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/playlists/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const playlistsData = response.data.map((item) => ({
          name: item.name,
        }));
        setPlaylists(playlistsData);
        setShowDropdown(true); // Show the dropdown after data is fetched
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const [PopupVisible, setPopupVisible] = useState(false);

  const closepopup = () => {
    setPopupVisible(false);
    window.location.reload();
  };
  let startIndex = "";
  let endIndex = "";
  if (result.includes("```")) {
    startIndex = result.indexOf("```") + 3;
    endIndex = result.lastIndexOf("```");
  } else {
    startIndex = result.indexOf("") + 3;
    endIndex = result.lastIndexOf("");
  }

  let lines = [];
  if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
    const extractedText = result.substring(startIndex, endIndex).trim();
    lines = extractedText.split("\n");
  }
  let downlines = [];

  const checkAndColorLines = (line) => {
    const hasMoreThan3Spaces = line.match(/\s{3,}/);
    const segments = [];
    let currentSegment = "";
    for (let i = 0; i < line.length; i++) {
      if (line[i] === " ") {
        if (currentSegment !== "") {
          segments.push(currentSegment);
          currentSegment = "";
        }
        segments.push(" ");
      } else {
        currentSegment += line[i];
      }
    }
    if (currentSegment !== "") {
      segments.push(currentSegment);
    }
    if (hasMoreThan3Spaces || line.length < 2) {
      downlines.push(line);
      // let spaceCount = segments.filter(segment => segment === ' ').length;
      return (
        <Verse>
          {segments.map((segment, index) =>
            segment === " " ? (
              <React.Fragment key={index}>&nbsp;</React.Fragment>
            ) : (
              <span
                key={index}
                style={{
                  backgroundColor: "#F0984B",
                  padding: "2px 4px",
                  borderRadius: "4px",
                  color: "#FFFFFF",
                }}
              >
                {segment}
              </span>
            )
          )}
        </Verse>
      );
    } else {
      downlines.push(line);
      return <Verse style={{ color: "#FFFFFF" }}>{line}</Verse>;
    }
  };

  const generatePDF = () => {
    // Get the current window dimensions
    const width = window.innerWidth;
    const height = window.innerHeight;

    // Construct the logo HTML
    const logo = `<img src="${new_logo_white}" alt="Logo" style="width: 250px;">`; // Adjust width as needed

    // Open a new window with the same dimensions
    const newWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height}`
    );

    // Get the content of the cj2 div
    const cj2Content = document.getElementById("cj2").innerHTML;

    // Capture CSS from the original window to apply in the new window
    const styles = Array.from(document.styleSheets)
      .map((styleSheet) => {
        try {
          return Array.from(styleSheet.cssRules)
            .map((rule) => rule.cssText)
            .join("");
        } catch (error) {
          return ""; // Skip any stylesheets that can't be accessed
        }
      })
      .join("\n");

    // Write HTML to the new window's document
    if (newWindow) {
      newWindow.document.write(`
      <html>
        <head>
          <title>${title1}</title>
          <style>
            body { font: 550 15px Poppins, sans-serif; white-space: pre-wrap; margin: 0; padding: 0; }
            ${styles}
            /* Logo styling */
            #logo { display: flex; justify-content: center; margin-bottom: 15px; }
            /* Title styling */
            #title1 { font-size: 20px; margin-bottom: 20px; text-align: left; margin-left: 3px; }
            /* cj2 content styling */
            #cj2, #cj2 * { color: black !important; margin-left: 3px; 
              page-break-inside: avoid;}
          </style>
          <!-- Load html2pdf.js -->
          <script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.9.2/html2pdf.bundle.min.js"></script>
        </head>
        <body>
          <div id="logo">${logo}</div>
          <div id="title1">${title1}</div>
          <div id="cj2">${cj2Content}</div>
          <script>
            // Convert the content to PDF and download
            window.onload = () => {
              const element = document.body; // Capture the full body
              html2pdf()
                .set({ filename: '${title1}.pdf' })
                .from(element)
                .save();
            };
          </script>
        </body>
      </html>
    `);

      // Close the document to trigger rendering
      newWindow.document.close();
    }
  };

  // const generatePDF = () => {
  //   const logoUrl = new_logo; // Your logo image path
  //   const songName = title1; // Song name variable

  //   // Render myVariable as a string
  //   const downloadpdf_newpage = createMyVariable1(myVariable);

  //   // Open a new window for the PDF preview
  //   const newWindow = window.open('', '_blank');

  //   // Write structured HTML with inline styles for layout and formatting
  //   if (newWindow) {
  //     newWindow.document.write(`
  //       <html>
  //         <head>
  //           <style>
  //             body { font-family: Arial, sans-serif; padding: 20px; text-align: left; }
  //             .logo { text-align: center; margin-bottom: 20px; }
  //             .song-name { font-size: 18px; font-weight: bold; margin-bottom: 10px; }
  //             .content { display: flex; flex-wrap: wrap; gap: 5px; font-size: 14px; }
  //             .line {
  //               display: flex;
  //               flex-wrap: wrap;
  //               margin-bottom: 10px;
  //             }
  //             .box {
  //               font-size: 15px;
  //               display: inline-block;
  //               padding: 3px;
  //               margin-bottom: 4px;
  //               white-space: nowrap;
  //             }
  //             .box.words {
  //               color: #FFFFFF;
  //             }
  //             .box.chord { /* Assuming chord type is differentiated */
  //               color: #F0984B;
  //             }
  //             .box.editable {
  //               border: 1px solid;
  //             }
  //           </style>
  //         </head>
  //         <body>
  //           <div class="logo">
  //             <img src="${logoUrl}" alt="Logo" style="max-width: 100px;" />
  //           </div>
  //           <div class="song-name">${songName}</div>
  //           <div class="content">${downloadpdf_newpage}</div>
  //         </body>
  //       </html>
  //     `);
  //     newWindow.document.close();
  //   } else {
  //
  //   }
  // };

  // const generatePDF = () => {
  // const doc = new jsPDF("landscape");
  // doc.setFontSize(10);
  // doc.text(title1, 10, 10);

  // const lineHeight = 8; // Reduced from 12 to minimize vertical spacing
  // const margin = 10;
  // const pageHeight = doc.internal.pageSize.height;
  // const maxLinesPerPage = Math.floor((pageHeight - 2 * margin) / lineHeight);
  // let y = margin + 10;
  // let lineIndex = 0;

  // const extractTextFromJSX = (jsxElements) => {
  //   let lines = [];
  //   let maxWordsLength = 10;

  //   React.Children.forEach(jsxElements, (lineElement) => {
  //     if (React.isValidElement(lineElement)) {
  //       let lineContent = [];
  //       let wordsInLine = 0;

  //       React.Children.forEach(lineElement.props.children, (boxElement) => {
  //         if (React.isValidElement(boxElement)) {
  //           const boxText = boxElement.props.children;
  //           // Remove any extra spaces in the text
  //           const normalizedText = boxText.replace(/\s+/g, " ").trim();
  //           const wordCount = normalizedText.split(/\s+/).length;
  //           wordsInLine += wordCount;

  //           // Calculate exact text width for precise positioning
  //           const textWidth = doc.getTextWidth(normalizedText);
  //           lineContent.push({
  //             text: normalizedText,
  //             width: textWidth // Store actual text width
  //           });
  //         }
  //       });

  //       maxWordsLength = Math.max(maxWordsLength, wordsInLine);
  //       lines.push(lineContent);
  //     }
  //   });

  //   return { lines, maxWordsLength };
  // };

  // const { lines: extractedLines, maxWordsLength } = extractTextFromJSX(myVariable);
  // const maxWidth = Math.min(15, Math.ceil(maxWordsLength));

  // extractedLines.forEach((line) => {
  //   let xOffset = margin;

  //   // Calculate if we need a new page before processing the line
  //   if (y + lineHeight > pageHeight - margin) {
  //     doc.addPage();
  //     y = margin + 10;
  //     lineIndex = 0;
  //   }

  //   line.forEach(({ text, width }) => {
  //     // Split text only if absolutely necessary
  //     if (width > maxWidth) {
  //       const textLines = doc.splitTextToSize(text, maxWidth);
  //       textLines.forEach((textLine, index) => {
  //         doc.text(textLine, xOffset, y + (index * lineHeight));
  //       });
  //     } else {
  //       doc.text(text, xOffset, y);
  //     }

  //     // Move x position by exact text width plus minimal spacing
  //     xOffset += maxWidth;
  //   });

  //   // Move to next line with minimal spacing
  //   y += lineHeight;
  //   lineIndex++;
  // });

  // const pdfBlob = doc.output("blob");
  // saveAs(pdfBlob, title1 + ".pdf");
  // window.print();
  //   const element = document.body;

  //   const lyricsDiv = document.getElementById("cj");

  //   if (lyricsDiv) {
  //     // Clone the original div so the changes do not affect the original
  //     const clonedDiv = document.createElement("div"); // New div to hold the content

  //     // Create and add logo
  //     const logo = new Image(); // More concise way to create an img element
  //     logo.src = new_logo; // Replace with the correct path to your logo
  //     logo.alt = "Logo"; // Set alt text for accessibility

  //     // Set styling for the logo
  //     logo.style.width = "100px";
  //     logo.style.display = "block";
  //     logo.style.margin = "0 auto";

  //     clonedDiv.appendChild(logo); // Add logo at the top of the cloned div

  //     // Create and add title
  //     const title = document.createElement("h2");
  //     title.textContent = title1; // Replace with your desired title
  //     title.style.color = "white"; // Set title color to white
  //     title.style.textAlign = "center"; // Center the title
  //     clonedDiv.appendChild(title); // Add title below the logo

  //     // Clone the original content and append it to the new clonedDiv
  //     const contentClone = lyricsDiv.cloneNode(true);
  //     clonedDiv.style.backgroundColor = "#282828"; // Change to your desired color

  //     clonedDiv.appendChild(contentClone); // Append the original content below the logo and title

  //     // Create a new element for PDF
  //     const pdfElement = document.createElement("div");
  //     pdfElement.appendChild(clonedDiv); // Append the newly structured div to the pdfElement

  //     // Save the PDF
  //     html2pdf()
  //       .from(pdfElement)
  //       .save(`${title1}.pdf`)
  //       .then(() => {
  //
  //       })
  //       .catch((err) => {
  //
  //       });
  //   } else {
  //
  //   }
  // };
  // const generatePDF = () => {
  //   const doc = new jsPDF("landscape");
  //   doc.setFontSize(7);
  //   doc.text(title1, 10, 10);

  //   const lineHeight = 12; // Standard line height
  //   const margin = 10; // Margins from top and bottom
  //   const pageHeight = doc.internal.pageSize.height;
  //   const maxLinesPerPage = Math.floor((pageHeight - 2 * margin) / lineHeight); // Calculate max lines per page based on height
  //   let y = margin + 10; // Start y position below the title
  //   let lineIndex = 0; // Keep track of the line index

  //   // Extracts text from the JSX elements (for chords and words)
  //   const extractTextFromJSX = (jsxElements) => {
  //     let lines = [];

  //     React.Children.forEach(jsxElements, (lineElement) => {
  //       if (React.isValidElement(lineElement)) {
  //         let lineContent = [];
  //         React.Children.forEach(lineElement.props.children, (boxElement) => {
  //           if (React.isValidElement(boxElement)) {
  //             const boxText = boxElement.props.children;
  //             const normalizedText = boxText.replace(/\s+/g, " ").trim();
  //             const boxWidth = boxElement.props.width || 0;

  //             // Calculate the text width required for this box
  //             const textWidth = doc.getTextWidth(normalizedText) + 10;
  //             lineContent.push({
  //               text: normalizedText,
  //               width: Math.max(boxWidth, textWidth),
  //             });
  //           }
  //         });
  //         lines.push(lineContent);
  //       }
  //     });

  //     return lines;
  //   };

  //   const extractedLines = extractTextFromJSX(myVariable);
  //

  //   extractedLines.forEach((line) => {
  //     let xOffset = margin; // Horizontal position (x) starts at the left margin
  //     let maxBoxHeight = 0; // To track the tallest box in this line

  //     // Set the max width for all boxes in this line
  //     const maxWidth = 16; // Adjust this width based on your layout

  //     // Process each box (chord or word) in the line
  //     line.forEach(({ text }) => {
  //       const textLines = doc.splitTextToSize(text, maxWidth - 4); // Split text to fit box
  //       const boxHeight = textLines.length * lineHeight + 4; // Calculate the height of the text block
  //       maxBoxHeight = Math.max(maxBoxHeight, boxHeight); // Track the tallest box in the current line

  //       // Check if there's enough space for the next line on the current page
  //       if (y + maxBoxHeight > pageHeight - margin) {
  //         doc.addPage(); // Add new page
  //         y = margin + 10; // Reset y-position for the new page
  //         lineIndex = 0; // Reset the line index for the new page
  //       }

  //       // Render the text inside where the box would be (box drawing removed)
  //       textLines.forEach((textLine, index) => {
  //         doc.text(textLine, xOffset + 2, y + lineHeight * (index + 1));
  //       });

  //       xOffset += maxWidth; // Move the x offset for the next box (chord or word)
  //     });

  //     // Update y for the next line (move y down by the max height of the current line)
  //     y += maxBoxHeight;
  //     lineIndex++;
  //   });

  //   const pdfBlob = doc.output("blob");
  //   saveAs(pdfBlob, title1 + ".pdf");
  // };

  // const generatePDF = () => {
  //   const doc = new jsPDF();

  //   const content = document.getElementById("pdf");

  //   html2canvas(content).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const imgWidth = 190; // Full width of A4 page
  //     const pageHeight = 275;
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     let heightLeft = imgHeight;
  //     let position = 0;

  //     const addPageTitle = () => {
  //       doc.setFontSize(12); // Adjust font size as needed
  //       doc.text(title1, 10, 10); // Position and content of the title
  //     };

  //     // Add first page with title
  //     doc.addImage(imgData, "PNG", 10, 20, imgWidth, imgHeight); // Adjusted position to make space for title
  //     addPageTitle(); // Add title to the first page
  //     heightLeft -= pageHeight;

  //     // doc.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
  //     // heightLeft -= pageHeight;

  //     while (heightLeft >= 0) {
  //       position = heightLeft - imgHeight;
  //       if (heightLeft > 0) {
  //         doc.addPage();
  //       }
  //       doc.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;
  //     }

  //     const pdfBlob = doc.output("blob");
  //     const title = title1; // Replace with your desired filename
  //     saveAs(pdfBlob, title + ".pdf");
  //   });

  // };

  const handleSongCardClick = async (requestId, url) => {
    setLoading(true);
    try {
      const encodedRequestId = encodeURIComponent(requestId);
      const response_status = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response_status.status === 200) {
        // setLoading(true);

        if (response_status.data.state === "complete") {
          // navigate("/chords", { state: { result1: response_status.data } });
          visitchords(
            encodedRequestId,
            response_status.data,
            response_status.data.youtube_id
          );
          // setLoading(false);
        } else if (
          response_status.data.state === "pending" ||
          response_status.data.state === "in_progress"
        ) {
          setTimeout(() => handleSongCardClick(requestId, url), 1000);
        } else {
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };


  useEffect(() => {
    setLoading(true);

    const fetchPlaylists = async () => {
      try {
        const response = await axios.get(
          `${config.apiUrl}/playlists/__default_playlist__`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const records = response.data.records;
          if (records.length === 0) {
            // setCount(0);
          } else {
            const mappedPlaylists = records
              .filter((record) => record.state === "complete")
              .map((record) => {
                const thumbnailUrl = record.thumbnail_url.split("?")[0];
                return {
                  title: record.title,
                  url: record.url,
                  id: record.request_id,
                  image: thumbnailUrl,
                };
              });

            const ids = mappedPlaylists.map((playlist) => playlist.id);
            for (let i = 0; i < ids.length; i++) {
              let id = ids[i].split("#");
              if (youtube_id === id[1]) {
                isuser = true;
              }
            }

            setPlaylists(mappedPlaylists.slice(0, 8));
          }
          setLoading(false);
        }

      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
      }
    };

    fetchPlaylists();

  }, []);

  const visitchords = async (requestId, responsed, youtube_id) => {
    try {
      const response = await fetch(
        `${config.apiUrl}/playlists/__default_playlist__/${requestId}/visit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        window.scrollTo(0, 0);

        navigate(`/chord/${initials}/youtube/${youtube_id}`);
        // window.location.reload();

        // navigate("/chords", { state: { result1: responsed } });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };
  const formatChordName = (chord) => {
    let processedChord = chord
      .replace(/Major/g, "maj")
      .replace(/major/g, "maj")
      .replace(/Minor/g, "min")
      .replace(/minor/g, "min")
      .replace(/diminished/g, "dim")
      .replace(/augmented/g, "aug")
      .replace(/add/g, "add")

      .replace(/m/g, "min")
      .replace(/#/g, "__")
      .replace(/dimin/g, "dim")
      .replace(/\/[0-9]+/g, "_")
      .replace(/minaj/g, "maj");

    // Handle single letter or sharp chords (e.g., E -> Emaj, C# -> C#maj)
    if (
      processedChord.length === 1 || processedChord.length === 2 ||
      (processedChord.length === 3 && processedChord.includes("__"))
    ) {
      processedChord += "maj";
    }

    return processedChord;
  };


  const for_display_ChordName = (chord) => {
    let processedChord = chord
      .replace(/maj/g, "")
      .replace(/min/g, "m")
      .replace(/min7/g, "m7")
      .replace(/maj7/g, "7")
      .replace(/min6/g, "m6")
      .replace(/min7b5/g, "m7b5")
      .replace(/min9/g, "m9")

    return processedChord;
  };

  const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => {
      images[item.replace("./", "").replace(".svg", "")] = r(item);
    });

    return images;
  };

  const images = importAll(
    require.context("../../assets/cords", false, /\.svg$/)
  );
  const View_all_Songs = (event) => {
    window.scrollTo(0, 0);
    navigate("/all_songs");
  };

  const retry_chord = async () => {
    const encodedRequestId1 = encodeURIComponent(id);

    // try {
    // setLoading(true);

    // const response = await fetch(
    //   `${config.apiUrl}/url_requests/${encodedRequestId1}/retry`,
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );

    // if (response.status === 200) {
    //   navigate("/user");
    // }

    // } catch (error) {
    //   if (error.response && error.response.status === 401) {
    //     clearToken();
    //     navigate("/");
    //   }
    // } finally {
    //   // setLoading(false);
    // }
  };

  const share_button = async () => {
    const encodedRequestId1 = encodeURIComponent(id);
    if (selectedValue === "original") {
      try {
        setLoading(true);
        const response = await fetch(
          `${config.apiUrl}/url_requests/${encodedRequestId1}/share_url`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          const share_url = data.share_url;
          setshare_url1(share_url);
          setLoading(false);
          setshare_popup(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
        setLoading(false);
      }

    } else {
      try {
        setLoading(true);
        const response = await fetch(
          `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}/share_url`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          const share_url = data.share_url;
          setshare_url1(share_url);
          setLoading(false);
          setshare_popup(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
        setLoading(false);
      }
    }


  };

  const handleCopyLink = () => {
    const textToCopy = url;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        showToast();
      })
      .catch((err) => { });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // const handleDelete = (id1) => {
  //   if (!loading) {
  //     delete_song(id1);
  //   }
  // };

  const add_playlist1 = async (playlistName) => {
    try {
      const response = await axios.post(
        `${config.apiUrl}/playlists/${playlistName}/add`,
        { records: [id] }, // Replace `id1` with your actual record ID(s)
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const textToCopy = `Added into ${playlistName} playlist`;

        // Copy text to clipboard
        navigator.clipboard
          .writeText(textToCopy)
          .then(() => {
            // Show toast after successful copy with a dynamic message
            // showToast1(textToCopy); // Pass the message to the toast
          })
          .catch((err) => { });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const retry = async (id) => {
    const encodedRequestId1 = encodeURIComponent(id);
    try {
      const response = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/pdf`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          responseType: "arraybuffer",
        }
      );

      if (response.status === 200) {
        const outputFileName = title1 + `.pdf`;
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = outputFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  // const edit_chord = () => {
  //   setIsEditing((prevIsEditing) => {
  //     const newIsEditing = !prevIsEditing;
  //     setImageSrc(newIsEditing ? save_edit : edit_chords);
  //     // Reset the editable state if exiting edit mode
  //     if (!newIsEditing) setEditable({ row: null, index: null });
  //     return newIsEditing;
  //   });
  // };

  const edit_chord = () => {
    if (selectedValue === "original") {
      setIsEditing(false);
      setEditable({ row: null, index: null });
      handle_save_as_original_popup();
    } else {
      //
      const groupedChords = {};
      chordsTs.forEach((chord, chordIndex) => {
        const chordTime = Math.round(chord.time || 0.1);
        groupedChords[chordTime] = {
          time: chord.time,
          chord: chord.chord,
          originalIndex: chordIndex,
        };
      });

      const gp = Object.values(groupedChords).map((chord) => ({
        time: chord.time,
        chord: chord.chord,
        originalIndex: chord.originalIndex,
      }));
      setchordsTs(gp);
      submit_chords_and_words1(gp);
      //
      setIsEditing((prevIsEditing) => !prevIsEditing);
    }
  };

  const exit_edit_mode = () => {
    setIsEditing(false);
    submit_all_chords_and_words();
    setEditable({ row: null, index: null });
  };

  const save_changes = async () => {
    handle_save_as_original_popup();
  };

  useEffect(() => {
    if (timesArray.length > 0) {
    }
  }, [timesArray]);

  const processRows = () => {
    const rows = [];
    const groupedChords = {};

    // Map chords to the last index by time
    const chordTimeToIndexMap = new Map();
    chordsTs.forEach((chord, index) => {
      chordTimeToIndexMap.set(chord.time, index); // Retain last chord at each time
    });

    // Group chords by time and retain the last occurrence's original index
    chordsTs.forEach((chord, index) => {
      const chordTime = Math.round(chord.time || 0.1);
      groupedChords[chordTime] = { ...chord, originalIndex: index };
    });

    // Create rows from groupedChords
    const gp = Object.values(groupedChords).map((chord) => ({
      time: chord.time,
      value: chord.chord,
      originalIndex: chord.originalIndex,
    }));

    gp.forEach((chord) => {
      rows.push({
        type: "chord",
        time: Math.round(chord.time || 0.1),
        value: chord.value,
        originalIndex: chord.originalIndex,
      });
    });

    // Process words similarly, mapping words by start time
    const wordTimeToIndexMap = new Map();
    wordsTs.forEach((word, index) => {
      wordTimeToIndexMap.set(word.start, index);
    });

    // Add words to rows
    wordsTs.forEach((word) => {
      if (word.word !== "") {
        // Skip empty words
        rows.push({
          type: "word",
          time: word.start,
          value: word.word,
          originalIndex: wordTimeToIndexMap.get(word.start),
        });
      }
    });

    // Sort rows by time
    rows.sort((a, b) => a.time - b.time);

    // Group chords and words in finalRows
    let currentChordsRow = [];
    let currentWordsRow = [];
    let currentWordIndices = [];
    let finalRows = [];
    let previousChord = null;
    const extractedTimes = [];

    wordsTs.forEach((wordObj) => {
      if (wordObj.word.includes("\n")) {
        extractedTimes.push(Math.round(wordObj.end));
      }
    });
    settimesArray(extractedTimes);

    rows.forEach((row) => {
      if (row.type === "chord") {
        if (previousChord !== row.value) {
          currentChordsRow.push(row.value);
          currentWordsRow.push("");
          currentWordIndices.push(row.originalIndex);
          previousChord = row.value;
        }
      } else if (row.type === "word") {
        if (row.value.includes("\n")) {
          const wordParts = row.value.split("\n");

          // Handle first part of split word
          if (wordParts[0] !== "") {
            // Skip empty word parts
            currentWordsRow.push(wordParts[0]);
            currentWordIndices.push(row.originalIndex); // Maintain the index for the first part
            currentChordsRow.push(""); // No chord for the first part

            finalRows.push({
              type: "chords",
              row: currentChordsRow,
              originalIndices: currentWordIndices, // Directly use currentWordIndices here
            });
            finalRows.push({
              type: "words",
              row: currentWordsRow,
              originalIndices: currentWordIndices,
            });

            // Reset rows for new line
            currentChordsRow = [];
            currentWordsRow = [];
            currentWordIndices = [];
            previousChord = null;
          }

          // Second part of the split word, if exists and non-empty
          if (wordParts[1] && wordParts[1] !== "") {
            currentWordsRow.push(wordParts[1]);
            currentWordIndices.push(row.originalIndex); // Maintain the same index for the second part
            currentChordsRow.push(""); // No chord for the second part
          }
        } else {
          if (row.value !== "") {
            // Skip empty words
            currentWordsRow.push(row.value);
            currentWordIndices.push(row.originalIndex);
            currentChordsRow.push("");
          }
        }
      }
    });

    // Add remaining rows
    if (currentChordsRow.length > 0 || currentWordsRow.length > 0) {
      finalRows.push({
        type: "chords",
        row: currentChordsRow,
        originalIndices: currentWordIndices, // Use currentWordIndices directly
      });
      finalRows.push({
        type: "words",
        row: currentWordsRow,
        originalIndices: currentWordIndices,
      });
    }

    // finalRows.forEach((rowData, index) => {
    //   console.log(`Row Type: ${rowData.type}`);
    //   console.log(`Row ${index + 1}:`, rowData.row);
    //   console.log(`Original Indices ${index + 1}:`, rowData.originalIndices);
    // });

    setFinalRows(finalRows);
    return finalRows;
  };

  const calculateWidth = (text) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = "15px Arial"; // Set the font size and style to match your UI
    const metrics = context.measureText(text);
    return metrics.width;
  };

  const sharpToFlatRootMap = {
    "C#": "Db",
    "D#": "Eb",
    "F#": "Gb",
    "G#": "Ab",
    "A#": "Bb",
  };

  const convertSharpToFlat = (chord) => {


    const displayChord = chord

      .replace(/maj(?![a-zA-Z0-9])/gi, "") // Remove "maj" suffix
      .replace(/minor(?![a-zA-Z0-9])/gi, "m") // Replace "minor" with "m"
      .replace(/min(?![a-zA-Z0-9])/gi, "m") // Replace "min" with "m" for consistency
      .replace(/m7(?![a-zA-Z0-9])/gi, "m7")
      .replace(/min7(?![a-zA-Z0-9])/gi, "m7");

    const root = displayChord.slice(0, 2);
    const rest = displayChord.slice(2);

    const flatRoot = sharpToFlatRootMap[root] || root;

    return flatRoot + rest;
  };

  const flatToSharpRootMap = {
    Db: "C#",
    Eb: "D#",
    Gb: "F#",
    Ab: "G#",
    Bb: "A#",
  };

  const handleChordUpdate = (index, newChord) => {
    // console.log("Updating chords at index:", index, "with:", newChord);
    const updatedChordsTs = [...chordsTs];
    updatedChordsTs[index] = {
      ...updatedChordsTs[index],
      chord: newChord,
    };
    // console.log("updatedChordsTs:", updatedChordsTs);
    setchordsTs(updatedChordsTs);
    submit_chords_and_words1(updatedChordsTs);
  };

  const handledelete_Chord = (time) => {
    setchordsTs((prevChords) => {
      const roundedTime = Math.round(time || 0.1);
      const updatedChordsTs = prevChords.filter(
        (chord) => Math.round(chord.time || 0.1) !== roundedTime
      );

      submit_chords_and_words1(updatedChordsTs);

      return updatedChordsTs;
    });

    // console.log(`Deleted chords with time group: ${time}`);
  };

  const handledelete_Words = (time, index, word) => {
    setwordsTs((prevWords) => {
      const updatedWordsTs = prevWords.filter((word, i) => i !== index);

      submit_chords_and_words(updatedWordsTs);

      return updatedWordsTs;
    });

    // console.log(`Deleted lyric at index: ${index} and time: ${time}`);
  };

  const handleLyricsUpdate = (index, newLyrics) => {
    let newword;
    if (lastitem !== "") {
      newword = newLyrics + "\n";
    } else {
      newword = newLyrics;
    }
    const updatedWordsTs = [...wordsTs];
    updatedWordsTs[index] = {
      ...updatedWordsTs[index],
      word: newword,
    };

    setwordsTs(updatedWordsTs);
    submit_chords_and_words(updatedWordsTs);
    setEditingWord(null);
  };

  const handleBlur1 = async (rowIndex, itemIndex, event) => {
    if (!isEditing) return;

    // console.log("onclick rowIndex ", rowIndex);
    // console.log("onclick itemIndex ", itemIndex);

    setrowIndex(rowIndex);
    setitemIndex(itemIndex);

    // Ensure finalRows exists and the rowIndex is valid
    try {
      const finalRow = finalRows[rowIndex];
      if (!finalRow) {
        // console.error("Invalid finalRow or rowIndex:", rowIndex);
        return;
      }

      const originalIndex = finalRow.originalIndices[itemIndex] || null; // Handle empty or invalid index
      // console.log("originalIndex ", originalIndex);

      if (finalRow.type === "chords") {
        let chordValue = null;
        let chordpreValue = null;
        let chordnextValue = null;
        let wordTime = null;

        if (originalIndex !== null && chordsTs[originalIndex]) {
          chordValue = chordsTs[originalIndex].chord || null;
        }
        if (originalIndex > 0 && chordsTs[originalIndex - 1]) {
          chordpreValue = chordsTs[originalIndex - 1].chord || null;
        }
        if (
          originalIndex < chordsTs.length - 1 &&
          chordsTs[originalIndex + 1]
        ) {
          chordnextValue = chordsTs[originalIndex + 1].chord || null;
        }

        // console.log("Corresponding Chords:", { chordValue });
        // console.log("Corresponding pre Chords:", { chordpreValue });
        // console.log("Corresponding next Chords:", { chordnextValue });

        const correspondingWord = wordsTs[originalIndex];
        if (correspondingWord) {
          const wordValue = correspondingWord.word;
          wordTime = correspondingWord.start;
          setchord_empty_time(wordTime);
          // console.log("Corresponding word:", { wordValue, wordTime });
        } else {
          // console.warn("No corresponding word found for this chord.");
        }

        const displayedChord = finalRow.row[itemIndex];
        setdisplayedWord(displayedChord);
        setwordIndex(originalIndex);
        setupdatedText("chords");

        if (displayedChord !== "") {
          handleyouClick_edittext();
        } else {
          handleyouClick_newtext2();
        }
      } else if (finalRow.type === "words") {
        let wordValue = null;
        let wordpreValue = null;
        let wordnextValue = null;
        let chordTime = null;

        if (originalIndex !== null && wordsTs[originalIndex]) {
          wordValue = wordsTs[originalIndex].word || null;
        }
        if (originalIndex > 0 && wordsTs[originalIndex - 1]) {
          wordpreValue = wordsTs[originalIndex - 1].word || null;
        }
        if (originalIndex < wordsTs.length - 1 && wordsTs[originalIndex + 1]) {
          wordnextValue = wordsTs[originalIndex + 1].word || null;
        }

        // console.log("Corresponding Words:", { wordValue });
        // console.log("Corresponding pre Words:", { wordpreValue });
        // console.log("Corresponding next Words:", { wordnextValue });

        const displayedWord = finalRow.row[itemIndex] || "";
        const previousWordIndex =
          itemIndex > 0 ? finalRow.originalIndices[itemIndex - 1] : null;
        const nextWordIndex =
          itemIndex < finalRow.row.length - 1
            ? finalRow.originalIndices[itemIndex + 1]
            : null;

        // console.log("displayedWord ", displayedWord);
        // console.log("previousWordIndex ", previousWordIndex);
        // console.log("nextWordIndex ", nextWordIndex);

        const correspondingChord = chordsTs[originalIndex];
        if (correspondingChord) {
          const chordValue = correspondingChord.chord;
          chordTime = correspondingChord.time;
          // console.log("Corresponding Chord:", { chordValue, chordTime });
        } else {
          // console.warn("No corresponding chord found for this word.");
        }

        let previousWordTime = null;
        let nextWordTime = null;

        // Check if previousWordIndex is null and display "null" if it is
        if (previousWordIndex === null) {
          // console.log("Previous Word: null");
        } else {
          if (wordsTs[previousWordIndex]) {
            previousWordTime = wordsTs[previousWordIndex].start;
            // console.log("Previous Word:", {
            //   index: previousWordIndex,
            //   time: previousWordTime,
            // });
          } else {
            // console.log("Previous Word: null (invalid index)");
          }
        }

        // Handle next word similarly
        if (nextWordIndex === null) {
          // console.log("Next Word: null");
        } else if (nextWordIndex !== null && wordsTs[nextWordIndex]) {
          nextWordTime = wordsTs[nextWordIndex].start;
          // console.log("Next Word:", {
          //   index: nextWordIndex,
          //   time: nextWordTime,
          // });
        }

        setdisplayedWord(displayedWord);
        setwordIndex(originalIndex);
        setupdatedText("words");

        if (
          wordValue == null &&
          wordpreValue == null &&
          wordnextValue == null
        ) {
          setword_empty_time(chordTime);
        } else {
          setword_empty_time("");
        }

        if (displayedWord !== "") {
          handleyouClick_edittext();
        } else {
          handleAddNewWord(rowIndex, itemIndex);
        }
      }
    } catch (error) {
      // console.error("Error handling blur:", error);
    }
  };

  const insertNewWord = (previousWord, nextWord, newWord) => {
    // console.log("Previous Word:", previousWord);
    // console.log("Next Word:", nextWord);

    setwordsTs((prevWordsTs) => {
      let newStart, newEnd;

      if (!previousWord && nextWord) {
        // If previousWord is null, use nextWord's timing
        newStart = nextWord.start - 0.2; // Reasonable start time before the next word
        newEnd = nextWord.start - 0.1; // End time just before the next word
      } else if (previousWord && !nextWord) {
        // If nextWord is null, use previousWord's timing
        newStart = previousWord.end + 0.1; // Start time just after the previous word
        newEnd = previousWord.end + 0.2; // Slightly after the previous word's end
      } else if (previousWord && nextWord) {
        // If both words exist, calculate based on their timings as before
        newStart = (previousWord.end + nextWord.start) / 2;
        newEnd = newStart + 0.1;
      } else if (!previousWord && !nextWord) {
        // If both are null, use word_empty_time
        newStart = word_empty_time;
        newEnd = word_empty_time + 0.1;

        // console.log("No neighboring words, using empty time:", word_empty_time);
      }

      const newWordObject = {
        word: newWord,
        start: newStart,
        end: newEnd,
      };

      // Insert the new word object
      let updatedWordsTs = [...prevWordsTs];

      if (nextWord) {
        // Find index of the next word and insert before it
        const nextIndex = prevWordsTs.findIndex(
          (word) => word.start === nextWord.start
        );
        if (nextIndex !== -1) {
          updatedWordsTs.splice(nextIndex, 0, newWordObject);
        }
      } else if (previousWord) {
        // Find index of the previous word and insert after it
        const previousIndex = prevWordsTs.findIndex(
          (word) => word.end === previousWord.end
        );
        if (previousIndex !== -1) {
          updatedWordsTs.splice(previousIndex + 1, 0, newWordObject);
        }
      } else {
        // If no previous or next word, append to the end
        updatedWordsTs.push(newWordObject);
      }

      // console.log("Updated wordsTs:", updatedWordsTs);

      // Call your external functions
      submit_chords_and_words(updatedWordsTs);
      processRows();

      return updatedWordsTs;
    });

    setpreviousWords(null);
    setnextWords(null);
  };

  const insertNewChord = (newChord) => {
    setchordsTs((prevChordsTs) => {
      const newWordObject = {
        chord: newChord,
        time: chord_empty_time,
      };

      // Insert the new word object
      let updatedChordsTs = [...prevChordsTs];
      if (updatedChordsTs) {
        updatedChordsTs.push(newWordObject);
      }

      // console.log("Updated ChordsTs:", updatedChordsTs);

      // Call your external functions
      submit_chords_and_words1(updatedChordsTs);
      processRows();

      return updatedChordsTs;
    });

    setchord_empty_time(null);
  };

  const handleAddNewWord = (rowIndex1, itemIndex1) => {
    // console.log("finalRows:", finalRows);
    // console.log("rowIndex:", rowIndex1);

    if (!finalRows || !Array.isArray(finalRows)) {
      // console.error("finalRows is not an array or not populated");
      return;
    }

    if (
      rowIndex1 === undefined ||
      rowIndex1 < 0 ||
      rowIndex1 >= finalRows.length
    ) {
      // console.error("Invalid rowIndex:", rowIndex1);
      return;
    }

    const finalRow = finalRows[rowIndex1];

    // if (!finalRow || finalRow.type !== "words") {
    //   console.error("Final row is not of type 'words':", finalRow);
    //   return;
    // }

    // if (!finalRow.row || itemIndex < 0 || itemIndex >= finalRow.row.length) {
    //   console.error("Invalid itemIndex or row data is not populated");
    //   return;
    // }

    const previousWordIndex =
      itemIndex1 > 0 ? finalRow.originalIndices[itemIndex1 - 1] : null;
    const nextWordIndex =
      itemIndex1 < finalRow.row.length - 1
        ? finalRow.originalIndices[itemIndex1 + 1]
        : null;

    const previousWord =
      previousWordIndex !== null && wordsTs[previousWordIndex]
        ? wordsTs[previousWordIndex]
        : null;
    const nextWord =
      nextWordIndex !== null && wordsTs[nextWordIndex]
        ? wordsTs[nextWordIndex]
        : null;

    // if (!previousWord || !nextWord) {
    //   console.error("Could not find previous or next word:", { previousWord, nextWord });
    //   return;
    // }

    setpreviousWords(previousWord);
    setnextWords(nextWord);

    // Trigger the new word input handler
    handleyouClick_newtext();
  };

  const addChord = (newChord, newtime) => {
    setchordsTs((prevChordsTs) => {
      const newWordObject = {
        time: newtime,
        chord: newChord,
      };

      // Insert the new word object
      let updatedChordsTs = [...prevChordsTs];
      if (updatedChordsTs) {
        updatedChordsTs.push(newWordObject);
      }

      // console.log("Updated ChordsTs:", updatedChordsTs);

      // Call your external functions
      submit_chords_and_words1(updatedChordsTs);
      // processRows();

      return updatedChordsTs;
    });
  };

  const addlyrics = (newChord, newtime) => {
    setwordsTs((prevWordsTs) => {
      const newWordObject = {
        word: newChord,
        start: newtime,
        end: newtime + 0.1,
      };
      let updatedWordsTs = [...prevWordsTs];

      if (updatedWordsTs) {
        updatedWordsTs.push(newWordObject);
      }
      // console.log("Updated wordsTs:", updatedWordsTs);

      submit_chords_and_words(updatedWordsTs);
      // processRows();

      return updatedWordsTs;
    });
  };

  const handleSave = () => {
    const exportData = {
      chords: chordsTs.map(({ time, chord }) => ({ time, chord })),
      words: wordsTs.map(({ word, start, end }) => ({ word, start, end })),
    };
    const blob = new Blob([JSON.stringify(exportData, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "music-data.json";
    a.click();
    URL.revokeObjectURL(url);
  };

  //   const handleDragStart = (item, type, e) => {
  //     e.stopPropagation();
  //     e.preventDefault(); // Prevent default drag behavior
  //     console.log("Drag Start Triggered");
  //     console.log("Drag Item:", item);
  //     console.log("Drag Type:", type);
  //     console.log("Drag index:", item.originalIndex);

  //     // Track the row of the dragged item
  //     const rowIndex = item.originalIndex; // Assuming `originalIndex` corresponds to the row index

  //     setIsDragging(true);
  //     setDragItem({ ...item, index: item.originalIndex, rowIndex }); // Add `rowIndex` to the dragItem
  //     setDragType(type);
  //     setIsActive1(true);
  //     console.log("Initialized Drag Item:", {
  //       ...item,
  //       index: item.originalIndex,
  //       rowIndex, // Store the row index
  //     });
  // };

  // const handleMouseMove1 = (e) => {
  //   if (!isDragging || !dragItem || !timelineRef.current) {
  //     console.log("Mouse move ignored:", {
  //       isDragging,
  //       dragItem,
  //       timelineRefExists: !!timelineRef.current,
  //     });
  //     return;
  //   }

  //   const rect = timelineRef.current.getBoundingClientRect();
  //   const x = e.clientX;// - rect.left;

  //   const startTime = 0;

  //   const time = startTime + (x / rect.width) * SECONDS_PER_LINE;

  //   const clampedTime = Math.max(0, Math.min(time, totalDuration));

  //   console.log("Mouse Move Detected");
  //   console.log("Relative X:", x);
  //   console.log("New Time:", clampedTime);

  //   const startTimeForSegment =
  //     Math.floor(clampedTime / SECONDS_PER_LINE) * SECONDS_PER_LINE;
  //   const endTimeForSegment = startTimeForSegment + SECONDS_PER_LINE;

  //   console.log(
  //     "Start Time:",
  //     startTimeForSegment,
  //     "End Time:",
  //     endTimeForSegment
  //   );

  //   if (dragType === "chord") {
  //     const updatedChord = {
  //       time: Math.max(
  //         startTimeForSegment,
  //         Math.min(endTimeForSegment, clampedTime)
  //       ),
  //       chord: dragItem.chord,
  //     };

  //     console.log("Updated Chord:", updatedChord);

  //     setchordsTs((prev) =>
  //       prev.map((c, index) =>
  //         index === dragItem.index
  //           ? { time: updatedChord.time, chord: updatedChord.chord }
  //           : c
  //       )
  //     );
  //   } else if (dragType === "word") {
  //     const duration = dragItem.end - dragItem.start;
  //     const updatedLyric = {
  //       ...dragItem,
  //       start: Math.max(
  //         startTimeForSegment,
  //         Math.min(endTimeForSegment - duration, clampedTime)
  //       ),
  //       end: Math.max(
  //         startTimeForSegment,
  //         Math.min(endTimeForSegment, clampedTime + duration)
  //       ),
  //     };

  //     console.log("Updated Lyric:", updatedLyric);

  //     setwordsTs((prev) =>
  //       prev.map((l) =>
  //         l.word === dragItem.word
  //           ? { word: l.word, start: updatedLyric.start, end: updatedLyric.end }
  //           : l
  //       )
  //     );
  //   }
  // };

  // const handleMouseMove1 = (e) => {
  //   if (!isDragging || !dragItem || !timelineRef.current) {
  //     console.log("Mouse move ignored:", {
  //       isDragging,
  //       dragItem,
  //       timelineRefExists: !!timelineRef.current,
  //     });
  //     return;
  //   }

  //   const rect = timelineRef.current.getBoundingClientRect();
  //   const x = e.clientX - rect.left; // Mouse position relative to the timeline
  //   const y = e.clientY - rect.top;  // Mouse Y-position for detecting the row

  //   const rowHeight = 50; // Adjust this value to match your row height
  //   const rowIndex = Math.floor(y / rowHeight); // Determine the active row index
  //   const startTimeForRow = rowIndex * SECONDS_PER_LINE;

  //   // Ensure dragging stays within the timeline bounds
  //   if (y < 0 || y > rect.height) {
  //     console.log("Mouse outside timeline bounds");
  //     return;
  //   }

  //   const time = startTimeForRow + (x / rect.width) * SECONDS_PER_LINE;

  //   // Clamp time to the current row
  //   const clampedTime = Math.max(
  //     startTimeForRow,
  //     Math.min(startTimeForRow + SECONDS_PER_LINE, time)
  //   );

  //   console.log("Mouse Move Detected");
  //   console.log("Row Index:", rowIndex, "Start Time for Row:", startTimeForRow);
  //   console.log("Clamped Time:", clampedTime);

  //   if (dragType === "chord") {
  //     // Update chord position within the current row
  //     const updatedTime = clampedTime;

  //     const updatedChord = {
  //       ...dragItem,
  //       time: updatedTime, // Update the time of the dragged chord
  //     };

  //     console.log("Updated Chord:", updatedChord);

  //     setchordsTs((prev) =>
  //       prev.map((c, index) =>
  //         index === dragItem.index
  //           ? { ...c, time: updatedChord.time } // Apply the new time to the specific chord
  //           : c
  //       )
  //     );
  //   } else if (dragType === "word") {
  //     // Update word position while maintaining its duration
  //     const duration = dragItem.end - dragItem.start;

  //     const updatedStart = Math.max(
  //       startTimeForRow,
  //       Math.min(startTimeForRow + SECONDS_PER_LINE - duration, clampedTime)
  //     );
  //     const updatedEnd = updatedStart + duration;

  //     const updatedLyric = {
  //       ...dragItem,
  //       start: updatedStart,
  //       end: updatedEnd,
  //     };

  //     console.log("Updated Lyric:", updatedLyric);

  //     setwordsTs((prev) =>
  //       prev.map((l) =>
  //         l.word === dragItem.word
  //           ? { ...l, start: updatedLyric.start, end: updatedLyric.end }
  //           : l
  //       )
  //     );
  //   }
  // };

  // const handleMouseUp1 = () => {
  //   console.log("Mouse Up Detected");
  //   setIsDragging(false);
  //   setDragItem(null);
  //   setDragType(null);
  //   setIsActive1(false);
  // };

  // useEffect(() => {
  //   console.log("Effect Triggered - Is Dragging:", isDragging);
  //   if (isDragging) {
  //     document.addEventListener("mousemove", handleMouseMove1);
  //     document.addEventListener("mouseup", handleMouseUp1);
  //   } else {
  //     document.removeEventListener("mousemove", handleMouseMove1);
  //     document.removeEventListener("mouseup", handleMouseUp1);
  //   }

  //   return () => {
  //     console.log("Effect Cleanup - Removing Event Listeners");
  //     document.removeEventListener("mousemove", handleMouseMove1);
  //     document.removeEventListener("mouseup", handleMouseUp1);
  //   };
  // }, [isDragging]);

  //   import { useRef } from "react";
  // import { List } from "react-window";

  // const VirtualizedWordRenderer = ({
  //   wordsTs,
  //   startTime,
  //   index,
  //   setCurrentword,
  //   handleyouClick_editword,
  //   handleyouClick_delwords,
  //   setHoveredItem,
  //   isDragging,
  //   dragItem,
  //   hoveredItem,
  // }) => {
  //   // Memoize prepared words to avoid unnecessary recalculations
  //   const preparedWords = React.useMemo(() => {
  //     return wordsTs.map((word, wordIndex) => ({
  //       ...word,
  //       originalIndex: wordIndex,
  //     }));
  //   }, [wordsTs]);

  //   // Ref to track hovered item without causing re-renders
  //   const hoveredItemRef = useRef(hoveredItem);

  //   const WordItem = React.memo(({ data, index, style }) => {
  //     const word = data[index];
  //     const leftPosition = ((word.start - startTime) / SECONDS_PER_LINE) * 100;

  //     // Event handlers with useRef to prevent unnecessary re-renders
  //     const handleMouseEnter = () => {
  //       hoveredItemRef.current = `word-${word.originalIndex}`;
  //       setHoveredItem(hoveredItemRef.current);
  //     };

  //     const handleMouseLeave = () => {
  //       hoveredItemRef.current = null;
  //       setHoveredItem(null);
  //     };

  //     const handleEdit = () => {
  //       setCurrentword({
  //         word: word.word,
  //         time: word.start,
  //         index: word.originalIndex,
  //       });
  //       handleyouClick_editword();
  //     };

  //     const handleDelete = () => {
  //       setCurrentword({
  //         word: word.word,
  //         time: word.start,
  //         index: word.originalIndex,
  //       });
  //       handleyouClick_delwords();
  //     };

  //     return (
  //       <ItemContainer
  //         key={`word-${index}-${word.originalIndex}`}
  //         top={77}
  //         left={leftPosition}
  //         type="word"
  //         isActive1={isDragging && dragItem?.type === "lyric" && dragItem?.index === word.originalIndex}
  //         onMouseEnter={handleMouseEnter}
  //         onMouseLeave={handleMouseLeave}
  //         style={style}
  //       >
  //         <span>{word.word}</span>
  //         {hoveredItemRef.current === `word-${word.originalIndex}` && (
  //           <>
  //             <Pencil
  //               size={14}
  //               style={{ cursor: "pointer", marginLeft: "1px" }}
  //               onClick={handleEdit}
  //             />
  //             <Trash2
  //               size={14}
  //               style={{ cursor: "pointer", marginLeft: "1px" }}
  //               onClick={handleDelete}
  //             />
  //           </>
  //         )}
  //       </ItemContainer>
  //     );
  //   });

  //   return (
  //     <List
  //       height={150}
  //       width={800}
  //       itemCount={preparedWords.length}
  //       itemSize={30}
  //       layout="horizontal"
  //     >
  //       {WordItem}
  //     </List>
  //   );
  // };

  // const renderTimeSegment = (startTime, endTime, index) => {
  //   // Optimize chord grouping
  //   const groupedChords = chordsTs.reduce((acc, chord, chordIndex) => {
  //     const chordTime = Math.round(chord.time || 0.1);
  //     if (!acc[chordTime]) {
  //       acc[chordTime] = {
  //         time: chord.time,
  //         chord: chord.chord,
  //         originalIndex: chordIndex,
  //       };
  //     }
  //     return acc;
  //   }, {});

  //   const wordsToRender = wordsTs.map((word, wordIndex) => ({
  //     ...word,
  //     originalIndex: wordIndex,
  //   }));

  //   return (
  //     <TimeSegment key={index}>
  //       <TimeLabel>{`${Math.floor(startTime)}s`}</TimeLabel>
  //       {startTime % 5 === 0 && startTime !== 0 && <Divider />}

  //       {/* Render chords */}
  //       {Object.values(groupedChords)
  //         .filter((chord) => chord.time >= startTime && chord.time < endTime)
  //         .map((chord, chordIndex) => (
  //           <ItemContainer
  //             key={`chord-${index}-${chordIndex}`}
  //             left={((chord.time - startTime) / SECONDS_PER_LINE) * 100}
  //             top={35}
  //             type="chord"
  //             isActive1={isDragging && dragItem?.type === "chord" && dragItem?.index === chordIndex}
  //             onMouseEnter={() => setHoveredItem(`chord-${index}-${chordIndex}`)}
  //             onMouseLeave={() => setHoveredItem(null)}
  //           >
  //             <span>{chord.chord}</span>

  //             {hoveredItem === `chord-${index}-${chordIndex}` && (
  //               <>
  //                 <Pencil
  //                   size={14}
  //                   style={{ cursor: "pointer", marginLeft: "1px" }}
  //                   onClick={() => {
  //                     setCurrentChord({
  //                       chord: chord.chord,
  //                       time: chord.time,
  //                       index: chord.originalIndex,
  //                     });
  //                     handleyouClick_edittext();
  //                   }}
  //                 />
  //                 <Trash2
  //                   size={14}
  //                   style={{ cursor: "pointer", marginLeft: "1px" }}
  //                   onClick={() => {
  //                     setCurrentChord({
  //                       chord: chord.chord,
  //                       time: chord.time,
  //                       index: chord.originalIndex,
  //                     });
  //                     handleyouClick_delchords();
  //                   }}
  //                 />
  //               </>
  //             )}
  //           </ItemContainer>
  //         ))}

  //       {/* Render words */}
  //       <VirtualizedWordRenderer
  //         wordsTs={wordsTs}
  //         startTime={startTime}
  //         index={index}
  //         setCurrentword={setCurrentword}
  //         handleyouClick_editword={handleyouClick_editword}
  //         handleyouClick_delwords={handleyouClick_delwords}
  //         setHoveredItem={setHoveredItem}
  //         isDragging={isDragging}
  //         dragItem={dragItem}
  //       />

  //       <TextContainer>
  //         <BoxedSpan onClick={() => {
  //           setaddchortime(startTime + SECONDS_PER_LINE / 2);
  //           handleyouClick_newtext2();
  //         }}>
  //           +C
  //         </BoxedSpan>
  //         <BoxedSpan onClick={() => {
  //           setaddwordtime(startTime + SECONDS_PER_LINE / 2);
  //           handleyouClick_newtext();
  //         }}>
  //           +L
  //         </BoxedSpan>
  //       </TextContainer>
  //     </TimeSegment>
  //   );
  // };

  // const timeBuffer = useRef([]);
  // const processingRef = useRef(false);
  // const lastProcessedTime = useRef(0);
  // const batchTimeoutRef = useRef(null);

  // const processBatch = () => {
  //   if (timeBuffer.current.length === 0) {
  //     processingRef.current = false;
  //     return;
  //   }

  //   // Sort all times in ascending order
  //   const sortedTimes = [...new Set(timeBuffer.current)].sort((a, b) => a - b);

  //   // Clear the buffer
  //   timeBuffer.current = [];

  //   // Process times sequentially
  //   let index = 0;

  //   const processNext = () => {
  //     if (index < sortedTimes.length) {
  //       const time = sortedTimes[index];
  //       if (time > lastProcessedTime.current) {
  //         lastProcessedTime.current = time;
  //         setCurrentTime(time);
  //         index++;
  //         setTimeout(processNext, 50);
  //       } else {
  //         index++;
  //         processNext();
  //       }
  //     } else {
  //       processingRef.current = false;
  //       if (timeBuffer.current.length > 0) {
  //         startProcessing();
  //       }
  //     }
  //   };

  //   processNext();
  // };

  // const startProcessing = () => {
  //   if (processingRef.current) return;

  //   if (batchTimeoutRef.current) {
  //     clearTimeout(batchTimeoutRef.current);
  //   }

  //   processingRef.current = true;

  //   // Wait a short time to collect more times before processing
  //   batchTimeoutRef.current = setTimeout(() => {
  //     processBatch();
  //   }, 100);
  // };

  // const handleTimeUpdate = (time) => {
  //   const roundedTime = Math.round(time * 10) / 10;
  //   timeBuffer.current.push(roundedTime);

  //   if (!processingRef.current) {
  //     startProcessing();
  //   }
  // };

  // useEffect(() => {
  //   return () => {
  //     if (batchTimeoutRef.current) {
  //       clearTimeout(batchTimeoutRef.current);
  //     }
  //   };
  // }, []);

  useEffect(() => {

    //  console.log("currenttime : ",currentTime);
  }, [currentTime]);

  const handleStartLoop = () => {
    setStartLoopTime(currentTime); // Set the start loop time to the current time
  };

  const handleEndLoop = () => {
    setEndLoopTime(currentTime); // Set the end loop time to the current time
  };

  const draggedItemRef = useRef(null);

  const TimeSegmentRenderer = ({
    startTime,
    endTime,
    index,
    chordsTs,
    setChordsTs,
    wordsTs,
    setWordsTs,
    handleyouClick_edittext,
    handleyouClick_delchords,
    handleyouClick_editword,
    handleyouClick_delwords,
    setaddchortime,
    setaddwordtime,
    totalDuration, currentTime, loopStart, loopEnd, isLoop, onLoopChange,
  }) => {
    const [draggedItem, setDraggedItem] = useState(null);
    const containerRef = useRef(null);
    const [hoveredItem, setHoveredItem] = useState(null);

    const [draggedItem1, setDraggedItem1] = useState(null);
    const [isDraggingStart1, setIsDraggingStart1] = useState(false);
    const [isDraggingEnd1, setIsDraggingEnd1] = useState(false);
    const dragStartTimeRef = useRef(null);
    const dragEndTimeRef = useRef(null);

    // Group chords by time
    const groupedChords = {};
    chordsTs.forEach((chord, chordIndex) => {
      const chordTime = chord.time; //Math.round(chord.time || 0.1);
      groupedChords[chordTime] = {
        time: chord.time,
        chord: chord.chord,
        originalIndex: chordIndex,
      };
    });

    const gp = Object.values(groupedChords).map((chord) => ({
      time: chord.time,
      chord: chord.chord,
      originalIndex: chord.originalIndex,
    }));

    const handleDragStart = (e, item, type, rowIndex) => {
      // console.log('Drag Start:', { type, item, sourceRowIndex: index });
      e.dataTransfer.setData("text/plain", "");

      const itemData = {
        ...item,
        type,
        sourceRowIndex: index,
        originalTime: type === "word" ? item.start : item.time,
      };
      draggedItemRef.current = itemData; // Store dragged item in the ref

      const dragImage = document.createElement("div");
      dragImage.textContent = type === "chord" ? item.chord : item.word;
      dragImage.style.position = "absolute";
      dragImage.style.top = "-1000px";
      document.body.appendChild(dragImage);
      e.dataTransfer.setDragImage(dragImage, 0, 0);
      document.body.removeChild(dragImage);
    };

    const handleDragOver = (e) => {
      e.preventDefault();

      if (!draggedItem) return;

      const timelineContainer = document.querySelector(
        ".w-full.border.border-gray-700"
      );
      if (!timelineContainer) return;

      const timelineRect = timelineContainer.getBoundingClientRect();
      const relativeY = e.clientY - timelineRect.top;
      const totalRows = Math.ceil(totalDuration / SECONDS_PER_LINE1);
      const rowHeight = timelineRect.height / totalRows;
      const currentRow = Math.floor(relativeY / rowHeight);

      e.dataTransfer.dropEffect = "move";
    };


    // Set the width of the container dynamically based on text width
    useEffect(() => {
      if (hiddenTextRef.current) {
        setWidth(`${hiddenTextRef.current.offsetWidth + 5}px`); // Added padding/margin to account for space
      }
    }, [editedWordValue]);

    // Ensure focus remains on the editable text field
    const handleFocus = () => {
      if (contentEditableRef.current) {
        contentEditableRef.current.focus();
      }
    };

    useEffect(() => {
      if (hiddenTextRef1.current) {
        setWidth(`${hiddenTextRef1.current.offsetWidth + 5}px`); // Added padding/margin to account for space
      }
    }, [editedChordValue]);

    // Ensure focus remains on the editable text field
    const handleFocus1 = () => {
      if (contentEditableRef1.current) {
        contentEditableRef1.current.focus();
      }
    };

    const handleDrop = (e) => {
      e.preventDefault();
      const draggedItem = draggedItemRef.current;
      // console.log('draggedItem:', draggedItem);
      // console.log("Dragged Item:", draggedItem); // Check if the dragged item is set
      if (!draggedItem) {
        // console.log('No dragged item found');
        return;
      }

      const timelineContainer = document.querySelector(
        ".w-full.border.border-gray-700"
      );
      if (!timelineContainer) {
        // console.log('Timeline container not found during drop');
        return;
      }

      // Log timeline container dimensions
      const timelineRect = timelineContainer.getBoundingClientRect();

      const relativeY = e.clientY - timelineRect.top;
      const totalRows = Math.ceil(totalDuration / SECONDS_PER_LINE1);
      const rowHeight = timelineRect.height / totalRows;
      const targetRowIndex = Math.floor(relativeY / rowHeight);

      const segmentRect = containerRef.current.getBoundingClientRect();
      const relativeX = e.clientX - segmentRect.left;
      const horizontalPercent = Math.max(
        0,
        Math.min(1, relativeX / segmentRect.width)
      );

      // Calculate new time
      const rowStartTime = targetRowIndex * SECONDS_PER_LINE1;
      const timeWithinRow = horizontalPercent * SECONDS_PER_LINE1;
      const newTime = Math.min(
        rowStartTime + timeWithinRow,
        totalDuration - 0.1
      );


      if (draggedItem.type === "word") {


        const updatedWords = [...wordsTs];
        if (draggedItem.originalIndexes) {
          draggedItem.originalIndexes
            .sort((a, b) => b - a)
            .forEach((index) => {
              updatedWords.splice(index, 1);
            });
          updatedWords.push({
            word: draggedItem.word,
            start: newTime,
            end: newTime + 0.1,
          });
        } else {
          updatedWords.splice(draggedItem.originalIndex, 1);
          updatedWords.push({
            word: draggedItem.word,
            start: newTime,
            end: newTime + 0.1,
          });
        }
        // console.log('Updated words array:', updatedWords);
        setwordsTs(updatedWords);
        submit_chords_and_words(updatedWords);
      } else if (draggedItem.type === "chord") {


        const updatedChords = [...chordsTs];
        updatedChords.splice(draggedItem.originalIndex, 1);
        updatedChords.push({
          chord: draggedItem.chord,
          time: newTime,
        });
        // console.log('Updated chords array:', updatedChords);
        setchordsTs(updatedChords);
        submit_chords_and_words1(updatedChords);
      }

      setDraggedItem(null);
    };


    const handleTouchStart = (e, item, type) => {
      if (e.targetTouches.length === 1) {
        draggedItemRef.current = {
          ...item,
          type,
          sourceRowIndex: rowIndex,
          originalTime: type === "word" ? item.start : item.time,
        };
      }
    };

    const handleTouchMove = (e) => {
      e.preventDefault();
      if (!draggedItemRef.current) return;

      const timelineContainer = containerRef.current;
      const rect = timelineContainer.getBoundingClientRect();
      const touch = e.touches[0];
      const relativeX = touch.clientX - rect.left;
      const relativeY = touch.clientY - rect.top;

      // Adjust the scroll position if the touch is near the edges
      const scrollThreshold = 50; // Adjust as needed
      if (relativeY < scrollThreshold) {
        // Near the top
        timelineContainer.scrollTop -= 10; // Adjust scroll speed
      } else if (relativeY > rect.height - scrollThreshold) {
        // Near the bottom
        timelineContainer.scrollTop += 10; // Adjust scroll speed
      }

      const newTime =
        Math.min(relativeX / rect.width, 1) * SECONDS_PER_LINE1 + startTime;
      draggedItemRef.current.newTime = newTime;
    };

    const handleTouchEnd = () => {
      if (!draggedItemRef.current) return;

      const draggedItem = draggedItemRef.current;
      if (draggedItem.type === "chord") {
        const updatedChords = [...chordsTs];
        updatedChords.splice(draggedItem.originalIndex, 1);
        updatedChords.push({ ...draggedItem, time: draggedItem.newTime });
        setchordsTs(updatedChords);
      } else if (draggedItem.type === "word") {
        const updatedWords = [...wordsTs];
        updatedWords.splice(draggedItem.originalIndex, 1);
        updatedWords.push({ ...draggedItem, start: draggedItem.newTime });
        setwordsTs(updatedWords);
      }
      draggedItemRef.current = null;
    };

    const [smoothCurrentTime, setSmoothCurrentTime] = useState(currentTime);
    const animationFrameId = useRef(null);

    useEffect(() => {
      // Smooth update logic for animation
      const smoothUpdate = () => {
        // Only update if the song is playing
        if (isPlaying) {
          setSmoothCurrentTime((prevTime) => {
            const diff = currentTime - prevTime;
            const smoothingFactor = 0.1; // Control smoothness: smaller factor for smoother transition
            return prevTime + diff * smoothingFactor;
          });
        }
        animationFrameId.current = requestAnimationFrame(smoothUpdate);
      };

      // Start the animation when playing
      if (isPlaying) {
        animationFrameId.current = requestAnimationFrame(smoothUpdate);
      }

      // Cleanup animation frame on component unmount or when song is not playing
      return () => {
        if (animationFrameId.current) {
          cancelAnimationFrame(animationFrameId.current);
        }
      };
    }, [currentTime, isPlaying]);

    const isActiveRow = currentTime >= startTime && currentTime < endTime;

    // Calculate smooth left position for the red line
    const smoothLeftPosition = useMemo(() => {
      if (!isActiveRow) return '0%';

      const relativeTime = Math.max(0, smoothCurrentTime - startTime);
      const position = (relativeTime / (endTime - startTime)) * 100;
      return `${Math.min(position, 100)}%`; // Clamp the position to 100%
    }, [smoothCurrentTime, startTime, endTime, isActiveRow]);






    // Tracks if the start time is set
    // const [loopStart, setLoopStart] = useState(null);
    // const [loopEnd, setLoopEnd] = useState(null);
    const handleRowClick = (e) => {
      if (isLoop) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const clickX = e.clientX - containerRect.left;
        const rowWidth = containerRect.width;

        // Calculate the time based on the click position
        const clickedTime = startTime + (clickX / rowWidth) * (endTime - startTime);

        if (!isStartSet) {
          // First click sets the start time
          if (clickedTime <= (loopEnd || endTime)) { // Ensure the start time is before the end time
            setLoopStart(clickedTime);
            setIsStartSet(true);
            // console.log("Start time:", clickedTime);
          }
        } else {
          // Second click sets the end time
          if (clickedTime > loopStart) { // Ensure the end time is after the start time
            setLoopEnd(clickedTime);
            setIsStartSet(false);
            // console.log("End time:", clickedTime);
          }
        }
      }
    };


    return (
      <TimeSegment
        key={index}
        ref={containerRef}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleRowClick}
        style={{ position: 'relative', overflow: 'hidden' }}
      >
        {isActiveRow && (
          <div
            style={{
              position: 'absolute',
              top: 10,
              left: smoothLeftPosition,
              height: '100%',
              width: '2px',
              backgroundColor: 'red',
              zIndex: 100,

            }}
          />
        )}







        {loopStart && (
          <div
            style={{
              position: "absolute",
              left: `${((loopStart - startTime) / (endTime - startTime)) * 100}%`, // Start marker position
              top: 0,
              bottom: 0,
              width: "4px",
              backgroundColor: "#F0984B",
              cursor: "ew-resize",
              zIndex: 1000,
            }}
          />
        )}

        {/* Loop End Marker */}
        {loopEnd && (
          <div
            style={{
              position: "absolute",
              left: `${((loopEnd - startTime) / (endTime - startTime)) * 100}%`, // End marker position (fix added)
              top: 0,
              bottom: 0,
              width: "4px",
              backgroundColor: "#F0984B",
              cursor: "ew-resize",
              zIndex: 1000,
            }}
          />
        )}

        {loopStart && loopEnd && (
          <div
            style={{
              position: "absolute",
              left: `${((loopStart - startTime) / (endTime - startTime)) * 100}%`, // Start position
              top: 0,
              bottom: 0,
              width: `${((loopEnd - loopStart) / (endTime - startTime)) * 100}%`, // Width between the start and end
              backgroundColor: "#747474", // Change to your desired color
              zIndex: 0, // Ensure it appears behind the markers
            }}
          />
        )}


        {[...Array(Math.ceil((endTime - startTime) / 1))].map((_, i) => {
          const timeLabel = Math.floor(startTime + i);
          return (
            <TimeLabel
              key={`time-label-${timeLabel}`}
              style={{
                left: (i / (endTime - startTime)) * 100 + "%",
              }}
            >
              {timeLabel} {"s"}
            </TimeLabel>
          );
        })}

        {startTime % 1 === 0 && startTime !== 0 && <Divider />}

        {gp
          .filter((chord) => chord.time >= startTime && chord.time < endTime)
          .map((chord, chordIndex) => {
            const isHighlighted =
              currentTime >= chord.time - 0.5 &&
              currentTime <= chord.time + 0.5;
            return (
              <ItemContainer
                key={`chord-${index}-${chordIndex}`}
                left={((chord.time - startTime) / SECONDS_PER_LINE1) * 100}
                top={25}
                type="chord"
                isHighlighted={isHighlighted}
                ref={isHighlighted ? wordRef : null}
                onMouseEnter={() =>
                  setHoveredItem(`chord-${index}-${chordIndex}`)
                }
                onMouseLeave={() => setHoveredItem(null)}
              >
                {hoveredItem === `chord-${index}-${chordIndex}` &&
                  !editingChord &&
                  !edt2 &&
                  !version_select && (
                    <GripButton
                      draggable
                      onDragStart={(e) =>
                        handleDragStart(e, chord, "chord", index)
                      }
                      onTouchStart={(e) => handleTouchStart(e, chord, "chord")}
                      onTouchMove={handleTouchMove}
                      onTouchEnd={handleTouchEnd}
                      style={{ position: "absolute", left: -25, cursor: "grab" }}
                    >
                      <GripHorizontal size={14} />
                    </GripButton>
                  )}

                {/* Inline editing for chords */}
                {editingChord?.originalIndex === chord.originalIndex && !edt2 && !version_select ? (
                  <InputText20>

                    <InputText2
                      ref={contentEditableRef1}
                      contentEditable
                      suppressContentEditableWarning
                      onKeyDown={(e) => {
                        // Handle Enter key
                        // if (e.key === 'Enter') {
                        //   e.preventDefault();  // Prevent the default behavior (e.g., creating a new line)
                        //   handleWordSave();
                        //   return;
                        // }

                        // Prevent backspace from triggering page scrolling or navigation
                        if (e.key === 'Backspace') {
                          e.preventDefault();

                          const selection = window.getSelection();
                          if (!selection.rangeCount) return;

                          const range = selection.getRangeAt(0);
                          const { startContainer, startOffset } = range;

                          if (startOffset > 0 && startContainer.nodeType === Node.TEXT_NODE) {
                            // Delete character from the text node
                            const text = startContainer.textContent;
                            startContainer.textContent =
                              text.slice(0, startOffset - 1) + text.slice(startOffset);

                            // Set the cursor position
                            range.setStart(startContainer, startOffset - 1);
                            range.collapse(true);
                            selection.removeAllRanges();
                            selection.addRange(range);
                          } else if (startOffset === 0 && startContainer.previousSibling) {
                            // Handle merging with the previous node if the cursor is at the start
                            const prevNode = startContainer.previousSibling;
                            if (prevNode.nodeType === Node.TEXT_NODE) {
                              const prevText = prevNode.textContent;
                              const currText = startContainer.textContent;
                              prevNode.textContent = prevText + currText;

                              // Remove the current empty node
                              startContainer.parentNode.removeChild(startContainer);

                              // Update the cursor
                              range.setStart(prevNode, prevText.length);
                              range.collapse(true);
                              selection.removeAllRanges();
                              selection.addRange(range);
                            }
                          }
                          // Update the state
                          // setEditedWordValue(contentEditableRef.current.textContent);
                          return;
                        }
                        // Prevent default behavior for other keys to manage insertion manually
                        if (e.key.length === 1) {
                          e.preventDefault();

                          const selection = window.getSelection();
                          const range = selection.getRangeAt(0);

                          // Insert the character manually at the current cursor position
                          const textNode = document.createTextNode(e.key);
                          range.insertNode(textNode);

                          // Move the cursor after the inserted text
                          range.setStartAfter(textNode);
                          range.setEndAfter(textNode);
                          selection.removeAllRanges();
                          selection.addRange(range);

                        }
                        // setEditedWordValue(contentEditableRef.current.textContent);
                      }}
                      onInput={(e) => {
                        const text = contentEditableRef1.current.textContent || '';
                        setEditedChordValue(text);
                      }}

                      style={{
                        userSelect: 'text',
                        WebkitUserSelect: 'text',
                        cursor: 'text',
                        outline: 'none',
                        caretColor: 'white'
                      }}
                    >
                      {editedChordValue}
                    </InputText2>

                    {/* HiddenText for calculating width if needed */}
                    <HiddenText ref={hiddenTextRef1}>{editedChordValue}</HiddenText>
                    <IconButton40
                      onClick={() => {
                        const text = contentEditableRef1.current?.textContent || '';
                        handleChordSave(text);
                      }}

                      disabled={!editedChordValue.trim()}
                      aria-label="Save"
                    >
                      <img src={green_tick1} alt="Save" />
                    </IconButton40>
                    <IconButton42

                      onClick={handleChordCancel}
                      aria-label="Cancel"
                    >
                      <img src={white_cancel} alt="Cancel" />
                    </IconButton42>
                  </InputText20>
                ) : (
                  <span>{chord.chord}</span>
                )}

                {/* Show error message if validation fails */}
                {editingChord?.originalIndex === chord.originalIndex &&
                  validationError && (
                    <div className="text-red-500 text-xs mt-1">
                      {validationError}
                    </div>
                  )}

                {/* Editing and delete buttons */}
                {hoveredItem === `chord-${index}-${chordIndex}` &&
                  !editingChord && !edt2 && !version_select && (
                    <>
                      <Pencil
                        size={14}
                        style={{ cursor: "pointer", marginLeft: "1px" }}
                        onClick={() => handleEditChordStart(chord)}
                      />
                      <Trash2
                        src={del_variant}
                        size={14}
                        style={{ cursor: "pointer", marginLeft: "1px" }}
                        onClick={() => {
                          setCurrentChord({
                            chord: chord.chord,
                            time: chord.time,
                            index: chord.originalIndex,
                          });
                          handleyouClick_delchords();
                        }}
                      />
                    </>
                  )}
              </ItemContainer>
            );
          })}

        {/* Render words (with drag functionality triggered by the grip button) */}
        {Object.values(
          wordsTs
            .map((word, wordIndex) => ({ ...word, originalIndex: wordIndex }))
            .reduce((acc, word) => {
              const { start } = word;
              if (!acc[start]) {
                acc[start] = {
                  word: word.word,
                  start: word.start,
                  originalIndexes: [word.originalIndex],
                };
              } else {
                acc[start].word += ` ${word.word}`;
                acc[start].originalIndexes.push(word.originalIndex);
              }
              return acc;
            }, {})
        )
          .filter((group) => group.start >= startTime && group.start < endTime)
          .map((word, index) => {
            // Check if this word group should be highlighted
            const isHighlighted =
              currentTime >= word.start - 0.5 &&
              currentTime <= word.start + 0.5;

            return (
              <ItemContainer
                key={`word-${index}-${word.originalIndexes[0]}`}
                top={57}
                left={((word.start - startTime) / SECONDS_PER_LINE1) * 100}
                type="word"
                isHighlighted={isHighlighted}
                ref={isHighlighted ? wordRef : null}

                onMouseEnter={() =>
                  setHoveredItem(`word-${word.originalIndexes[0]}`)
                }
                onMouseLeave={() => setHoveredItem(null)}
              >
                {hoveredItem === `word-${word.originalIndexes[0]}` &&
                  !editingWord && !edt2 && !version_select && (
                    <>

                      <GripButton
                        draggable
                        onDragStart={(e) =>
                          handleDragStart(e, word, "word", index)
                        }
                        style={{
                          position: "absolute",
                          left: -25,
                          cursor: "grab",
                        }}
                      >
                        <GripHorizontal size={14} />
                      </GripButton>
                    </>
                  )}

                {editingWord?.originalIndex === word.originalIndexes[0] && !edt2 && !version_select ? (
                  <>

                    <InputText20>



                      <InputTextword
                        ref={contentEditableRef}
                        contentEditable
                        suppressContentEditableWarning
                        onKeyDown={(e) => {
                          // Handle Enter key
                          // if (e.key === 'Enter') {
                          //   e.preventDefault();  // Prevent the default behavior (e.g., creating a new line)
                          //   handleWordSave();
                          //   return;
                          // }

                          // Prevent backspace from triggering page scrolling or navigation
                          if (e.key === 'Backspace') {
                            e.preventDefault();

                            const selection = window.getSelection();
                            if (!selection.rangeCount) return;

                            const range = selection.getRangeAt(0);
                            const { startContainer, startOffset } = range;

                            if (startOffset > 0 && startContainer.nodeType === Node.TEXT_NODE) {
                              // Delete character from the text node
                              const text = startContainer.textContent;
                              startContainer.textContent =
                                text.slice(0, startOffset - 1) + text.slice(startOffset);

                              // Set the cursor position
                              range.setStart(startContainer, startOffset - 1);
                              range.collapse(true);
                              selection.removeAllRanges();
                              selection.addRange(range);
                            } else if (startOffset === 0 && startContainer.previousSibling) {
                              // Handle merging with the previous node if the cursor is at the start
                              const prevNode = startContainer.previousSibling;
                              if (prevNode.nodeType === Node.TEXT_NODE) {
                                const prevText = prevNode.textContent;
                                const currText = startContainer.textContent;
                                prevNode.textContent = prevText + currText;

                                // Remove the current empty node
                                startContainer.parentNode.removeChild(startContainer);

                                // Update the cursor
                                range.setStart(prevNode, prevText.length);
                                range.collapse(true);
                                selection.removeAllRanges();
                                selection.addRange(range);
                              }
                            }
                            // Update the state
                            // setEditedWordValue(contentEditableRef.current.textContent);
                            return;
                          }
                          // Prevent default behavior for other keys to manage insertion manually
                          if (e.key.length === 1) {
                            e.preventDefault();

                            const selection = window.getSelection();
                            const range = selection.getRangeAt(0);

                            // Insert the character manually at the current cursor position
                            const textNode = document.createTextNode(e.key);
                            range.insertNode(textNode);

                            // Move the cursor after the inserted text
                            range.setStartAfter(textNode);
                            range.setEndAfter(textNode);
                            selection.removeAllRanges();
                            selection.addRange(range);

                          }
                          // setEditedWordValue(contentEditableRef.current.textContent);
                        }}
                        onInput={(e) => {
                          const text = e.target.textContent || '';

                          setEditedWordValue(text);
                        }}
                        style={{
                          userSelect: 'text',
                          WebkitUserSelect: 'text',
                          cursor: 'text',
                          outline: 'none',
                          caretColor: 'white',
                        }}
                      >
                        {editedWordValue}
                      </InputTextword>





                      <HiddenText ref={hiddenTextRef}>
                        {editedWordValue}
                      </HiddenText>
                      <IconButton40

                        onClick={() => {
                          const text = contentEditableRef.current?.textContent || '';
                          handleWordSave(text);
                        }}
                        disabled={!editedWordValue.trim()}
                        aria-label="Save"
                      >
                        <img src={green_tick1} alt="Save" />
                      </IconButton40>
                      <IconButton42
                        onClick={handleWordCancel}
                        aria-label="Cancel"
                      >
                        <img src={white_cancel} alt="Cancel" />
                      </IconButton42>
                    </InputText20>
                  </>
                ) : (
                  <span>{word.word}</span>
                )}

                {hoveredItem === `word-${word.originalIndexes[0]}` &&
                  !editingWord && !edt2 && !version_select && (
                    <>

                      <Pencil
                        size={14}
                        style={{ cursor: "pointer", marginLeft: "1px" }}
                        onClick={() => {
                          setCurrentword({
                            word: word.word,
                            time: word.start,
                            index: word.originalIndexes[0],
                          });
                          handleEditWordStart(word);
                        }}
                      />
                      <Trash2
                        src={del_variant}
                        size={14}
                        style={{ cursor: "pointer", marginLeft: "1px" }}
                        onClick={() => {
                          setCurrentword({
                            word: word.word,
                            time: word.start,
                            index: word.originalIndexes[0],
                          });
                          handleyouClick_delwords();
                        }}
                      />
                    </>
                  )}
              </ItemContainer>
            );
          })}

        <TextContainer>
          {/* Display the new chords for this row */}
          {newChords
            .filter((chord) => chord.row === index)
            .map((chord, chordIndex) => (
              <ItemContainer1
                key={`new-chord-${index}-${chordIndex}`}
                left={((chord.time - 0) / SECONDS_PER_LINE1) * 100}
                top={35}
                type="chord"
                onMouseEnter={() =>
                  setHoveredItem(`new-chord-${index}-${chordIndex}`)
                }
                onMouseLeave={() => setHoveredItem(null)}
              // draggable
              // onDragStart={(e) => handleDragStart1(e, chord, "chord", index)}
              // onDragEnd={(e) => handleDragEnd(e, chord, "chord", index)} // Handle drag end to update the position
              >
                {hoveredItem === `new-chord-${index}-${chordIndex}` &&
                  !editingChord && (
                    <GripButton
                      draggable
                      onDragStart={(e) =>
                        handleDragStart1(e, chord, "chord", index)
                      }
                      style={{
                        position: "absolute",
                        left: -25,
                        cursor: "grab",
                      }}
                    >
                      <GripHorizontal size={14} />
                    </GripButton>
                  )}
                {/* Chord text */}
                <span>{chord.chord}</span>

                {/* Show edit and delete buttons when hovering over the chord */}
                {hoveredItem === `new-chord-${index}-${chordIndex}` &&
                  !editingChord && (
                    <>
                      <Trash2
                        src={del_variant}
                        size={14}
                        style={{ cursor: "pointer", marginLeft: "1px" }}
                        onClick={() => {
                          handleDragEnd();
                        }}
                      />
                    </>
                  )}
              </ItemContainer1>
            ))}

          {newWord
            .filter((word) => word.row === index)
            .map((word, wordIndex) => (
              <ItemContainer1
                key={`new-word-${index}-${wordIndex}`}
                left={((word.time - 0) / SECONDS_PER_LINE1) * 100}
                top={35}
                type="word"
                onMouseEnter={() =>
                  setHoveredItem(`new-word-${index}-${wordIndex}`)
                }
                onMouseLeave={() => setHoveredItem(null)}
              // draggable
              // onDragStart={(e) => handleDragStart1(e, word, "word", index)}
              // onDragEnd={(e) => handleDragEnd(e, word, "word", index)} // Handle drag end to update the position
              >
                {hoveredItem === `new-word-${index}-${wordIndex}` && !editingWord && (
                  <>

                    <GripButton
                      draggable
                      onDragStart={(e) =>
                        handleDragStart1(e, word, "word", index)
                      }
                      style={{
                        position: "absolute",
                        left: -25,
                        cursor: "grab",
                      }}
                    >
                      <GripHorizontal size={14} />
                    </GripButton>
                  </>
                )}
                {/* Word text */}
                <span>{word.word}</span>

                {/* Show edit and delete buttons when hovering over the word */}
                {hoveredItem === `new-word-${index}-${wordIndex}` &&
                  !editingWord && (
                    <>
                      <Trash2
                        src={del_variant}
                        size={14}
                        style={{ cursor: "pointer", marginLeft: "1px" }}
                        onClick={() => {
                          handleDragEnd();
                        }}
                      />
                    </>
                  )}
              </ItemContainer1>
            ))}

          {/* Boxed spans for adding new chords or words */}
          {!version_select && (
            <>
              <BoxedSpan

                onClick={() =>
                  handleAddChord(index, startTime + SECONDS_PER_LINE1 / 2)
                }
              >
                +C
              </BoxedSpan>
              <BoxedSpan
                onClick={() => {
                  // setaddwordtime(startTime + SECONDS_PER_LINE / 2);
                  // handleyouClick_newtext();
                  handleAddword(index, startTime + SECONDS_PER_LINE1 / 2);
                }}
              >
                +L
              </BoxedSpan>
            </>
          )}

        </TextContainer>
      </TimeSegment>
    );
  };

  // Update the parent component to pass totalDuration
  const renderTimeSegment = (startTime, endTime, index) => {
    return (
      <TimeSegmentRenderer
        startTime={startTime}
        endTime={endTime}
        index={index}
        chordsTs={chordsTs}
        setchordsTs={setchordsTs}
        wordsTs={wordsTs}
        setwordsTs={setwordsTs}
        handleyouClick_edittext={handleyouClick_edittext}
        handleyouClick_delchords={handleyouClick_delchords}
        handleyouClick_editword={handleyouClick_editword}
        handleyouClick_delwords={handleyouClick_delwords}
        setaddchortime={setaddchortime}
        setaddwordtime={setaddwordtime}
        totalDuration={totalDuration} // Add this prop
        currentTime={currentTime}
        loopStart={loopStart}
        loopEnd={loopEnd}
        isLoop={isLoop}
        onLoopChange={(newStart, newEnd) => {
          // Update your loop state here
          setLoopStart(newStart);
          setLoopEnd(newEnd);
        }}
      />
    );
  };



  const TimeSegmentRenderer1 = ({
    startTime,
    endTime,
    index,
    chordsTs,
    wordsTs,
    currentTime,
    handleyouClick_delchords,
    handleyouClick_delwords,
    totalDuration,
  }) => {
    // console.log("currentTime_time:", currentTime);
    // console.log("startTime:", startTime);
    // console.log("endTime:", endTime);

    // Group chords by time
    const groupedChords = {};
    chordsTs.forEach((chord, chordIndex) => {
      const chordTime = Math.round(chord.time || 0.1);
      groupedChords[chordTime] = {
        time: chord.time,
        chord: chord.chord,
        originalIndex: chordIndex,
      };
    });

    const gp = Object.values(groupedChords).map((chord) => ({
      time: chord.time,
      chord: chord.chord,
      originalIndex: chord.originalIndex,
    }));

    // Group words and check highlighting
    const groupedWords = Object.values(
      wordsTs
        .map((word, wordIndex) => ({ ...word, originalIndex: wordIndex }))
        .reduce((acc, word) => {
          const { start } = word;
          if (!acc[start]) {
            acc[start] = {
              word: word.word,
              start: word.start,
              originalIndexes: [word.originalIndex],
            };
          } else {
            acc[start].word += ` ${word.word}`;
            acc[start].originalIndexes.push(word.originalIndex);
          }
          return acc;
        }, {})
    );

    return (
      <TimeSegment100 key={index}>
        {/* Map time labels across the visible segment */}
        {[...Array(Math.ceil((endTime - startTime) / 1))].map((_, i) => {
          const timeLabel = Math.floor(startTime + i);
          return (
            <TimeLabel
              key={`time-label-${timeLabel}`}
              style={{
                left: (i / (endTime - startTime)) * 100 + "%",
              }}
            >
              {timeLabel} {"s"}
            </TimeLabel>
          );
        })}

        {startTime % 1 === 0 && startTime !== 0 && <Divider />}

        {/* Render Chords */}
        {gp
          .filter((chord) => chord.time >= startTime && chord.time < endTime)
          .map((chord, chordIndex) => {
            const isHighlighted =
              currentTime >= chord.time - 0.5 &&
              currentTime <= chord.time + 0.5;
            return (
              <ItemContainer11
                key={`chord-${index}-${chordIndex}`}
                left={((chord.time - startTime) / (endTime - startTime)) * 100}
                top={18}
                type="chord"
              //isHighlighted={isHighlighted}
              >
                <span>{chord.chord}</span>
              </ItemContainer11>
            );
          })}

        {/* Render Words */}
        {groupedWords
          .filter((group) => group.start >= startTime && group.start < endTime)
          .map((word, index) => {
            // Check if this word group should be highlighted
            const isHighlighted =
              currentTime >= word.start - 0.5 &&
              currentTime <= word.start + 0.5;

            return (
              <ItemContainer11
                key={`word-${index}-${word.originalIndexes[0]}`}
                top={50}
                left={((word.start - startTime) / (endTime - startTime)) * 100}
                type="word"
                isHighlighted={isHighlighted}
                ref={isHighlighted ? wordRef : null}
              >
                <span>{word.word}</span>
              </ItemContainer11>
            );
          })}
      </TimeSegment100>
    );
  };





  // const TimeSegmentRenderer1 = ({
  //   startTime,
  //   endTime,
  //   index,
  //   chordsTs,
  //   wordsTs,
  //   currentTime,
  // }) => {
  //   console.log("currentTime_time   ", currentTime);

  //   // Group chords by time
  //   const groupedChords = {};
  //   chordsTs.forEach((chord, chordIndex) => {
  //     const chordTime = chord.time;
  //     groupedChords[chordTime] = {
  //       time: chord.time,
  //       chord: chord.chord,
  //       originalIndex: chordIndex,
  //     };
  //   });

  //   const gp = Object.values(groupedChords).map((chord) => ({
  //     time: chord.time,
  //     chord: chord.chord,
  //     originalIndex: chord.originalIndex,
  //   }));

  //   // Highlight chords logic
  //   const highlightedChords = gp.filter(
  //     (chord) => currentTime >= chord.time - 0.5 && currentTime <= chord.time + 0.5
  //   );

  //   // Group and highlight words logic
  //   const groupedWords = wordsTs.reduce((acc, word) => {
  //     const { start } = word;
  //     if (!acc[start]) {
  //       acc[start] = {
  //         word: word.word,
  //         start: word.start,
  //         originalIndexes: [word.originalIndex],
  //       };
  //     } else {
  //       acc[start].word += ` ${word.word}`;
  //       acc[start].originalIndexes.push(word.originalIndex);
  //     }
  //     return acc;
  //   }, {});

  //   const highlightedWords = Object.values(groupedWords).filter(
  //     (wordGroup) => currentTime >= wordGroup.start - 0.1 && currentTime <= wordGroup.start + 0.1
  //   );

  //   // Calculate the position of the current time indicator
  //   const currentTimePosition = 
  //     currentTime >= startTime && currentTime <= endTime
  //       ? ((currentTime - startTime) / SECONDS_PER_LINE1) * 100
  //       : -10; // Move off-screen if not in this segment

  //   return (
  //     <TimeSegment key={index}>
  //       {/* Current Time Indicator */}
  //       <CurrentTimeIndicator 
  //         style={{ 
  //           left: `${currentTimePosition}%`,
  //           display: currentTimePosition >= 0 && currentTimePosition <= 100 ? 'block' : 'none'
  //         }} 
  //       />

  //       {/* Time Labels */}
  //       {[...Array(Math.ceil((endTime - startTime) / 1))].map((_, i) => {
  //         const timeLabel = Math.floor(startTime + i);
  //         return (
  //           <TimeLabel
  //             key={`time-label-${timeLabel}`}
  //             style={{
  //               left: (i / (endTime - startTime)) * 100 + "%",
  //             }}
  //           >
  //             {timeLabel} {"s"}
  //           </TimeLabel>
  //         );
  //       })}

  //       {startTime % 1 === 0 && startTime !== 0 && <Divider />}

  //       {/* Chords */}
  //       {gp
  //         .filter((chord) => chord.time >= startTime && chord.time < endTime)
  //         .map((chord, chordIndex) => (
  //           <ItemContainer
  //             key={`chord-${index}-${chordIndex}`}
  //             left={((chord.time - startTime) / SECONDS_PER_LINE1) * 100}
  //             top={35}
  //             type="chord"
  //             style={{
  //               backgroundColor: highlightedChords.includes(chord)
  //                 ? "yellow"
  //                 : "transparent",
  //             }}
  //           >
  //             <span>{chord.chord}</span>
  //           </ItemContainer>
  //         ))}

  //       {/* Words */}
  //       {highlightedWords.map((wordGroup, wordIndex) => (
  //         <ItemContainer
  //           key={`word-${index}-${wordGroup.originalIndexes[0]}`}
  //           top={77}
  //           left={((wordGroup.start - startTime) / SECONDS_PER_LINE1) * 100}
  //           type="word"
  //           style={{
  //             backgroundColor: highlightedWords.includes(wordGroup)
  //               ? "#282828"
  //               : "#282828",
  //           }}
  //         >
  //           <span>{wordGroup.word}</span>
  //         </ItemContainer>
  //       ))}
  //     </TimeSegment>
  //   );
  // };



  const renderTimeSegment1 = (startTime, endTime, index) => {
    return (
      <TimeSegmentRenderer1
        startTime={startTime}
        endTime={endTime}
        index={index}
        chordsTs={chordsTs}
        wordsTs={wordsTs}
        currentTime={currentTime}
        handleyouClick_delchords={handleyouClick_delchords}
        handleyouClick_delwords={handleyouClick_delwords}
        totalDuration={totalDuration}
      />
    );
  };





  const createMyVariable = (rows) => {
    return rows.map((row, rowIndex) => {
      return (
        <Line key={rowIndex} type={row.type}>
          {row.row.length === 0 || !row.row.some(Boolean) ? (
            <Box
              key={`empty-${rowIndex}`}
              type={row.type}
              width={row.widths[0] || 0}
              editMode={isEditing}
              isGrouped={row.grouped}
              style={{ height: "20px", fontSize: `${fontSize}px` }}
            />
          ) : (
            row.row.map((item, itemIndex) => {
              let isWordHighlighted = false;
              let isChordHighlighted = false;

              // Chord Highlighting Logic
              // if (row.type === "chords") {
              //   const chordObj = chordsTs[row.originalIndices[itemIndex]];  
              //   const chordTime = chordObj ? parseFloat(chordObj.time) : null;
              //   console.log("rowIndex:", rowIndex, "itemIndex:", itemIndex);
              //   console.log("chordObj:", chordObj);
              //   console.log("chordTime:", chordTime);



              //   // Highlight chord if it is within the time range
              //   isChordHighlighted =
              //     chordTime && item.trim() !== "" &&
              //     currentTime >= chordTime - 0.5 &&
              //     currentTime < chordTime + 0.5;
              // }

              // Word Highlighting Logic
              if (row.type === "words") {
                const wordObj = wordsTs[row.originalIndices[itemIndex]];
                const wordTime = wordObj ? parseFloat(wordObj.start) : null;

                isWordHighlighted =
                  wordTime &&
                  item.trim() !== "" &&
                  currentTime >= wordTime - 0.5 &&
                  currentTime < wordTime + 0.5;
              }

              // Combine Word and Chord Highlighting
              const isHighlighted = isWordHighlighted || isChordHighlighted;

              return (
                <Box
                  key={itemIndex} // Unique key for each word or chord
                  type={row.type}
                  width={row.widths[itemIndex]}
                  editMode={isEditing}
                  isGrouped={row.grouped}
                  ref={isHighlighted ? wordRef2 : null}
                  style={{
                    fontSize: `${fontSize}px`,
                    cursor: isEditing ? "text" : "default",
                    backgroundColor: isHighlighted ? "#F0984B" : "transparent",
                    transition: "background-color 0.2s ease",
                  }}
                  onClick={(event) => {
                    // console.log("rowIndex:", rowIndex, "itemIndex:", itemIndex);  // Logging the indices
                    handleBlur1(rowIndex, itemIndex, event);  // Calling your original function
                  }}
                >
                  {item}
                </Box>
              );
            })
          )}
        </Line>
      );
    });
  };













  const submit_all_chords_and_words = async () => {
    const encodedRequestId1 = encodeURIComponent(id);

    try {
      const response = await axios.patch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}`,
        {
          chords_ts: chordsTs,
          words_ts_newline: wordsTs,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        handleyouClick_edittext1();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      } else if (error.response && error.response.status === 400) {
        // setErrormsg(error.response.data.message); // Display the actual error message
      } else {
        // setErrormsg("An unknown error occurred"); // Handle any other errors
      }
    }
  };

  const submit_chords_and_words = async (updatedWordsTs) => {
    const encodedRequestId1 = encodeURIComponent(id);

    try {
      const response = await axios.patch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}`,
        {
          chords_ts: chordsTs,
          words_ts_newline: updatedWordsTs,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        handleyouClick_edittext1();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      } else if (error.response && error.response.status === 400) {
        // setErrormsg(error.response.data.message); // Display the actual error message
      } else {
        // setErrormsg("An unknown error occurred"); // Handle any other errors
      }
    }
  };

  const submit_chords_and_words1 = async (updatedChordsTs) => {
    const encodedRequestId1 = encodeURIComponent(id);

    try {
      const response = await axios.patch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}`,
        {
          chords_ts: updatedChordsTs,
          words_ts_newline: wordsTs,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        handleyouClick_edittext1();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      } else if (error.response && error.response.status === 400) {
        // setErrormsg(error.response.data.message); // Display the actual error message
      } else {
        // setErrormsg("An unknown error occurred"); // Handle any other errors
      }
    }
  };

  const sharp_to_flat = () => {
    const updatedRows = combinedRowsWithMaxWidths.map((row) => {
      if (row.type === "chords") {
        const updatedChords = row.row.map((chord) => {
          const rootNote = Object.keys(sharpToFlatRootMap).find((sharp) =>
            chord.startsWith(sharp)
          );
          if (rootNote) {
            return chord.replace(rootNote, sharpToFlatRootMap[rootNote]);
          }
          return chord;
        });

        return { ...row, row: updatedChords };
      }
      return row;
    });

    setCombinedRowsWithMaxWidths(updatedRows);
    setMyVariable(createMyVariable(updatedRows));
  };

  const flat_to_sharp = () => {
    const updatedRows = combinedRowsWithMaxWidths.map((row) => {
      if (row.type === "chords") {
        const updatedChords = row.row.map((chord) => {
          const rootNote = Object.keys(flatToSharpRootMap).find((flat) =>
            chord.startsWith(flat)
          );
          if (rootNote) {
            return chord.replace(rootNote, flatToSharpRootMap[rootNote]);
          }
          return chord;
        });

        return { ...row, row: updatedChords };
      }
      return row;
    });

    setCombinedRowsWithMaxWidths(updatedRows);
    setMyVariable(createMyVariable(updatedRows));
  };

  useEffect(() => {
    const combinedRows = processRows();
    const combinedRowsWithMaxWidths = [];
    let tempChordsRow = [];
    let isGroupedChords = false;

    let originalChordIndex = 0;
    let originalWordIndex = 0;

    combinedRows.forEach((row, index, array) => {
      if (row.type === "chords") {
        const nextRow = array[index + 1];
        const isNextChordsWithoutWords =
          nextRow &&
          nextRow.type === "chords" &&
          (!array[index + 2] || array[index + 2].type !== "words");

        if (!row.row.some(Boolean)) {
          combinedRowsWithMaxWidths.push({
            type: "chords",
            row: [""], // Add an empty string to signify an empty row
            widths: [0], // Optional: Set a width of 0 for the empty row
            grouped: false,
            originalIndices: [originalChordIndex],
          });
          originalChordIndex++;
          return; // Skip to the next iteration
        }

        tempChordsRow = [...tempChordsRow, ...row.row];

        if (isNextChordsWithoutWords) {
          isGroupedChords = true;
        } else {
          const combinedWidths = tempChordsRow.map((chord) =>
            calculateWidth(chord)
          );

          combinedRowsWithMaxWidths.push({
            type: "chords",
            row: tempChordsRow,
            widths: combinedWidths,
            grouped: isGroupedChords,
            originalIndices: tempChordsRow.map(() => originalChordIndex++),
          });

          tempChordsRow = [];
          isGroupedChords = false;
        }
      } else if (row.type === "words") {
        const wordWidths = row.row.map((word) => calculateWidth(word));

        const prevRow =
          combinedRowsWithMaxWidths[combinedRowsWithMaxWidths.length - 1];
        if (prevRow && prevRow.type === "chords") {
          const chordWidths = prevRow.row.map((chord) => calculateWidth(chord));
          const maxWidths = wordWidths.map((wordWidth, i) =>
            Math.max(wordWidth, chordWidths[i] || 0)
          );
          prevRow.widths = maxWidths;
          row.widths = maxWidths;
        } else {
          row.widths = wordWidths;
        }

        // row.originalIndices = row.row.map(() => originalWordIndex++);
        combinedRowsWithMaxWidths.push(row);
      }
    });

    setCombinedRowsWithMaxWidths(combinedRowsWithMaxWidths);
    setMyVariable(createMyVariable(combinedRowsWithMaxWidths));
  }, [chordsTs, wordsTs, isEditing, fontSize]);

  useEffect(() => {
    setMyVariable(createMyVariable(combinedRowsWithMaxWidths));
  }, [fontSize, combinedRowsWithMaxWidths, isEditing, currentTime]);

  useEffect(() => {
    const allChords = combinedRowsWithMaxWidths
      .filter((row) => row.type === "chords")
      .flatMap((row) => row.row);
    const uniqueChordSet = new Set(allChords);
    //  setUniqueChords(Array.from(uniqueChordSet));
  }, [combinedRowsWithMaxWidths]); // Include isEditing in dependency array

  downlines.push(myVariable);

  // const submit_chords_and_words = async () => {
  //   const encodedRequestId1 = encodeURIComponent(id);

  //   try {
  //     const response = await axios.patch(
  //       `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}`,
  //       {
  //       chords_ts: chordsTs,
  //       words_ts_newline: wordsTs
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {

  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       clearToken();
  //       navigate("/");
  //     } else if (error.response && error.response.status === 400) {
  //       // setErrormsg(error.response.data.message); // Display the actual error message
  //     } else {
  //       // setErrormsg("An unknown error occurred"); // Handle any other errors
  //     }
  //   }
  // };

  // downlines.push(myVariable);

  const downloadpdf_newpage = myVariable;

  const sharpScale = [
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B",
  ];
  const flatScale = [
    "C",
    "Db",
    "D",
    "Eb",
    "E",
    "F",
    "Gb",
    "G",
    "Ab",
    "A",
    "Bb",
    "B",
  ];

  // Function to transpose a single chord by semitones (+1 or -1)
  function transposeChord(chord, semitones) {
    // Extract root note (C, D#, Bb, etc.) and suffix (maj7, m, dim, etc.)
    const regex = /^([A-G][b#]?)(.*)$/; // Match root note (A-G with optional # or b) and suffix
    const matches = chord.match(regex);

    if (!matches) {
      return chord; // Return original chord if it doesn't match the regex
    }

    const root = matches[1]; // Root note
    const suffix = matches[2]; // Chord suffix (e.g., m7, maj, dim, etc.)

    // Determine whether to use sharp or flat scale
    let scale;
    if (sharpScale.includes(root)) {
      scale = sharpScale;
    } else if (flatScale.includes(root)) {
      scale = flatScale;
    } else {
      return chord; // If root is not found, return the original chord
    }

    // Find the root note's index in the scale and transpose it
    let index = scale.indexOf(root);
    let newIndex = (index + semitones + scale.length) % scale.length;

    // Return the transposed chord (transposed root + original suffix)
    return scale[newIndex] + suffix;
  }

  // Function to transpose all chords in the chord sequence
  function transposeChords(chords, semitones) {
    return chords.map((chordData) => ({
      time: chordData.time,
      chord: transposeChord(chordData.chord, semitones),
    }));
  }

  function transposeChord1(chord, semitones) {
    const regex = /^([A-G][b#]?)(.*)$/; // Match root note and suffix
    const matches = chord.match(regex);

    if (!matches) {
      return chord; // Return original chord if it doesn't match
    }

    const root = matches[1]; // Root note
    const suffix = matches[2]; // Chord suffix

    // Determine whether to use sharp or flat scale
    let scale;
    if (sharpScale.includes(root)) {
      scale = sharpScale;
    } else if (flatScale.includes(root)) {
      scale = flatScale;
    } else {
      return chord; // If root is not found, return original chord
    }

    // Find the root note's index and transpose
    let index = scale.indexOf(root);
    let newIndex = (index + semitones + scale.length) % scale.length;

    // Return transposed chord
    return scale[newIndex] + suffix;
  }

  // Function to transpose all chords in the array
  function transposeChordsArray1(chords, semitones) {
    return chords.map((chord) => transposeChord(chord, semitones));
  }

  const minus_one1 = async () => {
    let transposedUp = transposeChords(chordsTs, -1);
    let transup = transposeChordsArray1(uniqueChords, -1);

    setUniqueChords(transup);
    setchordsTs(transposedUp);
  };

  const plus_one1 = async () => {
    let transposedDown = transposeChords(chordsTs, 1);
    let transdown = transposeChordsArray1(uniqueChords, 1);
    setUniqueChords(transdown);
    setchordsTs(transposedDown);
  };

  const transposeOptions = [
    { label: "- 1", value: -1 },
    { label: "+ 1", value: 1 },
  ];

  // const handleClick = async (value) => {
  //   // Calculate the new transposed value
  //   const newTransposedValue = transposedValue + value;

  //   // Update the transposition value state
  //   setTransposedValue(newTransposedValue);

  //   // Update the active button state
  //   setActiveButton(value);

  //   // Call the appropriate method based on button clicked
  //   if (value === -1) {
  //     await minus_one2(newTransposedValue);
  //   } else if (value === 1) {
  //     await plus_one2(newTransposedValue);
  //   }
  // };

  const handleClick = async (value) => {
    let newTransposedValue;

    if (value === 1) {
      newTransposedValue = (transposedValue + value) % 12;
    } else if (value === -1) {
      newTransposedValue = transposedValue - 1;
      if (newTransposedValue < -11) {
        newTransposedValue = 0;
      }
    }

    setTransposedValue(newTransposedValue);

    setActiveButton(value);

    if (value === -1) {
      await minus_one2(newTransposedValue);
    } else if (value === 1) {
      await plus_one2(newTransposedValue);
    }
  };

  const minus_one2 = async (newTransposedValue) => {
    let transposedUp = transposeChords(chordsTs, -1);
    let transup = transposeChordsArray1(uniqueChords, -1);

    setUniqueChords(transup);
    setchordsTs(transposedUp);
  };

  const plus_one2 = async (newTransposedValue) => {
    let transposedDown = transposeChords(chordsTs, 1);
    let transdown = transposeChordsArray1(uniqueChords, 1);

    setUniqueChords(transdown);
    setchordsTs(transposedDown);
  };

  const [position, setPosition] = useState({
    x: window.innerWidth / 2 - 100, // Center horizontally
    y: window.innerHeight - 100, // 100px from the bottom
  });

  const handleMouseDown = (e) => {
    if (!isMobile) {
      setDragging(true);
      setOffset({
        x: e.clientX - position.x,
        y: e.clientY - position.y,
      });
    }
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    setPosition({
      x: e.clientX - offset.x,
      y: e.clientY - offset.y,
    });
  };

  const handleMouseUp = () => {
    if (!isMobile) {
      setDragging(false);
    }
  };

  // const checkContainer = () => {
  //   const container = document.getElementById("cj");
  //   if (container) {
  //     console.log("Container found:", {
  //       height: container.clientHeight,
  //       scrollHeight: container.scrollHeight,
  //       overflow: window.getComputedStyle(container).overflow,
  //     });
  //     return container;
  //   }
  //   console.log("Container not found");
  //   return null;
  // };

  useEffect(() => {
    let timer;

    // Auto-scrolling logic
    if (isActive) {
      timer = setInterval(() => {
        setElapsedTime((prev) => {
          const newTime = prev + 1;

          // Calculate and log time information
          if (currentIndex < timesArray.length) {
            const nextScrollTime = timesArray[currentIndex];
            const timeUntilNextScroll = nextScrollTime - newTime;
          }

          // Check if we need to scroll at this time
          if (
            currentIndex < timesArray.length &&
            newTime >= timesArray[currentIndex]
          ) {
            try {
              // Scroll incrementally
              const newPosition = window.pageYOffset + 70;

              // Smooth scroll
              window.scrollTo({
                top: newPosition,
                behavior: "smooth",
              });

              setScrollPosition(newPosition);
              setCurrentIndex((prev) => prev + 1);
            } catch (error) { }
          }

          return newTime;
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isActive, currentIndex, scrollPosition]);

  // Handle button click
  const handleToggle = () => {
    if (!isActive) {
      // Scroll to 'cj' div first
      const element = document.getElementById("cj1");
      if (element) {
        const rect = element.getBoundingClientRect();
        const elementTop = rect.top + window.pageYOffset;

        // Scroll to the 'cj' div smoothly
        window.scrollTo({
          top: elementTop - 100,
          behavior: "smooth",
        });

        // Start the scroll sequence after scrolling to 'cj'
        setTimeout(() => {
          setCurrentIndex(0);
          setElapsedTime(0);
          setScrollPosition(window.pageYOffset);
          setIsActive(true);
        }, 500); // 500ms delay to allow scroll to complete
      }
    } else {
      setIsActive(false);
    }
  };

  const Deleteedit_chords = ({ onClose }) => {
    const handleDeleteClick = (event) => {
      event.preventDefault();

      const { chord, time, index } = currentChord;
      if (chord) {
        handledelete_Chord(time); // Use edt_text1 for the new chord
        onClose();
      }
    };

    const ActionButton = ({ text, primary, onClick }) => {
      return (
        <Button4 primary={primary} onClick={onClick}>
          {text}
        </Button4>
      );
    };

    return (
      <FormContainer4>
        <Form>
          <HeaderContainer4>
            <Title01>Warning</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer4>

          <Content4>
            Are you sure you want to delete these Chords? This action is
            permanent and cannot be undone.
          </Content4>
          <ActionButton
            text="Delete"
            primary
            onClick={(e) => handleDeleteClick(e)}
          />

          <ActionButton text="Cancel" onClick={onClose} />

          {/* <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Create</Button01>
          </ButtonContainer01> */}
        </Form>
      </FormContainer4>
    );
  };

  const Deleteedit_words = ({ onClose }) => {
    const handleDeleteClick = (event) => {
      event.preventDefault();

      const { word, time, index } = currentword;
      if (word) {
        handledelete_Words(time, index, word); // Use edt_text1 for the new chord
        onClose();
      }
    };

    const ActionButton = ({ text, primary, onClick }) => {
      return (
        <Button4 primary={primary} onClick={onClick}>
          {text}
        </Button4>
      );
    };

    return (
      <FormContainer4>
        <Form>
          <HeaderContainer4>
            <Title01>Warning</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer4>

          <Content4>
            Are you sure you want to delete these Words? This action is
            permanent and cannot be undone.
          </Content4>
          <ActionButton
            text="Delete"
            primary
            onClick={(e) => handleDeleteClick(e)}
          />

          <ActionButton text="Cancel" onClick={onClose} />

          {/* <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Create</Button01>
          </ButtonContainer01> */}
        </Form>
      </FormContainer4>
    );
  };

  const EditTextForm = ({ onClose }) => {
    const [edt_text, setedt_text] = useState(currentChord.chord);
    const [edt_text1, setedt_text1] = useState("");

    const [errormsg, setErrormsg] = useState("");

    const handleCreateClick = (event) => {
      event.preventDefault();

      const { chord, time, index } = currentChord;

      if (chord) {
        handleChordUpdate(index, edt_text1); // Use edt_text1 for the new chord
        onClose();
      }
    };

    const handleDeleteClick = (event) => {
      event.preventDefault();

      const { chord, time, index } = currentChord;
      if (chord) {
        handledelete_Chord(time); // Use edt_text1 for the new chord
        onClose();
      }
    };

    return (
      <FormContainer>
        <Form>
          <HeaderContainer>
            <Title01>Edit Chords</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer>

          <InputContainer>
            <Label htmlFor="playlistTitle">Original Chords</Label>
            <InputWrapper>
              <Input value={edt_text} readOnly disabled />
            </InputWrapper>
          </InputContainer>

          <InputContainer>
            <Label htmlFor="playlistTitle">New Chords</Label>
            <InputWrapper>
              <Input
                id="ChordTitle"
                type="text"
                aria-label="Enter Chord title"
                placeholder="Enter New Chords"
                onChange={(e) => setedt_text1(e.target.value)}
              />
            </InputWrapper>
            {errormsg && <ErrorMessage>{errormsg}</ErrorMessage>}
          </InputContainer>

          <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Update Chords</Button01>
          </ButtonContainer01>
        </Form>
      </FormContainer>
    );
  };

  const EditWordForm = ({ onClose }) => {
    const [edt_text, setedt_text] = useState(currentword.word);
    const [edt_text1, setedt_text1] = useState("");

    const [errormsg, setErrormsg] = useState("");

    const handleCreateClick = (event) => {
      event.preventDefault();

      const { word, time, index } = currentword;
      // console.log("index   ", index);

      if (word) {
        handleLyricsUpdate(index, edt_text1); // Use edt_text1 for the new chord
        onClose();
      }
    };

    const handleDeleteClick = (event) => {
      event.preventDefault();

      const { word, time, index } = currentword;
      if (word) {
        handledelete_Words(time, index, word); // Use edt_text1 for the new chord
        onClose();
      }
    };

    return (
      <FormContainer>
        <Form>
          <HeaderContainer>
            <Title01>Edit Lyrics</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer>

          <InputContainer>
            <Label htmlFor="playlistTitle">Original Lyrics</Label>
            <InputWrapper>
              <Input value={edt_text} readOnly disabled />
            </InputWrapper>
          </InputContainer>

          <InputContainer>
            <Label htmlFor="playlistTitle">New Lyrics</Label>
            <InputWrapper>
              <Input
                id="ChordTitle"
                type="text"
                aria-label="Enter Chord title"
                placeholder="Enter New Lyrics"
                onChange={(e) => setedt_text1(e.target.value)}
              />
            </InputWrapper>
            {errormsg && <ErrorMessage>{errormsg}</ErrorMessage>}
          </InputContainer>

          <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Update Lyrics</Button01>
          </ButtonContainer01>
        </Form>
      </FormContainer>
    );
  };

  const NewAddTextForm = ({ onClose }) => {
    const [edt_text1, setedt_text1] = useState(""); // State for new lyrics input
    const [errormsg, setErrormsg] = useState(""); // State for error message
    const [edt_text2, setedt_text2] = useState("");

    const handleCreateClick = (event) => {
      event.preventDefault();

      if (!edt_text1.trim()) {
        setErrormsg("Lyrics cannot be empty.");
        return;
      }

      addlyrics(edt_text1, edt_text2);

      setErrormsg(""); // Clear error message if successful
      onClose();
    };

    const handleCreateClick2 = () => {
      setrowIndex(null);
      setitemIndex(null);
      onClose();
    };

    return (
      <FormContainer>
        <Form>
          <HeaderContainer>
            <Title01>Add New Lyrics</Title01>
            <CloseButton onClick={handleCreateClick2} aria-label="Close Form">
              ×
            </CloseButton>
          </HeaderContainer>

          <InputContainer>
            <Label htmlFor="lyricsInput">New Lyrics</Label>
            <InputWrapper>
              <Input
                id="lyricsInput"
                type="text"
                aria-label="Enter new lyrics"
                placeholder="Enter New Lyrics"
                value={edt_text1}
                onChange={(e) => setedt_text1(e.target.value)}
              />
            </InputWrapper>
            {errormsg && (
              <ErrorMessage role="alert" aria-live="polite">
                {errormsg}
              </ErrorMessage>
            )}
          </InputContainer>

          <InputContainer>
            <Label htmlFor="lyricsInput">Add Time</Label>
            <InputWrapper>
              <Input
                id="lyricsInput"
                type="text"
                aria-label="Enter new Time"
                placeholder="Enter New Time"
                value={edt_text2}
                onChange={(e) => setedt_text2(e.target.value)}
              />
            </InputWrapper>
          </InputContainer>

          <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Add New Lyrics</Button01>
          </ButtonContainer01>
        </Form>
      </FormContainer>
    );
  };

  const NewAddchordsForm = ({ onClose }) => {
    const [edt_text1, setedt_text1] = useState(""); // State for new lyrics input
    const [errormsg, setErrormsg] = useState(""); // State for error message
    const [edt_text2, setedt_text2] = useState("");

    const handleCreateClick = (event) => {
      event.preventDefault();

      if (!edt_text1.trim()) {
        setErrormsg("Chords cannot be empty.");
        return;
      }

      addChord(edt_text1, edt_text2);

      setErrormsg(""); // Clear error message if successful
      onClose();
    };

    const handleCreateClick2 = () => {
      setchord_empty_time(null);
      onClose();
    };

    return (
      <FormContainer>
        <Form>
          <HeaderContainer>
            <Title01>Add New Chords</Title01>
            <CloseButton onClick={handleCreateClick2} aria-label="Close Form">
              ×
            </CloseButton>
          </HeaderContainer>

          <InputContainer>
            <Label htmlFor="lyricsInput">New Chords</Label>
            <InputWrapper>
              <Input
                id="lyricsInput"
                type="text"
                aria-label="Enter new Chords"
                placeholder="Enter New Chords"
                value={edt_text1}
                onChange={(e) => setedt_text1(e.target.value)}
              />
            </InputWrapper>
            {errormsg && (
              <ErrorMessage role="alert" aria-live="polite">
                {errormsg}
              </ErrorMessage>
            )}
          </InputContainer>

          <InputContainer>
            <Label htmlFor="lyricsInput">Add Time</Label>
            <InputWrapper>
              <Input
                id="lyricsInput"
                type="text"
                aria-label="Enter new Time"
                placeholder="Enter New Time"
                value={edt_text2}
                onChange={(e) => setedt_text2(e.target.value)}
              />
            </InputWrapper>
          </InputContainer>

          <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Add New Chords</Button01>
          </ButtonContainer01>
        </Form>
      </FormContainer>
    );
  };

  const NewVersionForm = ({ onClose, id }) => {
    const navigate = useNavigate();
    const token = getToken();

    const [versionTitle, setversionTitle] = useState("");
    const [errormsg, setErrormsg] = useState(""); // Only using errormsg for error display

    const handleCreateClick = (event) => {
      event.preventDefault();

      if (versionTitle.trim() === "") {
        setErrormsg("Please enter a title");
      } else {
        setErrormsg(""); // Clear previous errors
        add_retry_version(versionTitle);
      }
    };

    const add_retry_version = async (title) => {
      const encodedRequestId1 = encodeURIComponent(id);

      try {
        const response = await axios.post(
          `${config.apiUrl}/url_requests/${encodedRequestId1}/retry_variant`,
          {
            name: title,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          get_retry_version(id);
          // get_variant(title);
          const ver = "Generating New Version";
          const title01 = title + " - " + ver; // Concatenate using +
          showToast5(title01);

          onClose();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        } else if (error.response && error.response.status === 400) {
          setErrormsg(error.response.data.message); // Display the actual error message
        } else {
          setErrormsg("An unknown error occurred"); // Handle any other errors
        }
      }
    };

    return (
      <FormContainer>
        <Form>
          <HeaderContainer>
            <Title01>Enter Version name</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer>

          <InputContainer>
            <Label htmlFor="playlistTitle">Title</Label>
            <InputWrapper>
              <Input
                id="VersionTitle"
                type="text"
                placeholder="Version title"
                aria-label="Enter Version title"
                value={versionTitle}
                onChange={(e) => setversionTitle(e.target.value)}
              />
            </InputWrapper>
            {errormsg && <ErrorMessage>{errormsg}</ErrorMessage>}{" "}
            {/* Show error message only if present */}
          </InputContainer>

          <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Create</Button01>
          </ButtonContainer01>
        </Form>
      </FormContainer>
    );
  };

  const SaveAsForm = ({ onClose }) => {
    const navigate = useNavigate();
    const token = getToken();

    const [versionTitle, setversionTitle] = useState("");
    const [errormsg, setErrormsg] = useState("");

    const handleCreateClick = (event) => {
      event.preventDefault();

      if (versionTitle.trim() === "") {
        setErrormsg("Please enter a title");
      } else {
        setErrormsg(""); // Clear previous errors
        add_retry_version(versionTitle);
      }
    };

    const add_retry_version = async (title) => {
      const encodedRequestId1 = encodeURIComponent(id);

      try {
        const response = await axios.post(
          `${config.apiUrl}/url_requests/${encodedRequestId1}/variants`,
          {
            name: title,
            words_ts_formatted: [],
            chords_ts_formatted: [],
            words_ts_newline: wordsTs,
            chords_ts: chordsTs,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setIsEditing(false);
          setEditable({ row: null, index: null });
          get_retry_version(id);
          // get_variant(title);
          const ver = "Generating New Version";
          const title01 = title + " - " + ver; // Concatenate using +
          showToast5(title01);

          onClose();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        } else if (error.response && error.response.status === 400) {
          setErrormsg(error.response.data.message); // Display the actual error message
        } else {
          setErrormsg("An unknown error occurred"); // Handle any other errors
        }
      }
    };

    return (
      <FormContainer>
        <Form>
          <HeaderContainer>
            <Title01>Enter Version name</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer>

          <InputContainer>
            <Label htmlFor="playlistTitle">Title</Label>
            <InputWrapper>
              <Input
                id="VersionTitle"
                type="text"
                placeholder="Version title"
                aria-label="Enter Version title"
                value={versionTitle}
                onChange={(e) => setversionTitle(e.target.value)}
              />
            </InputWrapper>
            {errormsg && <ErrorMessage>{errormsg}</ErrorMessage>}{" "}
            {/* Show error message only if present */}
          </InputContainer>

          <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Create</Button01>
          </ButtonContainer01>
        </Form>
      </FormContainer>
    );
  };

  const DeleteVersionForm = ({ onClose, id, name }) => {
    const navigate = useNavigate();
    const token = getToken();

    const [versionTitle, setversionTitle] = useState("");
    const [errormsg, setErrormsg] = useState(""); // Only using errormsg for error display

    const delete_version = async (e) => {
      e.preventDefault();
      const encodedRequestId1 = encodeURIComponent(id);
      try {
        const response = await axios.delete(
          `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${name}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          get_retry_version(id);

          onClose();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        } else if (error.response && error.response.status === 400) {
          setErrormsg(error.response.data.message); // Display the actual error message
        } else {
          setErrormsg("An unknown error occurred"); // Handle any other errors
        }
      }
    };

    const ActionButton = ({ text, primary, onClick }) => {
      return (
        <Button4 primary={primary} onClick={onClick}>
          {text}
        </Button4>
      );
    };

    return (
      <FormContainer4>
        <Form>
          <HeaderContainer4>
            <Title01>Warning</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer4>

          <Content4>
            Are you sure you want to delete this version? This action cannot be
            undone, and all data associated with this version will be
            permanently lost.
          </Content4>
          <ActionButton
            text="Delete"
            primary
            onClick={(e) => delete_version(e)}
          />

          <ActionButton text="Cancel" onClick={onClose} />

          {/* <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Create</Button01>
          </ButtonContainer01> */}
        </Form>
      </FormContainer4>
    );
  };

  const EditVersionForm = ({ onClose, id, name }) => {
    const navigate = useNavigate();
    const token = getToken();

    const [versionTitle, setVersionTitle] = useState(name); // Initialize with name
    const [errormsg, setErrormsg] = useState("");

    useEffect(() => {
      setVersionTitle(name); // Set versionTitle to the existing name when the component mounts
    }, [name]);

    const handleCreateClick = (event) => {
      event.preventDefault();

      if (versionTitle.trim() === "") {
        setErrormsg("Please enter a title");
      } else {
        setErrormsg("");
        edit_name(versionTitle);
      }
    };

    const edit_name = async (edt_name) => {
      const encodedRequestId1 = encodeURIComponent(id);
      try {
        const response = await axios.post(
          `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${name}/rename`,
          {
            name: edt_name,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          get_retry_version(id);
          onClose();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        } else if (error.response && error.response.status === 400) {
          setErrormsg(error.response.data.message);
        } else {
          setErrormsg("An unknown error occurred");
        }
      }
    };

    return (
      <FormContainer>
        <Form>
          <HeaderContainer>
            <Title01>Edit Version name</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer>

          <InputContainer>
            <Label htmlFor="versionTitle">Title</Label>
            <InputWrapper>
              <Input
                id="VersionTitle"
                type="text"
                placeholder="Version title"
                aria-label="Enter Version title"
                value={versionTitle} // Use versionTitle as the value
                onChange={(e) => setVersionTitle(e.target.value)} // Update versionTitle on change
              />
            </InputWrapper>
            {errormsg && <ErrorMessage>{errormsg}</ErrorMessage>}
          </InputContainer>

          <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Confirm</Button01>
          </ButtonContainer01>
        </Form>
      </FormContainer>
    );
  };





  //  const VideoPlayer = ({ videoUrl }) => {
  //   const iframeRef = useRef(null);
  //   const playerRef = useRef(null);
  //   const [isApiReady, setIsApiReady] = useState(false);
  //   // const [currentTime, setCurrentTime] = useState(0);
  //   const intervalRef = useRef(null);

  //   useEffect(() => {
  //     // Check if the YouTube IFrame API is already loaded
  //     if (!window.YT) {
  //       const tag = document.createElement("script");
  //       tag.src = "https://www.youtube.com/iframe_api";
  //       document.body.appendChild(tag);

  //       // Set a callback for when the API is ready
  //       window.onYouTubeIframeAPIReady = () => {
  //         setIsApiReady(true);
  //       };
  //     } else {
  //       // API is already loaded
  //       setIsApiReady(true);
  //     }
  //   }, []);

  //   useEffect(() => {
  //     if (isApiReady) {
  //       // Initialize the YouTube Player once the API is ready
  //       playerRef.current = new window.YT.Player(iframeRef.current, {
  //         videoId: extractVideoId(videoUrl),
  //         events: {
  //           onStateChange: handleStateChange,
  //         },
  //       });
  //     }
  //   }, [isApiReady, videoUrl]);



  //   const handleStateChange = (event) => {
  //     if (event.data === window.YT.PlayerState.PLAYING) {
  //       // Clear any existing interval to prevent multiple intervals
  //       if (intervalRef.current) {
  //         clearInterval(intervalRef.current);
  //       }

  //       // Start a new interval to track current time
  //       intervalRef.current = setInterval(() => {
  //         if (playerRef.current) {
  //           const time = playerRef.current.getCurrentTime();
  //           setCurrentTime(time);
  //           console.log("Current Time:", time);
  //         }
  //       }, 1000);
  //     } else if (
  //       event.data === window.YT.PlayerState.PAUSED || 
  //       event.data === window.YT.PlayerState.ENDED
  //     ) {
  //       // Clear the interval when video is paused or ended
  //       if (intervalRef.current) {
  //         clearInterval(intervalRef.current);
  //         intervalRef.current = null;
  //       }

  //       // Save the last known time when paused
  //       if (playerRef.current) {
  //         const time = playerRef.current.getCurrentTime();
  //         setCurrentTime(time);
  //         console.log("Paused at Time:", time);
  //       }
  //     }
  //   };

  //   const extractVideoId = (url) => {
  //     const regex = /(?:https?:\/\/)?(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=|embed\/|v\/)?([^&?\/\s]+)/;
  //     const match = url.match(regex);
  //     return match ? match[1] : null;
  //   };

  //   return (
  //     <div className="video-player">
  //       <style>
  //         {`
  //           .video-player {
  //             position: relative;
  //             width: 461px;
  //             height: 300px;
  //             overflow: hidden;    
  //           }
  //           .video-player iframe {
  //             position: absolute;
  //             top: 0;
  //             left: 0;
  //             width: 100%;
  //             height: 100%;
  //             border: none;
  //             margin: 0; 
  //             padding: 0;
  //           }
  //         `}
  //       </style>
  //       {/* The iframe container */}
  //       <div
  //         ref={iframeRef}
  //         id="youtube-iframe"
  //         style={{ width: "100%", height: "100%" }}
  //       ></div>
  //     </div>
  //   );
  // };

  const handleLoop = () => {
    setisLoop(!isLoop);
    if (!isLoop) {
      // Set initial loop points
      const activeRowStartTime = Math.floor(currentTime / SECONDS_PER_LINE1) * SECONDS_PER_LINE1;
      // setLoopStart(activeRowStartTime + (SECONDS_PER_LINE1 * 0.25));
      // setLoopEnd(activeRowStartTime + (SECONDS_PER_LINE1 * 1.75)); // Extends to next row
    } else {
      // Clear loop points when disabling
      setLoopStart(null);
      setLoopEnd(null);
    }
  };



  const handleLoopStart = () => {
    // Set loop start point at current time
    setLoopStart(currentTime);
    setIsSettingLoopStart(false);
    setIsSettingLoopEnd(true);
  };

  const handleLoopEnd = () => {
    // Set loop end point at current time
    // Ensure end time is after start time
    if (loopStart === null || currentTime > loopStart) {
      setLoopEnd(currentTime);
      setIsSettingLoopStart(false);
      setIsSettingLoopEnd(false);
    } else {
      alert("Loop end time must be after loop start time");
    }
  };




  // const play = () => {
  //   if (playerRef.current) {
  //     if (isPlaying) {
  //       playerRef.current.pauseVideo(); // Pause the video


  //     } else {
  //       // Scroll to 'play1' only if not already scrolled
  //       // if (!hasScrolledToPlay1) {
  //       const element = document.getElementById("cj1");
  //       if (element) {
  //         const rect = element.getBoundingClientRect();
  //         const elementTop = rect.top + window.pageYOffset;

  //         // Scroll to the 'play1' element smoothly
  //         window.scrollTo({
  //           top: elementTop - 0,
  //           behavior: "smooth",
  //         });

  //         // Mark that we have scrolled to 'play1'
  //         // setHasScrolledToPlay1(true);

  //         // Optionally delay video playback until after scroll
  //         setTimeout(() => {
  //           playerRef.current.playVideo(); // Play the video
  //         }, 500); // Delay to allow scroll to complete
  //       }
  //       // } else {
  //       playerRef.current.playVideo(); // Play the video immediately

  //       // }

  //     }
  //     setIsPlaying(!isPlaying); // Toggle play/pause state
  //   }
  // };
  const setAudioTime = () => {
    if (isLoop && loopStart && loopEnd) {
      if(mp3Player.currentTime<loopStart || mp3Player.currentTime>loopEnd){
        mp3Player.currentTime = loopStart
      }else {
        setCurrentTime(mp3Player.currentTime.toFixed(1))
      }
    } else {
      if (mp3Player.ended) {
        setCurrentTime(0)
        setIsPlaying(false)
        clearInterval(audioInterval.current)
      } else {
        setCurrentTime(mp3Player.currentTime.toFixed(1))
      }
    }
  }

  const playAudio = () => {
    if (mp3Player) {
      if (isPlaying) {
        mp3Player.pause()
        clearInterval(audioInterval.current)
      } else {
        mp3Player.play()
        audioInterval.current = setInterval(setAudioTime, 100)
      }
      setIsPlaying(!isPlaying)
    }
  }


  const play = () => {
    if (playerRef.current) {
      if (isPlaying) {
        playerRef.current.pauseVideo(); // Pause the video
      } else {
        const element = document.getElementById("cj1");
        if (element) {
          const rect = element.getBoundingClientRect();
          const elementTop = rect.top + window.pageYOffset;

          // Scroll to the 'play1' element smoothly
          window.scrollTo({
            top: elementTop - 0,
            behavior: "smooth",
          });

          // Handle loop playback
          setTimeout(() => {
            if (isLoop) {
              // If we're in loop mode
              const currentTime = playerRef.current.getCurrentTime();
              if (currentTime < loopStart || currentTime >= loopEnd) {
                // If outside loop range, start from loop start
                playerRef.current.seekTo(loopStart, true);
              }

              // Add loop checking interval
              const loopCheckInterval = setInterval(() => {
                const currentTime = playerRef.current.getCurrentTime();
                if (currentTime >= loopEnd) {
                  playerRef.current.seekTo(loopStart, true);
                }
              }, 100); // Check every 100ms

              // Store the interval ID to clear it later
              setLoopIntervalId(loopCheckInterval);
            }
            playerRef.current.playVideo();
          }, 500);
        } else {
          // Direct play without scrolling
          if (isLoop) {
            const currentTime = playerRef.current.getCurrentTime();
            if (currentTime < loopStart || currentTime >= loopEnd) {
              playerRef.current.seekTo(loopStart, true);
            }

            // Add loop checking interval
            const loopCheckInterval = setInterval(() => {
              const currentTime = playerRef.current.getCurrentTime();
              if (currentTime >= loopEnd) {
                playerRef.current.seekTo(loopStart, true);
              }
            }, 100);

            setLoopIntervalId(loopCheckInterval);
          }
          playerRef.current.playVideo();
        }
      }
      setIsPlaying(!isPlaying);

      // Clear loop interval when pausing
      if (isPlaying && loopIntervalId) {
        clearInterval(loopIntervalId);
        setLoopIntervalId(null);
      }
    }
  };

  useEffect(() => {
    // Cleanup loop interval when component unmounts or loop is disabled
    return () => {
      if (loopIntervalId) {
        clearInterval(loopIntervalId);
      }
    };
  }, [loopIntervalId]);

  // Also cleanup when loop is disabled
  useEffect(() => {
    if (!isLoop && loopIntervalId) {
      clearInterval(loopIntervalId);
      setLoopIntervalId(null);
    }
  }, [isLoop]);


  const loop1 = () => {

  };




 const restart11 = () => {
    // Call the restartVideo method exposed from VideoPlayer
    if (isUploaded && mp3Player) {
      mp3Player.currentTime = 0;
      if (!isPlaying) {
        setIsPlaying(true)
        mp3Player.play()
        audioInterval.current = setInterval(setAudioTime, 100)
      }
    } else {
      if (playerRef2.current) {
        playerRef2.current.restartVideo();
      }
    }
  };



  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        // setCurrentTime((prev) => prev + 1);
      }, 0);

      setedt2(true);
    } else {
      clearInterval(interval);
      setedt2(false);
    }
    return () => clearInterval(interval);
  }, [isPlaying]);


  useEffect(() => {
    if (wordRef.current && timelineRef.current) {
      const highlightedWord = wordRef.current;
      const container = timelineRef.current;

      const containerRect = container.getBoundingClientRect();
      const wordRect = highlightedWord.getBoundingClientRect();

      // Calculate the offset to center the highlighted word
      const offset =
        wordRect.top -
        containerRect.top -
        container.clientHeight / 2 +
        wordRect.height / 2;

      // Scroll the container smoothly to the offset
      container.scrollTo({
        top: container.scrollTop + offset,
        behavior: "smooth",
      });
    }
  }, [currentTime]);


  useEffect(() => {
    if (wordRef2.current && timelineRef2.current) {
      const highlightedElement = wordRef2.current;
      const container = timelineRef2.current;

      const containerRect = container.getBoundingClientRect();
      const elementRect = highlightedElement.getBoundingClientRect();

      // Calculate the offset to center the highlighted element
      const offset =
        elementRect.top -
        containerRect.top -
        container.clientHeight / 2 +
        elementRect.height / 2;

      // Scroll the container smoothly to the offset
      container.scrollTo({
        top: container.scrollTop + offset,
        behavior: "smooth",
      });
    }
  }, [currentTime, highlight_chords]);


  const Downgrade_play2 = ({ onClose }) => {
 

    const ActionButton = ({ text, primary, onClick }) => {
      return (
        <Button4 primary={primary} onClick={onClick}>
          {text}
        </Button4>
      );
    };
  
    return (

      <FormContainer4>
      <Form>
        <HeaderContainer4>
          <Title01>Warning</Title01>
          <CloseButton onClick={onClose}>×</CloseButton>
        </HeaderContainer4>

        <Content41>
        Are you sure you want to remove this song<br></br> from your playlist ?
        </Content41>
        <ActionButton text="Confirm" primary onClick={handleremove_song} />
        <ActionButton text="Cancel" onClick={onClose} />
      </Form>
    </FormContainer4>


     
    );
  }



  useEffect(() => {
    get_playlist_new();
  }, []);

  const get_playlist_new = async () => {
    try {

      const response = await axios.get(`${config.apiUrl}/playlists/${result1}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setplaylist_img(response.data.playlist.song_image_url);
        setplaylist_name(response.data.playlist.name);
        setplaylist_total(response.data.playlist.total_songs);

        const mappedRecords = response.data.records.map((record) => {
          const thumbnailUrl = record.thumbnail_url.split("?")[0];
          const song = {
            title: record.title,
            requestId: record.request_id,
            url: record.url,
            thumbnail_url: thumbnailUrl,
            state: record.state,
            created_at: record.created_at,
            default_variant: record.default_variant,
          };

          return song;
        });

        setSongs(mappedRecords);
        setPageLoaded(false);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };



  const get_playlist_new1 = async (selectedSongId) => {
    try {

      const response = await axios.get(`${config.apiUrl}/playlists/${result1}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {


        const mappedRecords = response.data.records.map((record) => {
          const thumbnailUrl = record.thumbnail_url.split("?")[0];
          const song = {
            title: record.title,
            requestId: record.request_id,
            url: record.url,
            thumbnail_url: thumbnailUrl,
            state: record.state,
            created_at: record.created_at,
          };

          return song;
        });
        // console.log("Mapped Records:", mappedRecords);


        show_songs2(mappedRecords, selectedSongId);

      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const truncateString = (str, maxLength) => {
    if (str?.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };


  const playPrevious = () => {
    if (currentIndexsong > 0) {
      const previousIndex = currentIndexsong - 1;
      const previousSong = songs[previousIndex];

      // Set the selected song ID and update the current index
      setSelectedSongId(previousSong.requestId);
      setCurrentIndexsong(previousIndex);

      // Set the previous song name
      if (previousIndex > 0) {
        const previousSongName = songs[previousIndex - 1].title;
        setpreviousSongName(previousSongName);

      } else {
        setpreviousSongName(null); // No previous song for the first song

      }

      // Set the next song name
      if (previousIndex < songs.length - 1) {
        const nextSongName = songs[previousIndex + 1].title;
        setnextSongName(nextSongName);

      } else {
        setnextSongName(null); // No next song for the last song

      }

      // Call the selected_songs function
      selected_songs(previousSong.requestId);
    }
  };


  // Play the next song
  const playNext = () => {

    if (currentIndexsong < songs.length - 1) {
      const nextIndex = currentIndexsong + 1;
      const nextSong = songs[nextIndex];

      // Set the selected song ID and update the current index
      setSelectedSongId(nextSong.requestId);
      setCurrentIndexsong(nextIndex);


      // Set the previous song name
      if (nextIndex > 0) {
        const previousSongName = songs[nextIndex - 1].title;
        setpreviousSongName(previousSongName);

      } else {
        setpreviousSongName(null); // No previous song for the first song

      }

      // Set the next song name
      if (nextIndex < songs.length - 1) {
        const nextSongName = songs[nextIndex + 1].title;
        setnextSongName(nextSongName);

      } else {
        setnextSongName(null); // No next song for the last song

      }

      // Call the selected_songs function
      selected_songs(nextSong.requestId);
    }
  };

  const show_songs1 = async () => {
    const firstSongId = songs[0].requestId;

    const index = songs.findIndex((song) => song.requestId === firstSongId);


    if (index !== -1) {

      const correctSongId = songs[index].requestId;
      setSelectedSongId(correctSongId); // Ensure the correct ID is being set
      setCurrentIndexsong(index); // Update the current index

      if (index > 0) {
        const previousSongName = songs[index - 1].title;
        setpreviousSongName(previousSongName);

      } else {
        setpreviousSongName(null);
      }

      if (index < songs.length - 1) {
        const nextSongName = songs[index + 1].title;
        setnextSongName(nextSongName);

      } else {
        setnextSongName(null);
      }


      selected_songs(correctSongId);
    }
  };

  const show_songs2 = async (mappedRecords, selectedSongId11) => {


    const index = mappedRecords.findIndex((song) => song.requestId === selectedSongId11);
    setSongs1(mappedRecords);
    setSongs(mappedRecords);
    setCurrentIndexsong(index);

    if (index !== -1) {

      const correctSongId = mappedRecords[index].requestId;
      setSelectedSongId(correctSongId); // Ensure the correct ID is being set

      if (index > 0) {
        const previousSongName = mappedRecords[index - 1].title;
        setpreviousSongName(previousSongName);


      } else {
        setpreviousSongName(null);
      }

      if (index < songs.length - 1) {
        const nextSongName = mappedRecords[index + 1].title;
        setnextSongName(nextSongName);

      } else {
        setnextSongName(null);
      }


      selected_songs(correctSongId);
    }
  };


  const show_songs = async (song_ids) => {

    const index = songs.findIndex((song) => song.requestId === song_ids);


    if (index !== -1) {

      const correctSongId = songs[index].requestId;
      setSelectedSongId(correctSongId); // Ensure the correct ID is being set
      setCurrentIndexsong(index); // Update the current index

      if (index > 0) {
        const previousSongName = songs[index - 1].title;
        setpreviousSongName(previousSongName);

      } else {
        setpreviousSongName(null);
      }

      if (index < songs.length - 1) {
        const nextSongName = songs[index + 1].title;
        setnextSongName(nextSongName);

      } else {
        setnextSongName(null);
      }


      selected_songs(correctSongId);
    }



  };



  const selected_songs = async (song_req_id) => {
    setCurrentTime(0);
    if(isPlaying){
      setIsPlaying(false)
      
      if(mp3Player){
        mp3Player.pause();
      }
    }


    const encodedRequestId1 = encodeURIComponent(song_req_id);
    const encodedRequestId2 = encodedRequestId1.split('%23')[1];



    setLoading(true);

    try {
      // 
      const response = await axios.get(
        `${config.apiUrl}/url_requests/youtube/${encodedRequestId2}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setyoutube_id(encodedRequestId2);
        settitle1(response.data.title);
        const thumbnailUrl = response.data.thumbnail_url.split("?")[0];
        setthumb_img(thumbnailUrl);
        setresult(response.data.result);

        setchordsTs(response.data.chords_ts);
        setwordsTs(response.data.words_ts_newline);
        seturl(response.data.url);
        setid(response.data.request_id);
        get_retry_version(response.data.request_id);
        setIsUploaded(response.data.url?.startsWith("https://jammin-upload-stage.s3.amazonaws.com"))
        setMp3Url(response.data.mp3_url)




        const allFilteredNames = new Set();

        response.data.chords.forEach((chord) => {


          allFilteredNames.add(chord); // Add every filtered name to the new Set


        });


        setUniqueChords(Array.from(allFilteredNames));


        if (url.includes("=")) {
          const parts = url.split("=");
          videoId = parts[1];
        } else {
          const parts = url.split("/");
          videoId = parts[parts.length - 1];
        }

        videoUrl = `https://www.youtube.com/embed/${encodedRequestId1}`;
        if (selectedValue === "original") {
          get_original_votes(response.data.request_id);
        }
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (songs.length > 0 && !pageLoaded) {
      const firstSongId = songs[0].requestId;
      setSelectedSongId(firstSongId); // Set the first song as selected
      show_songs(firstSongId); // Show details of the first song
      setPageLoaded(true); // Mark the page as loaded
      show_songs1();
    }
  }, [songs, pageLoaded]);



  const handleDragStart_left = (e, index) => {
    e.dataTransfer.setData("text/plain", index.toString()); // Ensure string format
    e.currentTarget.classList.add("dragging");
  };

  const handleDragOver_left = (e) => {
    e.preventDefault(); // Allow dropping
  };

  const handleDragEnter_left = (e) => {
    e.currentTarget.classList.add("drag-over");
  };

  const handleDragLeave_left = (e) => {
    e.currentTarget.classList.remove("drag-over");
  };

  const handleDrop_left = (e, dropIndex) => {
    e.preventDefault();
    e.currentTarget.classList.remove("drag-over");

    const dragIndex = parseInt(e.dataTransfer.getData("text/plain"), 10); // Convert back to number

    if (dragIndex !== dropIndex) {
      const updatedSongs = [...songs];
      const [draggedSong] = updatedSongs.splice(dragIndex, 1);
      updatedSongs.splice(dropIndex, 0, draggedSong);

      setSongs(updatedSongs);

      const reorderedRecords = updatedSongs.map((song, index) => ({
        request_id: song.requestId,
        order: 10001 + index,
      }));

      updateSongOrderAPI({ records: reorderedRecords })
        .then(() => {
          get_playlist_new1(selectedSongId);
          //  console.log(selectedSongId);
          setSelectedSongId1(selectedSongId);

          // if (selectedSongId === songs[dragIndex].requestId) {
          //  // setSelectedSongId(updatedSongs[dropIndex].requestId);
          //  // show_songs(updatedSongs[dropIndex].requestId);


          //  } else {
          //   // show_songs(selectedSongId);
          // }
        })
        .catch((err) => {
          console.error("Failed to update order:", err);
        });



    }
  };

  const handleDragEnd_left = (e) => {
    e.currentTarget.classList.remove("dragging");
  };




  const updateSongOrderAPI = async (data) => {
    try {
      const response = await axios.post(
        `${config.apiUrl}/playlists/${result1}/reorder`, // URL
        data, // Request body
        { // Configuration (headers, etc.)
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data; // Axios already parses JSON responses
    } catch (error) {
      console.error("Error updating song order:", error.response?.data || error.message);
      throw error;
    }
  };



  return (
    <AppContainer_new>
      <Pre_nav />
      <Overlay loading={loading}>
        <Spinner />
      </Overlay>
      {isMobile ? (
        <>
          <Header_new_mob>
            <Header_row_mob1>

              <Header_title_new>
                <SongTitle_new>{playlist_name}</SongTitle_new>
                <Button_row>
                  <Total_songs_new>{playlist_total} tracks</Total_songs_new>
                  <Image1_mob onClick={show_edit_popup} src={edit_playlist} />
                  <Image1_mob onClick={show_del_popup} src={delete_playlist} />
                  <Image1_mob onClick={show_left_nav} src={play_mobile} />
                </Button_row>
              </Header_title_new>

            </Header_row_mob1>
            {!show_left_nav_mob && (
              <Header_row_mob2>

                <Playlist_controls_mob>


                  {previousSongName ? (
                    <>
                      <Previous_control1_mob>
                        <Header_pre_mob onClick={playPrevious} src={restart1} />
                        <Previous_control>
                          <Pre_name_mob>Previous</Pre_name_mob>
                          <Pre_title_mob>{truncateString(previousSongName, 15)}</Pre_title_mob>
                        </Previous_control>
                      </Previous_control1_mob>
                    </>
                  ) : (
                    <>
                      <Previous_control1>
                        <Header_pre_mob onClick={playPrevious} src={restart1} style={{ visibility: "hidden" }} />
                        <Previous_control style={{ visibility: "hidden" }}>
                          {/* Empty structure to preserve space */}
                          <Pre_name_mob>Previous</Pre_name_mob>
                          <Pre_title_mob>PreviousPrevio</Pre_title_mob>
                        </Previous_control>
                      </Previous_control1>
                    </>
                  )}

                  <Now_Playing_mob>
                    <Now_name_mob>Now Playing</Now_name_mob>
                    <Now_title_mob>{truncateString(title1, 15)}</Now_title_mob>
                  </Now_Playing_mob>

                  {nextSongName ? (
                    <>
                      <Next_control1_mob>
                        <Next_control_mob>
                          <Next_name_mob>Next</Next_name_mob>
                          <Next_title_mob>{truncateString(nextSongName, 15)}</Next_title_mob>
                        </Next_control_mob>
                        <Header_next_mob onClick={playNext} src={restart1} />
                      </Next_control1_mob>
                    </>
                  ) : (
                    <>
                      <Next_control1_mob>
                        <Next_control style={{ visibility: "hidden" }}>
                          <Next_name_mob>Next</Next_name_mob>
                          <Next_title_mob>PreviousPrevio</Next_title_mob>
                        </Next_control>
                        <Header_next_mob onClick={playNext} src={restart1} style={{ visibility: "hidden" }} />
                      </Next_control1_mob>
                    </>
                  )}

                </Playlist_controls_mob>


              </Header_row_mob2>
            )}
          </Header_new_mob>


        </>
      ) : (

        <>

          <Header_new>

            {/* <Button_collapse onClick={toggleSidebar}>
          ☰
        </Button_collapse> */}

            <AlbumCover_new src={playlist_img?playlist_img:playlist_image} alt="Album cover" />
            <Header_title_new>
              <SongTitle_new>{truncateString(playlist_name, 13)}</SongTitle_new>
              <Button_row>
                <Total_songs_new>{playlist_total} tracks</Total_songs_new>
                <Image1 onClick={show_edit_popup} src={edit_playlist} />
                <Image1 onClick={show_del_popup} src={delete_playlist} />
              </Button_row>
            </Header_title_new>

            <Playlist_controls>


              {previousSongName ? (
                <>
                  <Previous_control1>
                    <Header_pre onClick={playPrevious} src={restart1} />
                    <Previous_control>
                      <Pre_name>Previous</Pre_name>
                      <Pre_title>{truncateString(previousSongName, 30)}</Pre_title>
                    </Previous_control>
                  </Previous_control1>
                </>
              ) : (
                <>
                  <Previous_control1>
                    <Header_pre onClick={playPrevious} src={restart1} style={{ visibility: "hidden" }} />
                    <Previous_control style={{ visibility: "hidden" }}>
                      {/* Empty structure to preserve space */}
                      <Pre_name>Previous</Pre_name>
                      <Pre_title>PreviousPrevio</Pre_title>
                    </Previous_control>
                  </Previous_control1>
                </>
              )}

              <Now_Playing>
                <Now_name>Now Playing</Now_name>
                <Now_title>{truncateString(title1, 30)}</Now_title>
              </Now_Playing>

              {nextSongName ? (
                <>
                  <Next_control1>
                    <Next_control>
                      <Next_name>Next</Next_name>
                      <Next_title>{truncateString(nextSongName, 30)}</Next_title>
                    </Next_control>
                    <Header_next onClick={playNext} src={restart1} />
                  </Next_control1>
                </>
              ) : (
                <>
                  <Next_control1>
                    <Next_control style={{ visibility: "hidden" }}>
                      <Next_name>Next</Next_name>
                      <Next_title>PreviousPrevio</Next_title>
                    </Next_control>
                    <Header_next onClick={playNext} src={restart1} style={{ visibility: "hidden" }} />
                  </Next_control1>
                </>
              )}

            </Playlist_controls>


          </Header_new>

          <Divider_new />
        </>

      )}


      {/* Main content container */}
      <MainContent_new>
        {!isMinimized && (
          <Three_dot onClick={toggleSidebar} src={three_dot} />
        )}
        {isMinimized && (
          <>
            <Leftsidebar_new>

              <Left_play>
                <Play_name>Playlist</Play_name>
                <Header_play onClick={toggleSidebar} src={collapse_arrow} />
              </Left_play>

              {songs.map((song, index) => (


                <TrackContainer
                  key={song.requestId}
                  draggable
                  onDragStart={(e) => handleDragStart_left(e, index)}
                  onDragOver={handleDragOver_left}
                  onDragEnter={handleDragEnter_left}
                  onDragLeave={handleDragLeave_left}
                  onDrop={(e) => handleDrop_left(e, index)}
                  onDragEnd={handleDragEnd_left}
                  isSelected={song.requestId === selectedSongId}
                  onClick={() => show_songs(song.requestId)}
                >
                  <div>
                  <TrackArtwork loading="lazy" src={song.thumbnail_url ? song.thumbnail_url : mp3_thumbnail} />

                    <TrackInfoContainer_row>
                      <TrackInfoContainer>

                        <TitleWrapper>
                          <TrackTitle>{truncateString(song.title, 25)}</TrackTitle>
                          <ControlsWrapper />
                        </TitleWrapper>


                        <TrackTitle1>
                          {truncateString(song.default_variant || "Original Version", 20)}
                        </TrackTitle1>
                      </TrackInfoContainer>
                      <Track_image src={playlist_remove} onClick={show_remove_popup} />
                    </TrackInfoContainer_row>
                  </div>
                </TrackContainer>
              ))}

            </Leftsidebar_new>


          </>
        )}

        <VerticalDivider />
        {show_left_nav_mob && (
 <>
 <Leftsidebar_new_mob>

   <Left_play>
     <Play_name>Playlist</Play_name>
    
   </Left_play>

   {songs.map((song, index) => (


     <TrackContainer
       key={song.requestId}
       draggable
       onDragStart={(e) => handleDragStart_left(e, index)}
       onDragOver={handleDragOver_left}
       onDragEnter={handleDragEnter_left}
       onDragLeave={handleDragLeave_left}
       onDrop={(e) => handleDrop_left(e, index)}
       onDragEnd={handleDragEnd_left}
       isSelected={song.requestId === selectedSongId}
       onClick={() => show_songs(song.requestId)}
     >
       <div>
       <TrackArtwork loading="lazy" src={song.thumbnail_url ? song.thumbnail_url : mp3_thumbnail} />
         <TrackInfoContainer_row>
           <TrackInfoContainer>

             <TitleWrapper>
               <TrackTitle>{truncateString(song.title, 25)}</TrackTitle>
               <ControlsWrapper />
             </TitleWrapper>


             <TrackTitle1>
               {truncateString(song.default_variant || "Original Version", 20)}
             </TrackTitle1>
           </TrackInfoContainer>
           <Track_image src={playlist_remove} onClick={show_remove_popup} />
         </TrackInfoContainer_row>
       </div>
     </TrackContainer>
   ))}

 </Leftsidebar_new_mob>


</>
         )}
        <Rightbar>
        {!show_left_nav_mob && (
          <AppContainer1>

            <Con
              style={{
                left: `${position.x}px`,
                top: `${position.y}px`,
                position: "fixed",
                cursor: dragging ? "grabbing" : "grab",
              }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              {/* Other elements inside Con */}
              <Header5>Transpose: {transposedValue}</Header5>
              <ButtonContainer>
                {transposeOptions.map((option) => (
                  <TransposeButton
                    key={option.value}
                    isActive={activeButton === option.value}
                    onClick={() => handleClick(option.value)}
                  >
                    {option.label}
                  </TransposeButton>
                ))}
              </ButtonContainer>

              {/* <Header6>Auto Tempo</Header6> */}

              <DropdownContainer>
                <Select
                  id="tempo"
                  value={
                    selectedOption === "Scroll: Manual"
                      ? "Manual"
                      : "Auto Tempo"
                  }
                  onChange={handleSelectChange}
                  className="w-full opacity-0"
                >
                  <option value="Auto Tempo">Scroll : Auto Tempo</option>
                  <option value="Manual">Scroll : Manual</option>
                </Select>
              </DropdownContainer>

              {selectedOption === "Scroll: Auto Tempo" ? (
                <Header61 onClick={handleToggle} isActive={isActive}>
                  {isActive ? "Stop" : "Start"} {/* Toggle text */}
                </Header61>
              ) : (
                <>
                  <SpeedControl>
                    <SpeedButton onClick={decreaseScrollSpeed}>
                      &lt;
                    </SpeedButton>
                    <Header7>{scrollSpeed}</Header7>
                    <SpeedButton onClick={increaseScrollSpeed}>
                      &gt;
                    </SpeedButton>
                  </SpeedControl>
                </>
              )}

            </Con>

            <SongSection>

            <AlbumCover src={thumb_img ? thumb_img : mp3_thumbnail} alt="Album cover" />
              <SongInfo>
                <SongTitle>{title1}</SongTitle>

                <SongActions>


                  <StyledDownloadButton>
                    <ButtonImage0 onClick={handleyouClick_playlist} src={playlist_add_playlist} alt="Download Free" />
                    <Tooltip>
                      Add to Playlist
                    </Tooltip>
                    <Imagearrow src={arrow} alt="Arrow" />
                  </StyledDownloadButton>




                  {subscription1 !== null ? (
                      <StyledDownloadButton>
                        <ButtonImage0 onClick={generatePDF} src={playlist_pdf} alt="Download Free" />
                        <Tooltip>
                          Download pdf
                        </Tooltip>
                        <Imagearrow src={arrow} alt="Arrow" />
                      </StyledDownloadButton>

                    ) : isTrial ? (
                      <StyledDownloadButton>
                        <ButtonImage0 onClick={generatePDF} src={playlist_pdf} alt="Download Free" />
                        <Tooltip>
                          {`Download pdf ( Free trial ends on ${endsOn} )`}
                        </Tooltip>
                        <Imagearrow src={arrow} alt="Arrow" />
                      </StyledDownloadButton>
                    ) : (
                      <StyledDownloadButton>
                        <ButtonImage0 src={playlist_pdf} alt="Download Free" />
                        <Tooltip>
                          Upgrade to premium to <br></br> download the pdf <br></br>
                        </Tooltip>
                        <Imagearrow src={arrow} alt="Arrow" />
                      </StyledDownloadButton>
                    )}

                  <StyledDownloadButton>
                    <ButtonImage0 onClick={handleCopyLink} src={playlist_youtube} alt="Download Free" />
                    <Tooltip>
                      Copy Youtube Url
                    </Tooltip>
                    <Imagearrow src={arrow} alt="Arrow" />
                  </StyledDownloadButton>


                  <StyledDownloadButton>
                    <ButtonImage0 onClick={downgrade1} src={playlist_delete} alt="Download Free" />
                    <Tooltip>
                      Delete Song
                    </Tooltip>
                    <Imagearrow src={arrow} alt="Arrow" />
                  </StyledDownloadButton>


                  <StyledDownloadButton>
                  <ButtonImage0 onClick={()=>{setRetryPopupVisible(true)}} src={playlist_retry} alt="Download Free" />
                    <Tooltip>
                      Retry Song
                    </Tooltip>
                    <Imagearrow src={arrow} alt="Arrow" />
                  </StyledDownloadButton>

                  <StyledDownloadButton>
                    <ButtonImage0 onClick={share_button} src={playlist_share} alt="Download Free" />
                    <Tooltip>
                      Share
                    </Tooltip>
                    <Imagearrow src={arrow} alt="Arrow" />
                  </StyledDownloadButton>




                </SongActions>
                <Header8>
                  Not quite right? Click 'Retry' for a fresh take on chords and
                  lyrics. It's quick and easy to get a better match!
                </Header8>
              </SongInfo>
            </SongSection>


            {PopupVisible && (
              <PopupOverlay>
                <CreatePlaylistPopup onClose={closepopup} />
              </PopupOverlay>
            )}
            {down && (
              <PopupOverlay>
                <Downgrade
                  onClose={() => setDown(false)}
                  id1={id}
                  hiddenParam={hiddenParam}
                />
              </PopupOverlay>
            )}
            {showDropdown && (
              <DropdownMenu>
                {playlists.map((playlist, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => add_playlist1(playlist.name)}
                  >
                    {playlist.name}
                  </MenuItem>
                ))}
              </DropdownMenu>
            )}

          {retryPopupVisible && (
              <PopupOverlay>
                <RetryPopup setPopupVisible={setRetryPopupVisible} handlePopupSubmit={retry_with_name} />
              </PopupOverlay>
            )}

            {showversion_title_popup && (
              <PopupOverlay>
                <NewVersionForm onClose={handleyouClick_retryversion1} id={id} />
              </PopupOverlay>
            )}

            {saveas_original && (
              <PopupOverlay>
                <SaveAsForm onClose={handle_save_as_original_popup1} />
              </PopupOverlay>
            )}

            {showedt_txt_popup && (
              <PopupOverlay>
                <EditTextForm onClose={handleyouClick_edittext1} />
              </PopupOverlay>
            )}

            {showdel_chords_popup && (
              <PopupOverlay>
                <Deleteedit_chords onClose={handleyouClick_delchords1} />
              </PopupOverlay>
            )}

            {showdel_words_popup && (
              <PopupOverlay>
                <Deleteedit_words onClose={handleyouClick_delwords1} />
              </PopupOverlay>
            )}

            {showedt_txt_popup_wrd && (
              <PopupOverlay>
                <EditWordForm onClose={handleyouClick_editword1} />
              </PopupOverlay>
            )}

            {shownew_txt_popup && (
              <PopupOverlay>
                <NewAddTextForm onClose={handleyouClick_newtext1} />
              </PopupOverlay>
            )}
            {shownew_txt_popup1 && (
              <PopupOverlay>
                <NewAddchordsForm onClose={handleyouClick_newtext3} />
              </PopupOverlay>
            )}
            {showversion_del_popup && (
              <PopupOverlay>
                <DeleteVersionForm
                  onClose={handleDelete_close}
                  id={id}
                  name={del_name}
                />
              </PopupOverlay>
            )}



            {editDropdown && (
              <PopupOverlay>
                <EditPlaylistForm onClose={hide_edit_popup} name={playlist_name} id1={result1} />
              </PopupOverlay>
            )}

            {delDropdown && (
              <PopupOverlay>
                <Downgrade_play onClose={hide_del_popup} id1={result1} nav={nav1} />
              </PopupOverlay>
            )}


{remove_song && (
  <PopupOverlay>
    <Downgrade_play2 onClose={hide_remove_popup} />
  </PopupOverlay>
)}


            {showversion_edit_popup && (
              <PopupOverlay>
                <EditVersionForm
                  onClose={handleEdit_close}
                  id={id}
                  name={edt_name}
                />
              </PopupOverlay>
            )}

            {showplaylist_popup && (
              <PopupOverlay>
                <PlaylistForm onClose={handleyouClick_playlist1} id1={id} />
              </PopupOverlay>
            )}

            {show_feedback && (
              <>
                {isMobile ? (
                  <PopupOverlay3>
                    <FeedbackPopup onClose={() => setshow_feedback(false)} />
                  </PopupOverlay3>
                ) : (
                  <PopupOverlay1>
                    <FeedbackPopup onClose={() => setshow_feedback(false)} />
                  </PopupOverlay1>
                )}
              </>
            )}

            {share_popup && (
              <PopupOverlay>
                <ShareLinkPopup
                  onClose={() => setshare_popup(false)}
                  url={share_url1}
                />
              </PopupOverlay>
            )}

            <AppContainer disableScroll={isMobile && isLandscape}>
              {/* <Container2>  */}
              <MainContent>
                <LyricsSection>
                  <Header >Chords used in this song</Header>
                  <Chordsrow>
                    <ChordList style={{ position: "relative" }}>
                      {/* Toggle expand button fixed at the top right */}
                      {uniqueChords.length > maxVisibleImages && (
                        <div
                          className="toggle-container"
                          style={{
                            position: "absolute", // Fixes the button position relative to the container
                            top: "4px", // Adjust to position from the top
                            right: "10px", // Adjust to position from the right
                            zIndex: 10, // Ensure it stays on top of other content
                          }}
                        >
                          <button
                            onClick={handleToggleExpand}
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={isExpanded ? ex_up : ex_down}
                              alt={isExpanded ? "Collapse" : "Expand"}
                              style={{
                                width: "24px",
                                height: "24px", // Adjust icon size as needed
                              }}
                            />
                          </button>
                        </div>
                      )}

                      <div
                        className="chord-container"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "left",
                          gap: "10px",
                          paddingTop: "3px", // Add padding to avoid overlap with the button
                        }}
                      >
                        {uniqueChords.map((chord, index) => {

                          const formattedName = formatChordName(chord);
                          const displayChord = formattedName.includes("/")
                            ? formattedName.split("/")[0]
                            : formattedName;



                          const processedChord = displayChord.replace(/_+/g, "#");



                          const flatName = convertSharpToFlat(processedChord);
                          const imgSrc = images[displayChord]; // Use the processed chord with sharp for image lookup
                          const isSharpChord = processedChord.includes("#");

                          const processedChord1 = for_display_ChordName(processedChord);
                          const chordDisplay = isSharpChord
                            ? `${processedChord1}/${flatName}`
                            : processedChord1;




                          // Show only the first 5 images if not expanded
                          if (!isExpanded && index >= maxVisibleImages) return null;

                          return imgSrc ? (
                            <div
                              className="chord-item"
                              key={index}
                              style={{ textAlign: "center" }}
                            >
                              <StyledImage src={imgSrc} alt={displayChord} />

                              {/* Display chord names */}
                              <p
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                  marginTop: "8px",
                                }}
                              >
                                {isSharpChord ? (
                                  <>
                                    <span
                                      onClick={() => {
                                        flat_to_sharp();
                                        setSelectedName("formattedName");
                                      }}
                                      style={{
                                        fontSize:
                                          selectedName === "formattedName"
                                            ? "16px"
                                            : "14px",
                                        fontWeight:
                                          selectedName === "formattedName"
                                            ? "bold"
                                            : "normal",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {chord}
                                    </span>
                                    /
                                    <span
                                      onClick={() => {
                                        sharp_to_flat();
                                        setSelectedName("flatName");
                                      }}
                                      style={{
                                        fontSize:
                                          selectedName === "flatName"
                                            ? "16px"
                                            : "12px",
                                        fontWeight:
                                          selectedName === "flatName"
                                            ? "bold"
                                            : "normal",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {flatName}
                                    </span>
                                  </>
                                ) : (
                                  <span style={{ fontSize: "14px" }}>{chordDisplay}</span>
                                )}
                              </p>
                            </div>
                          ) : null;
                        })}
                      </div>
                    </ChordList>

                  </Chordsrow>
                   <Rows id="cj1" ref={cj1Ref}>
                 
                                 <>
                 
                                   <Header id="lyrics_with_chords">Chords</Header>
                 
                 
                 
                 
                                   <SelectWrapper_header>
                                     <SelectButton_header onClick={() => setIsOpen(!isOpen)}>
                                       {selectedValue === "original"
                                         ? "Original Version"
                                         : (() => {
                                           let displayName = selectedValue;
                 
                                           // Check if "Copy" exists in the name
                                           if (selectedValue.includes("Copy")) {
                                             const copyMatch =
                                               selectedValue.match(/Copy (\d+)/); // Extract copy number
                                             const copyNumber = copyMatch
                                               ? `Copy ${copyMatch[1]}`
                                               : "Copy 1";
                 
                                             // Generate truncated version like "Celine..- Copy 1"
                                             const baseName = selectedValue
                                               .split("-")[0]
                                               .trim(); // Get the base part (e.g., "Celine")
                                             displayName = `${baseName.slice(
                                               0,
                                               8
                                             )}..- ${copyNumber}`;
                                           } else if (selectedValue.length > 20) {
                                             // Default truncation for other cases
                                             displayName = `${selectedValue.slice(
                                               0,
                                               20
                                             )}...`;
                                           }
                 
                                           return displayName;
                                         })()}
                                     </SelectButton_header>
                 
                                     {isOpen && (
                                       <DropdownContainer_header>
                                         <CustomOption
                                           onClick={() => handleSelectChange4("original")}
                                         >
                                           <OptionText_header>Original Version</OptionText_header>
                                         </CustomOption>
                 
                                         {variants.map((variant, index) => {
                                           let displayName = variant.name;
                 
                                           // Check if "Copy" exists in the name
                                           if (variant.name.includes("Copy")) {
                                             const copyMatch =
                                               variant.name.match(/Copy (\d+)/); // Extract copy number
                                             const copyNumber = copyMatch
                                               ? `Copy ${copyMatch[1]}`
                                               : "Copy 1";
                 
                                             // Generate truncated version like "Celine..- Copy 1"
                                             const baseName = variant.name
                                               .split("-")[0]
                                               .trim(); // Get the base part (e.g., "Celine")
                                             displayName = `${baseName.slice(
                                               0,
                                               6
                                             )}..- ${copyNumber}`;
                                           }
                 
                                           return (
                                             <CustomOption key={index}>
                                               <OptionText_header
                                                 onClick={() =>
                                                   handleSelectChange4(variant.name)
                                                 }
                                               >
                                                 {displayName}
                                               </OptionText_header>
                                               <IconsContainer>
                                                 <IconButton
                                                   onClick={(e) => {
                                                     e.stopPropagation();
                                                     handleEdit(variant.name);
                                                   }}
                                                   style={{ color: "#2684ff" }}
                                                 >
                                                   <Edit src={edt_variant} />
                                                 </IconButton>
                                                 <IconButton
                                                   onClick={(e) => {
                                                     e.stopPropagation();
                                                     handleDelete(variant.name);
                                                   }}
                                                   style={{ color: "#dc3545" }}
                                                 >
                                                   <Trash2 src={del_variant} />
                                                 </IconButton>
                                               </IconsContainer>
                                             </CustomOption>
                                           );
                                         })}
                                       </DropdownContainer_header>
                                     )}
                                   </SelectWrapper_header>
                 
                 
                 
                 
                                   <Play_control>
                 
                                     <Header1100
                                       onClick={restart11}
                                       src={restart1}
                                     />
                                     <Header110
                                       onClick={isUploaded ? playAudio : play}
                                       src={isPlaying ? video_pause_button : video_play_button}
                                     />
                 
                                     {/* {activeView === 'orgChordsView' ? (
                                         <>
                                           <Headerloop onClick={handleLoop} src={isLoop ? loop_orange : loop} />
                                           {isLoop ? (
                                             <>
                                               <StyledDownloadButton1>
                                                 <Headerloop1 src={ques_new} />
                                                 <Tooltip1>
                                                   Click on any chords or lyrics to enable the loop feature. Select both the start and end points of the loop.
                                                 </Tooltip1>
                                                 <Imagearrow1 src={arrow} alt="Arrow" />
                                               </StyledDownloadButton1>
                                             </>
                                           ) : (
                                             <>
                                               <StyledDownloadButton1>
                                                 <Headerloop1 src={ques_new} />
                 
                                               </StyledDownloadButton1>
                                             </>
                                           )}
                                         </>
                                       ) : (
                                         <>
                                           <Headerloop onClick={handleLoop} disabled={true} src={loop_disable} />
                 
                                           <StyledDownloadButton1>
                                             <Headerloop1 src={ques_new} />
                                             <Tooltip1>
                                               Want to use the Loop Feature? Please enable 'Show Timing'
                                             </Tooltip1>
                                             <Imagearrow1 src={arrow} alt="Arrow" />
                                           </StyledDownloadButton1>
                                         </>
                 
                 
                                       )} */}
                 
                 
                 
                                   </Play_control>
                 
                 
                                   {activeView === 'orgChordsView' ? (
                                     <Header_rows_row>
                                       <Header_top_row_loop onClick={handleLoop} src={isLoop ? loop_dis : loop_en} />
                 
                                       <Header_bottom_row_loop>Loop</Header_bottom_row_loop>
                                     </Header_rows_row>
                                   ) : (
                                     <Header_rows_row>
                 
                 
                                       <StyledDownloadButton1_0>
                                         <Header_top_row_loop disabled={true} src={loop_disabled} />
                                         <Header_bottom_row_disabled_loop>Loop</Header_bottom_row_disabled_loop>
                                         <Tooltip1>
                                           Want to use the Loop Feature? Please enable 'Show Timing'
                                         </Tooltip1>
                                         <Imagearrow1 src={arrow} alt="Arrow" />
                                       </StyledDownloadButton1_0>
                                     </Header_rows_row>
                                   )}
                 
                 
                                   {activeView === 'simpleView' ? (
                                     <Header_rows_row>
                                       <Header61 onClick={handleToggle} isActive={isActive}>
                                         {isActive ? "Stop" : "Start"} {/* Toggle text */}
                                       </Header61>
                                       <Header_bottom_row>Auto Scroll</Header_bottom_row>
                                     </Header_rows_row>
                                   ) : (
                                     <Header_rows_row>
                 
                                       <StyledDownloadButton1_0>
                                         <Header62 disabled={true}>
                                           {"Start"}
                                         </Header62>
                                         <Header_bottom_row_disabled>Auto Scroll</Header_bottom_row_disabled>
                                         <Tooltip1>
                                           Not available in Show Timing mode
                                         </Tooltip1>
                                         <Imagearrow1 src={arrow} alt="Arrow" />
                                       </StyledDownloadButton1_0>
                 
                 
                 
                                     </Header_rows_row>
                                   )}
                 
                                   <Header_rows_row>
                                     <Header_top_row onClick={handleViewChange} src={activeView === 'simpleView' ? show_clock : show_clock_enabled} />
                 
                                     <Header_bottom_row>Show Timing</Header_bottom_row>
                                   </Header_rows_row>
                 
                 
                                   <Header_rows_row>
                 
                                     <ButtonContainer>
                                       {transposeOptions.map((option) => (
                                         <TransposeButton
                                           key={option.value}
                                           isActive={activeButton === option.value}
                                           onClick={() => handleClick(option.value)}
                                         >
                                           {option.label}
                                         </TransposeButton>
                                       ))}
                                     </ButtonContainer>
                 
                                     <Header_bottom_row_2>Transpose: {transposedValue}</Header_bottom_row_2>
                                   </Header_rows_row>
                 
                 
                                   {activeView === 'simpleView' ? (
                                     <Header_rows_row>
                                       <Header_top_row
                                         onClick={handle_highlight_chords}
                                         src={highlight_chords ? high_light_chords_enable : high_light_chords_white}
                                       />
                                       <Header_bottom_row>Highlight Chords</Header_bottom_row>
                                     </Header_rows_row>
                                   ) : (
                                     <Header_rows_row>
                                       <StyledDownloadButton1_0>
                                         <Header_top_row src={high_light_chords} />
                                         <Header_bottom_row_disabled>Highlight Chords</Header_bottom_row_disabled>
                                         <Tooltip1>
                                           Not available in Show Timing mode
                                         </Tooltip1>
                                         <Imagearrow1 src={arrow} alt="Arrow" />
                                       </StyledDownloadButton1_0>
                 
                 
                                     </Header_rows_row>
                                   )}
                 
                 
                 
                 
                 
                                   {activeView === 'orgChordsView' ? (
                                     <>
                                       <Header_rows_row>
                 
                 
                                         <SelectWrapper1110>
                                           <SelectButton_1 onClick={toggleDropdown1}>
                                             {selectedValue === "Select Time"
                                               ? "Select Time"
                                               : SECONDS_PER_LINE1} secs
                                           </SelectButton_1>
                 
                                           {isDropdownOpen && (
                                             <DropdownContainer60>
                                               {SECONDS_list.map((seconds, index) => (
                                                 <CustomOption key={index}>
                                                   <OptionText
                                                     onClick={() => {
                                                       set_SECONDS_PER_LINE1(seconds);
                                                       setIsDropdownOpen(false); // Close dropdown after selecting
                                                     }}
                                                   >
                                                     {seconds} sec
                                                   </OptionText>
                                                 </CustomOption>
                                               ))}
                                             </DropdownContainer60>
                                           )}
                                         </SelectWrapper1110>
                                         <Header_bottom_row_1 id="lyrics_with_chords">Seconds per line</Header_bottom_row_1>
                                       </Header_rows_row>
                                     </>
                                   ) : (
                                     <>
                                       <Header_rows_row>
                 
                                         <StyledDownloadButton1_0>
                 
                                           <SelectWrapper1110>
                                             <SelectButton_10 onClick={toggleDropdown1} disabled={true}>
                                               {selectedValue === "Select Time"
                                                 ? "Select Time"
                                                 : SECONDS_PER_LINE1} secs
                                             </SelectButton_10>
                 
                 
                                           </SelectWrapper1110>
                                           <Header_bottom_row_disabled1 id="lyrics_with_chords">Seconds per line</Header_bottom_row_disabled1>
                 
                                           <Tooltip1>
                                             Only available in Show Timing mode
                                           </Tooltip1>
                                           <Imagearrow1 src={arrow} alt="Arrow" />
                                         </StyledDownloadButton1_0>
                 
                 
                 
                                       </Header_rows_row>
                                     </>
                                   )}
                 
                 
                                   <Header1
                                     onClick={() => {
                                       if (selectedValue === "original") {
                                         // edit_chords();
                                         save_changes();
                                       } else {
                                         save_changes();
                                       }
                                     }}
                                     src={selectedValue === "original" ? create_coopy : create_save_as}
                                     alt="Edit Chords"
                                   />
                 
                 
                 
                                 </>
                 
                               </Rows>


                  <Linees>

                    <div>
                      {selectedValue === "original" ? (
                        <Retry2>
                          This is the original version, and it cannot be edited.
                        </Retry2>
                      ) : (
                        <Retry2>
                          This song is an edited version.
                        </Retry2>
                      )}
                    </div>


                    <>
                      <ThumbUpIcon
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={toggleThumbColor}
                      >
                        <circle
                          cx="14"
                          cy="14"
                          r="14"
                          fill={isClicked ? "#20C997" : "white"}
                        />
                        <path
                          d="M20.3381 11.4479C20.6849 11.4479 20.9913 11.5809 21.2572 11.8469C21.5232 12.1129 21.6562 12.4192 21.6562 12.7661V13.9438C21.6562 14.0196 21.6483 14.1013 21.6323 14.1892C21.6164 14.2771 21.5926 14.3589 21.5609 14.4348L19.4714 19.365C19.3668 19.5987 19.1916 19.796 18.9457 19.9567C18.6999 20.1175 18.4442 20.1979 18.1786 20.1979H12.2751C11.9115 20.1979 11.6009 20.0692 11.3434 19.8116C11.0859 19.554 10.9571 19.2434 10.9571 18.8798V11.9933C10.9571 11.8176 10.9931 11.6487 11.0651 11.4866C11.137 11.3243 11.2328 11.1833 11.3525 11.0636L15.21 7.23132C15.355 7.094 15.5228 7.00941 15.7135 6.97757C15.9042 6.94585 16.0869 6.97435 16.2617 7.06307C16.4365 7.1519 16.5627 7.2823 16.6403 7.45426C16.7179 7.62635 16.7334 7.80913 16.6866 8.0026L15.9013 11.4479H20.3381ZM8.39103 20.1979C8.02742 20.1979 7.71679 20.0692 7.45915 19.8116C7.20163 19.554 7.07288 19.2434 7.07288 18.8798V12.7661C7.07288 12.4025 7.20163 12.0918 7.45915 11.8342C7.71679 11.5767 8.02742 11.4479 8.39103 11.4479H8.54525C8.90886 11.4479 9.21948 11.5767 9.47712 11.8342C9.73464 12.0918 9.8634 12.4025 9.8634 12.7661V18.8869C9.8634 19.2505 9.73464 19.5599 9.47712 19.8151C9.21948 20.0703 8.90886 20.1979 8.54525 20.1979H8.39103Z"
                          fill={isClicked ? "white" : "#7F8384"}
                        />
                      </ThumbUpIcon>
                      <Thumbup_num isClicked={isClicked}>{upvotes}</Thumbup_num>

                      <ThumbdownIcon
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={toggleThumbColor1}
                      >
                        <circle
                          cx="14"
                          cy="14"
                          r="14"
                          fill={isClicked1 ? "#e31021" : "white"}
                        />
                        <path
                          d="M7.66194 16.5521C7.3151 16.5521 7.00873 16.4191 6.74283 16.1531C6.4768 15.8871 6.34379 15.5808 6.34379 15.2339V14.0562C6.34379 13.9804 6.35175 13.8987 6.36767 13.8108C6.38359 13.7229 6.40741 13.6411 6.43913 13.5652L8.52855 8.63498C8.63319 8.40128 8.80843 8.20404 9.05429 8.04326C9.30014 7.88248 9.55583 7.80209 9.82137 7.80209H15.7249C16.0885 7.80209 16.3991 7.93085 16.6566 8.18836C16.9141 8.446 17.0429 8.75663 17.0429 9.12024V16.0067C17.0429 16.1824 17.0069 16.3513 16.9349 16.5134C16.863 16.6757 16.7672 16.8167 16.6475 16.9364L12.79 20.7687C12.645 20.906 12.4772 20.9906 12.2865 21.0224C12.0958 21.0541 11.9131 21.0256 11.7383 20.9369C11.5635 20.8481 11.4373 20.7177 11.3597 20.5457C11.2821 20.3737 11.2666 20.1909 11.3134 19.9974L12.0987 16.5521H7.66194ZM19.609 7.80209C19.9726 7.80209 20.2832 7.93085 20.5408 8.18836C20.7984 8.446 20.9271 8.75663 20.9271 9.12024V15.2339C20.9271 15.5975 20.7984 15.9082 20.5408 16.1658C20.2832 16.4233 19.9726 16.5521 19.609 16.5521H19.4548C19.0911 16.5521 18.7805 16.4233 18.5229 16.1658C18.2654 15.9082 18.1366 15.5975 18.1366 15.2339V9.11313C18.1366 8.74952 18.2654 8.44011 18.5229 8.1849C18.7805 7.92969 19.0911 7.80209 19.4548 7.80209H19.609Z"
                          fill={isClicked1 ? "white" : "#7F8384"}
                        />
                      </ThumbdownIcon>
                      <Thumbdown_num isClicked1={isClicked1}>
                        {downvotes}
                      </Thumbdown_num>
                    </>



                  </Linees>


                  <div id="cj">
                    {loading1 ? (
                      <CJContainer>
                        <Overlay1 loading={loading1}>
                          <Spinner />
                          {/* <LoadingText>Loading... Please wait</LoadingText> */}
                        </Overlay1>
                      </CJContainer>
                    ) : (
                      <div id="cj2">
                        {activeView === 'orgChordsView' ? (
                          <div className="view orgChordsView">
                            <Container100
                              isPortrait={isMobile && isPortrait}
                              isLandscape={isMobile && isLandscape}
                              className="Container100"
                              ref={timelineRef}
                            >
                              <div

                                className="w-full border border-gray-700 rounded-lg overflow-hidden bg-black"
                              >
                                {Array.from(
                                  { length: Math.ceil(totalDuration / SECONDS_PER_LINE1) },
                                  (_, i) =>
                                    renderTimeSegment(
                                      i * SECONDS_PER_LINE1,
                                      (i + 1) * SECONDS_PER_LINE1,
                                      i
                                    )
                                )}
                              </div>
                            </Container100>
                          </div>
                        ) : activeView === 'simpleView' ? (
                          <ViewContainer>
                            <div className="view simpleView">
                              <Container
                                ref={timelineRef2}
                                isPortrait={isMobile && isPortrait}
                                isLandscape={isMobile && isLandscape}
                              >
                                {myVariable} {/* Render your myVariable content here */}
                              </Container>
                            </div>
                          </ViewContainer>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    )}
                  </div>

                </LyricsSection>
              </MainContent>



            </AppContainer>
          </AppContainer1>
        )}
          {/* 
          <Ad>
            <VideoPlayer videoUrl={videoUrl} onTimeUpdate={setCurrentTime} playerRef={playerRef} isPlaying={setIsPlaying} playerRef2={playerRef2} />
          </Ad> */}


        </Rightbar>
        <VerticalDivider />


        {!isMinimized1 && (
          <Three_dot onClick={toggleSidebar1} src={youtube_logo} />
        )}

        <>
          <Sidebar isMinimized={isMinimized1  || isMobile} >


            <Right_play>
              <Header_right_play onClick={toggleSidebar1} src={collapse_arrow} />
            </Right_play>

            {!isUploaded && <Ad>
              <VideoPlayer videoUrl={videoUrl} onTimeUpdate={setCurrentTime} playerRef={playerRef} isPlaying={setIsPlaying} playerRef2={playerRef2} onPlayerEnd={playNext}  />
            </Ad>}
            <Header3>Recently Played songs</Header3>
            <RecentlyPlayedList>
              {playlists.map(
                (song, index) =>
                  song.title !== title1 && (
                    <div
                      key={index}
                      onClick={() => {
                        setLoading(true);
                        handleSongCardClick(song.id, song.url);
                      }}
                    >
                      <RecentlyPlayedItem>
                      <SongThumbnail src={song.image ? song.image : mp3_thumbnail} alt={song.title} />

                        <SongDetails>
                          <SongName>{truncateString(song.title, 40)}</SongName>
                        </SongDetails>
                      </RecentlyPlayedItem>
                    </div>
                  )
              )}
              <ViewAllLink onClick={View_all_Songs}>View all</ViewAllLink>
            </RecentlyPlayedList>
          </Sidebar>


        </>




      </MainContent_new>
      <ToastContainer />
      <Footer />
    </AppContainer_new>
  );

}


function Downgrade({ onClose, id1, hiddenParam }) {
  const token = getToken();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const delete_song = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${config.apiUrl}/playlists/__default_playlist__/remove`,
        { records: [id1] },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        if (hiddenParam == "all_songs") {
          navigate("/all_songs");
        } else if (hiddenParam == "user") {
          navigate("/user");
        } else {
          navigate("/all_songs");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  const ActionButton = ({ text, primary, onClick }) => {
    return (
      <Button primary={primary} onClick={onClick}>
        {text}
      </Button>
    );
  };

  return (

    <FormContainer4>
    <Form>
      <HeaderContainer4>
        <Title01>Warning</Title01>
        <CloseButton onClick={onClose}>×</CloseButton>
      </HeaderContainer4>

      <Content41>
      Are you sure you want to delete this song?
      </Content41>
      <ActionButton text="Confirm" primary onClick={delete_song} />
      <ActionButton text="Cancel" onClick={onClose} />
    </Form>
  </FormContainer4>

   
  );
}


function Downgrade_play({ onClose, id1, nav }) {
  const token = getToken();

  const [loading, setLoading] = useState(false);




  const navigate = useNavigate();

  const delete_playlist = async () => {
    setLoading(true);
    const encodedRequestId1 = encodeURIComponent(id1);

    try {
      const response = await axios.delete(
        `${config.apiUrl}/playlists/${encodedRequestId1}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        if (nav === "user") {
          navigate("/user");
        } else if (nav === "all_playlist") {
          navigate("/playlist");
        }

      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  const ActionButton = ({ text, primary, onClick }) => {
    return (
      <Button4 primary={primary} onClick={onClick}>
        {text}
      </Button4>
    );
  };

  return (

    <FormContainer4>
    <Form>
      <HeaderContainer4>
        <Title01>Warning</Title01>
        <CloseButton onClick={onClose}>×</CloseButton>
      </HeaderContainer4>

      <Content41>
      Are you sure you want to delete this playlist?<br></br> This action cannot be undone.
      </Content41>
      <ActionButton text="Confirm" primary onClick={delete_playlist} />
      <ActionButton text="Cancel" onClick={onClose} />
    </Form>
  </FormContainer4>

   
  );
}






const MainContent_new = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height:450px;
`;


const VerticalDivider = styled.div`
  width: 1px;
  background-color: #fff; /* Adjust divider color */
  height: auto; /* Adjust height dynamically */
  margin: 0px; /* Space between the divider and sidebars */
   @media (max-width: 991px) {
   display:none;
  }
`;



const Divider_new = styled.div`
  width: 100%;
  height: 1px;
  background-color: white;
  margin-top: 10px; /* Space between the header and the divider */
`;


const Button_collapse = styled.div`
  display: flex;
  align-self: flex-start; /* Align button to the start of the container */
  margin-left: 10px; /* Adjust spacing from the left edge */
  cursor: pointer;
  font-size: 20px;
  justify-content: center;
  margin-top:30px;
  color:white;
`;



const Rightbar = styled.div`
 flex:1;
  padding: 0px;
  background-color: #222; /* Optional: Different background for contrast */
  display: flex;
  flex-direction: column; /* Ensure content stacks vertically */
  gap: 0px; /* Space between items */
  overflow-y: auto; /* Allow scrolling if content overflows */
  width:80%;
`;

const AlbumCover_new = styled.img`
  max-width: 137px;
  height: 80px;
  margin-left: 10px;
  margin-top: 10px;
  @media (max-width: 991px) {
    width: 150px;
    height: 100px;
  }
`;

const SongTitle_new = styled.div`
  color: #fff;
  font: 700 20px Poppins, sans-serif;
  margin-left: 10px;
  margin-top:20px;
`;

const Total_songs_new = styled.div`
  color: #fff;
  font: 400 15px/140% Poppins, sans-serif;
  margin-left: 10px;
  margin-top: 3px; /* Ensure proper spacing */
`;

const Button_row = styled.div`
 display:flex;
 flex-direction:row;
`;

const Pre_name = styled.div`
  color: #fff;
  font: 700 14px Poppins, sans-serif;
 
`;

const Pre_name_mob = styled.div`
  color: #fff;
  font: 600 10px Poppins, sans-serif;
 
`;

const Pre_title_mob = styled.div`
  color: #fff;
  font: 600 10px Poppins, sans-serif;
   white-space: nowrap;          /* Prevents text from wrapping */
  overflow: hidden;            /* Hides text that overflows */
  text-overflow: ellipsis;
 
`;


const Pre_title = styled.div`
  color: #fff;
  font: 600 16px Poppins, sans-serif;
 
`;


const Now_name = styled.div`
  color: #F0984B;
  font: 700 16px Poppins, sans-serif;
 
`;

const Now_name_mob = styled.div`
  color: #F0984B;
  font: 700 12px Poppins, sans-serif;
 
`;

const Now_title_mob = styled.div`
  color: #fff;
  font: 600 12px Poppins, sans-serif;
  white-space: nowrap;          /* Prevents text from wrapping */
  overflow: hidden;            /* Hides text that overflows */
  text-overflow: ellipsis;
`;


const Now_title = styled.div`
  color: #fff;
  font: 600 20px Poppins, sans-serif;
 
`;


const Next_name = styled.div`
  color: #fff;
  font: 700 14px Poppins, sans-serif;
  text-align: right; /* Aligns the text content to the right */
`;

const Next_name_mob = styled.div`
  color: #fff;
  font: 600 10px Poppins, sans-serif;
  text-align: right; /* Aligns the text content to the right */
`;

const Next_title_mob = styled.div`
  color: #fff;
  font: 600 10px Poppins, sans-serif;
  white-space: nowrap;          /* Prevents text from wrapping */
  overflow: hidden;            /* Hides text that overflows */
  text-overflow: ellipsis;     /* Adds ellipsis when text overflows */
`;



const Next_title = styled.div`
  color: #fff;
  font: 600 16px Poppins, sans-serif;
 
`;

const Header_new = styled.div`
  display: flex;
  flex-direction: row;
  height: 90px;
  align-items: center;
`;

const Header_new_mob = styled.div`
  display: flex;
  flex-direction: column;
 
  align-items: flex-start;
`;

const Header_title_new = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;


const Header_row_mob1 = styled.div`
  display: flex;
  flex-direction: row;
 
`;

const Header_row_mob2 = styled.div`
  display: flex;
  flex-direction: row;
 margin-top:10px;
 margin-bottom:10px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: ${props => (props.loading ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const AppContainer_new = styled.div`
  display: flex;
  background-color: #282828;
  flex-direction: column;
`;

const TrackInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  gap: 5px;
`;

const TrackInfoContainer_row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Space out the TrackInfoContainer and Track_image */
  align-items: center; /* Ensure vertical alignment of all elements */
  width: 100%; /* Ensure it spans the container's width */
`;



const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TrackTitle = styled.div`
  
  margin: auto 0;
  font: 700 14px Poppins, sans-serif;
  color:white;
`;

const TrackTitle1 = styled.div`
  
  margin_top:10px;
  font: 500 12px Poppins, sans-serif;
  color:white;
`;

const Play_name = styled.div`
  
  margin: auto 0;
  font: 700 20px Poppins, sans-serif;
  color:white;
  @media (max-width: 991px) {
     font: 700 18px Poppins, sans-serif;
  }
`;


const ControlsWrapper = styled.div`
  display: flex;
`;

const Leftsidebar_new = styled.div`
  display: inline-block; 
  min-height: 350px;
  max-height:1150px; 
  overflow-y: auto; 
  position: relative;
  &::-webkit-scrollbar {
    display: none; 
  }
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  width: 340px;
  @media (max-width: 991px) {
    visibility: hidden; 
    position: absolute; 
    width: 0; 
    height: 0; 
    overflow: hidden;
  }
 `;

 const Leftsidebar_new_mob = styled.div`
  display: inline-block; 
  min-height: 350px;
  max-height:1150px; 
  overflow-y: auto; 
  position: relative;
  &::-webkit-scrollbar {
    display: none; 
  }
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  width: 100%;

 `;

const TrackContainer = styled.div`
  display: flex;
  gap: 12px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  line-height: 1.4;
  padding: 10px; /* To show a little spacing around the inner wrapper */
  align-items: center;
  cursor: pointer;

  /* Inner wrapper styling */
  > div {
    flex: 1;
    padding: 7px;
    background-color: ${(props) => (props.isSelected ? "#f0984b" : "transparent")};
   
    display: flex; /* Ensure layout stays consistent */
    gap: 12px;
  }
`;



const TrackArtwork = styled.img`
  aspect-ratio: 1.23;
  object-fit: contain;
  width: 64px;
  border-radius: 2px;
  margin: auto 0;
`;


const Track_image = styled.img`
  width: 25px;
  margin-left: auto; /* Push the image to the far right */
   align-self: center;
`;



const ErrorMessage = styled.p`
  color: red;
  margin-top: 5px;
  font-size: 12px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 35px;
  right: 30px;
  background: none;
  border: none;
  color: #fff;
  font-size: 34px;
  cursor: pointer;
`;

const ButtonContainer01 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* This centers the button vertically if needed */

  @media (max-width: 991px) {
    width: 90%; /* Ensures the container spans the full width on mobile */
    margin: 0 auto; /* Centers the container horizontally */
  }
`;

const InputContainer = styled.section`
  align-self: center;
  display: flex;
  margin-top: 32px;
  width: 100%;
  max-width: 396px;
  flex-direction: column;
  @media (max-width: 991px) {
    width: 90%;
  }
`;

const Label = styled.label`
  color: #fff;
  font: 600 16px Inter, sans-serif;
`;

const InputWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  min-height: 56px;
`;

const Input = styled.input`
  flex: 1;
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 17px 16px;
  border: 1px solid #ececec;
  color: #414141;
  font: 400 13px/32px Poppins, sans-serif;
`;

const Button01 = styled.button`
  align-self: stretch;
  border-radius: 4px;
  background-color: #0072d5;
  margin-top: 55px;
  min-height: 60px;
  margin-bottom: 40px;
  width: 400px;
  color: #fff;
  text-align: center;
  padding: 0 30px;
  font: 600 16px Poppins, sans-serif;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #005bb0;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 114, 213, 0.3);
  }
`;

const Button02 = styled.button`
  align-self: stretch;
  border-radius: 4px;
  background-color: #0072d5;
  margin-top: 10px;
  min-height: 60px;
  margin-bottom: 40px;
  width: 400px;
  color: #fff;
  text-align: center;
  padding: 0 30px;
  font: 600 16px Poppins, sans-serif;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #005bb0;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 114, 213, 0.3);
  }
`;

const HeaderContainer = styled.header`
  position: relative;
  display: flex;
  min-height: 68px;
  align-items: center;
  justify-content: center;
  padding: 21px 16px;
  color: #fff;
  text-align: center;
  letter-spacing: -0.2px;
  font: 600 20px/1.3 Poppins, sans-serif;
`;

const HeaderContainer17 = styled.header`
  position: relative;
  display: flex;
  min-height: 18px;
  align-items: center;
  justify-content: center;
  padding: 21px 16px;
  color: #fff;
  text-align: center;
  letter-spacing: -0.2px;
  font: 600 20px/1.3 Poppins, sans-serif;
`;

const HeaderContainer4 = styled.header`
  position: relative;
  display: flex;
  min-height: 68px;
  align-items: center;
  justify-content: center;
  padding: 21px 16px;
  color: #fff;
  text-align: center;
  letter-spacing: -0.2px;
  font: 600 20px/1.3 Poppins, sans-serif;
`;

const Title01 = styled.div`
  z-index: 0;
  margin: 0;
  font: 600 25px/1.3 Poppins, sans-serif;
  @media (max-width: 991px) {
    font: 600 23px/1.3 Poppins, sans-serif;
  }
`;

const CloseIcon01 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
`;

const FormContainer = styled.main`
  border-radius: 12px;

  display: flex;
  width: 428px;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 991px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const FormContainer4 = styled.main`
  border-radius: 12px;

  display: flex;
  width: 428px;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 991px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const Form = styled.form`
  border-radius: 12px;
  background-color: #312f2f;
  display: flex;
  width: 100%;
  flex-direction: column;
`;



const Modal = styled.section`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  max-width: 428px;
  height: 300px;
  padding: 0 0 0px;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  @media (max-width: 991px) {
    width: 95%;
  }
`;

const Content1 = styled.p`
  color: #3e3635;
  letter-spacing: 0.1px;
  margin-top: 37px;
  font: 500 18px/21px Poppins, sans-serif;
`;

const Content4 = styled.p`
  color: white;
  letter-spacing: 0.1px;
  margin-top: 0px;
  font: 500 14px Poppins, sans-serif;
  line-height: 22.4px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
`;

const Content41 = styled.div`
  color: white;
  letter-spacing: 0.1px;
  margin-top: 0px;
  margin-bottom: 15px;
  font: 600 16px Poppins, sans-serif;
  line-height: 22.4px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
`;
const HeaderContainer1 = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 428px;
  background-color: #0072d5;
  max-width: 100%;
  font-size: 24px;
  white-space: nowrap;
  letter-spacing: -0.24px;
  line-height: 130%;
  height: 70px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
`;

const WarningText = styled.h2`
  margin: 0 auto;
  font: 600 24px Poppins, sans-serif;
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
`;
const Button = styled.button`
  font-family: Poppins, sans-serif;
  border-radius: 4px;
  background-color: ${(props) => (props.primary ? " #0072d5" : "transparent")};
  align-self: center;
  margin-top: ${(props) => (props.primary ? "15px" : "5px")};
  width: 100%;
  max-width: 296px;
  color: ${(props) => (props.primary ? "#fff" : "#717171")};
  white-space: nowrap;
  padding: 17px 30px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
`;

const Button4 = styled.button`
  font-family: Poppins, sans-serif;
  border-radius: 4px;
  background-color: ${(props) => (props.primary ? "#0072D5" : "transparent")};
  align-self: center;
  margin-top: ${(props) => (props.primary ? "15px" : "5px")};
  width: 100%;
  max-width: 296px;
  color: ${(props) => (props.primary ? "#fff" : "#fff")};
  white-space: nowrap;
  padding: 17px 30px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const HeaderWrapper = styled.header`
  display: flex;
  width: 428px;
  background-color: #428bca;
  max-width: 100%;
  gap: 0px;
  font-size: 24px;
  white-space: nowrap;
  letter-spacing: -0.24px;
  line-height: 130%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

const Title = styled.h2`
  font-family: Poppins, sans-serif;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: auto 0;
  margin-left: 10px;
  margin-right: 10px;
`;

const TransposeButton = styled.button`
  display: inline-flex; /* Ensures proper alignment of text */
  justify-content: center;
  align-items: center; /* Centers the content */
  padding: 8px 10px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? "#F0984B" : "#242323")};
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  line-height: 1; /* Prevents any spacing issues */
  transition: background-color 0.3s;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  @media (max-width: 991px) {
    font-size: 10px;
  }
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;
  margin-left: 5px;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 18px; /* Reduced size to fit inside */
    width: 18px; /* Reduced size to fit inside */
    left: 0px; /* Adjusted to sit inside the slider */
    bottom: 0px;

    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const CloseIcon1 = styled.img`
  object-position: center;
  margin-top: 20px;
  align-self: flex-start;
  cursor: pointer;
  margin-right: 5px;
`;

const Message = styled.p`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  letter-spacing: -0.18px;
  color: #3e3635;
  margin-top: 65px;
`;

const ModalWrapper = styled.section`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  max-width: 428px;
  flex-direction: column;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  padding: 23px 25px;
`;

const ViewAllLink = styled.a`
  color: #428bca;
  margin: auto 0;
  font: 16px Poppins, sans-serif;
  margin-right: 35px;
  margin-top: 8px;
  cursor: pointer;
  @media (max-width: 991px) {
    margin-right: 0px;
    flex-direction: row;
  }
`;
const AppContainer = styled.div`
  
  display: flex;
  padding-bottom: 10px;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  overflow-x: auto; /* Enable horizontal scroll */
  overflow-y: hidden; /* Disable vertical scroll */
  white-space: nowrap; /* Prevent shrinking or wrapping */
  width: 100%; /* Set width to 100% */

  /* Add this media query for mobile responsiveness */
  @media (max-width: 991px) {
    justify-content: flex-start;
  }

   ${({ disableScroll }) =>
    disableScroll &&
    css`
      overflow-x: hidden; 
    `}
`;

const AppContainer1 = styled.div`
  flex-direction: column;

  display: flex;
  margin-left:10px;
  gap: 0px;
  align-items: flex-start;
  overflow: hidden;
`;

const MainContent = styled.main`
  display: flex;
  padding: 0 0px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
  }
`;

const Header = styled.div`
  color: #fff;
   font: 700 20px Poppins, sans-serif;
  @media (max-width: 991px) {
    margin-left: 0px;
    font: 700 18px Poppins, sans-serif;
  }
`;

const Header_mob = styled.h3`
  color: #fff;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const Header30 = styled.h4`
  color: #fff;
  margin-left: 10px;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const Header1 = styled.img`
  cursor: pointer;
  margin-left: 5px;
  height:41px;
  margin-top:2px;
  
`;

const Thumb_img = styled.img`
  cursor: pointer;
  margin-left: 10px;
`;

const Thumbup_num = styled.div`
  color: ${({ isClicked }) => (isClicked ? "#20C997" : "#fff")};

  font: 550 15px Poppins, sans-serif;

  align-items: center;
  margin-left: 10px;
  margin-top: 7px;
  @media (max-width: 991px) {
    font-size: 15px;
    margin-top: 10px;
    margin-left: 5px;
  }
`;

const Thumbdown_num = styled.div`
  color: ${({ isClicked1 }) => (isClicked1 ? "#e31021" : "#fff")};
  font: 550 15px Poppins, sans-serif;

  align-items: center;
  margin-left: 10px;
   margin-top: 7px;
  @media (max-width: 991px) {
    font-size: 15px;
    margin-top: 10px;
    margin-left: 5px;
  }
`;

const Linees = styled.div`
 display:flex;
 flex-direction:row;
 margin-top:10px;
`;


const Checkbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
   @media (max-width: 991px) {
   
    margin-top: 8px;
    margin-left: 5px;
  }
`;

const Check_box_text = styled.div`
  color: #fff;
  font: 550 15px Poppins, sans-serif;
  margin-left: 10px;
  align-items: center;

  @media (max-width: 991px) {
    font-size: 12px;
    margin-top: 0; /* Adjusted to keep alignment consistent */
    margin-left: 5px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;

    input[type="checkbox"] {
      width: 18px;
      height: 18px;
      cursor: pointer;
      margin-right: 8px;
    }
  }
`;



const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ToggleButton = styled.button`
  padding: 10px 20px;
  background-color: ${(props) => (props.active ? '#4CAF50' : '#f1f1f1')};
  color: ${(props) => (props.active ? 'white' : 'inherit')};
  border: none;
  cursor: pointer;
`;

const ViewContainer = styled.div`
  .view {
    display: block;
  }

  .view.hidden {
    display: none;
  }
`;

const Header10 = styled.img`
  cursor: pointer;
  width: 120px;
`;
const Header11 = styled.img`
  cursor: pointer;
  width: 140px;
`;

const Header110 = styled.img`
  cursor: pointer;
  margin-left:5px;
  margin-right:8px;
  height:27px;
  width:27px;

`;

const Headerloop = styled.img`
  cursor: pointer;
  
  height:30px;
  width:30px;
  margin-right:7px;
   margin-top:2px;

`;




const LoopStartButton = styled.img`
  cursor: pointer;
  margin-left:8px;
  

`;

const LoopEndButton = styled.img`
  cursor: pointer;
  margin-left:8px;
  

`;

const Header1100 = styled.img`
  cursor: pointer;
  margin-left:5px;
  height:30px;
  width:30px;
  margin-top:2px;


`;

const Header_pre = styled.img`
  cursor: pointer;
  margin-left:5px;
  height:30px;
  width:30px;
  margin-top:2px;


`;

const Header_pre_mob = styled.img`
  cursor: pointer;
  margin-left:5px;
  height:20px;
  width:20px;
  margin-top:2px;


`;

const Header_next = styled.img`
  cursor: pointer;
  margin-left:5px;
  height:30px;
  width:30px;
  margin-top:2px;
 transform: rotate(180deg);

`;

const Header_next_mob = styled.img`
  cursor: pointer;
  margin-left:5px;
  height:20px;
  width:20px;
  margin-top:2px;
 transform: rotate(180deg);

`;

const Header_play = styled.img`
  cursor: pointer;
  
  height:30px;
  width:30px;
  margin-top:2px;


`;

const Header_right_play = styled.img`
  cursor: pointer;
  
  height:30px;
  width:30px;
  margin-top:2px;
   transform: rotate(180deg);


`;


const Three_dot = styled.img`
  cursor: pointer;
  margin-left:10px;
  height:30px;
  width:30px;
  margin-top:10px;
  margin-right:10px;


`;

const Playlist_controls = styled.div`
  display: flex;
  justify-content: space-between; /* Distributes items evenly across the container */
  align-items: center; /* Vertically centers items */
  margin-left: 80px;
  width: 75%;
`;

const Playlist_controls_mob = styled.div`
  display: flex;
  justify-content: space-between; /* Distributes items evenly across the container */
  align-items: center; /* Vertically centers items */
  

`;
const Previous_control = styled.div`
  display:flex;
  flex-direction:column;
  gap:8px;
  margin-left:10px;
   @media (max-width: 991px) {
     gap:4px;
  }
`;

const Previous_control1 = styled.div`
  display:flex;
  flex-direction:row;
 
`;

const Previous_control1_mob = styled.div`
  display:flex;
  flex-direction:row;
 
`;

const Next_control1 = styled.div`
  display:flex;
  flex-direction:row;
  margin-right:30px;
 
 
`;

const Next_control1_mob = styled.div`
  display:flex;
  flex-direction:row;
 
 
 
`;

const Now_Playing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the child elements horizontally */
  justify-content: center; /* Centers the child elements vertically (if needed) */
  gap: 8px;
  margin-left: 50px; /* Adjust this or remove if not necessary for centering */
`;

const Now_Playing_mob = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the child elements horizontally */
  justify-content: center; /* Centers the child elements vertically (if needed) */
  gap: 8px;
  margin-left:20px;
 
`;

const Next_control = styled.div`
  display:flex;
  flex-direction:column;
  gap:8px;
  margin-left:50px;
 
`;

const Next_control_mob = styled.div`
  display:flex;
  flex-direction:column;
  gap:8px;
  margin-left:25px;
 
`;


const Left_play = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Ensures space between children */
  align-items: center; /* Vertically centers the items */
  margin-top:15px;
  margin-left:10px;
  margin-right:10px;
  margin-bottom:10px;
`;

const Right_play = styled.div`
  display: flex;
  flex-direction: row;
 
  margin-top:0px;
  margin-left:0px;
  margin-right:10px;
  margin-bottom:10px;
`;

const Header200 = styled.img`
  cursor: pointer;
 
  height:40px;
 

`;
const Header5 = styled.div`
  color: #fff;
  font: 550 15px Poppins, sans-serif;

  align-items: center;
  margin-left: 8px;
  @media (max-width: 991px) {
    font-size: 12px;
    margin-left: 5px;
  }
`;

const Header6 = styled.div`
  color: #fff;
  font: 550 15px Poppins, sans-serif;

  align-items: center;
  margin-left: 10px;
  @media (max-width: 991px) {
    font-size: 12px;
    margin-left: 5px;
    white-space: nowrap;
  }
`;

const DropdownContainer = styled.div`
  @media (max-width: 991px) {
    width: 50%;
  }
`;

const Select = styled.select`
  padding: 12px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #fff;
  background-color: #333232;
  color: white;
  text-align: left;
  width: 160px;
  appearance: none; /* Remove default arrow */

  /* Position the text and arrow */
  background-image: url(${down_drop_arrow}); /* Path to your arrow image */
  background-repeat: no-repeat;
  background-position: right 8px center; /* Adjust the position of the arrow */
  background-size: 15px; /* Size of the arrow */

  /* Flexbox for content alignment */
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:focus {
    border-color: #fff;
    outline: none;
  }

  @media (max-width: 991px) {
    font-size: 10px;
    width: auto;
    /* Adjusted padding to prevent text overlap with arrow */
    padding: 12px 16px 12px 8px; /* Right padding increased to make room for arrow */
    white-space: nowrap;
    justify-content: flex-start; /* Changed from 'left' to 'flex-start' */
    text-align: left;
    background-position: right 6px center; /* Slightly adjusted arrow position */
    background-size: 10px;
    margin-left: 5px;
    margin-right: 0px;
  }
`;

const Select1 = styled.select`
  height: 50px;
  padding: 12px; /* Reduced padding to decrease height */
  margin-left: 15px;
  margin-top: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #fff;
  background-color: #333232;
  color: white;
  text-align: center;
  width: 210px;
  appearance: none; /* Remove default arrow */

  /* Position the text and arrow */
  background-image: url(${down_drop_arrow}); /* Path to your arrow image */
  background-repeat: no-repeat;
  background-position: right 8px center; /* Adjust the position of the arrow */
  background-size: 15px; /* Size of the arrow */

  /* Flexbox for content alignment */
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:focus {
    border-color: #fff;
    outline: none;
  }
  @media (max-width: 991px) {
    font-size: 10px;
    width: auto;
    /* Adjusted padding to prevent text overlap with arrow */
    padding: 12px 16px 12px 8px; /* Right padding increased to make room for arrow */
    white-space: nowrap;
    justify-content: flex-start; /* Changed from 'left' to 'flex-start' */
    text-align: left;
    background-position: right 6px center; /* Slightly adjusted arrow position */
    background-size: 10px;
    margin-left: 5px;
    margin-right: 0px;
  }
`;

const DisplayText = styled.p`
  font-size: 18px;
  color: #333;
  margin-top: 15px;
`;

const Header61 = styled.div`
  color: #fff;
  font: 700 12px Poppins, sans-serif;
  text-align: center; /* Center text horizontally */
  display: flex; /* Use flexbox to center content */
  justify-content: center; /* Horizontal centering in flexbox */
  align-items: center; /* Vertical centering in flexbox */
  margin-top:3px;

  border-radius: 4px; /* Add border-radius */
  width: 50px;
  height:36px;
  padding: 0px;
  background-color: ${({ isActive }) => (isActive ? "#F0984B" : "#333333")};

 
`;

const FontSizeControls = styled.div`
  display: flex;
  gap: 10px;
  margin-left: 10px;
`;
const FontSizeButton1 = styled.button`
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? "blue" : "#242323")};
  color: ${(props) => (props.isActive ? "#fff" : "#fff")};
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  @media (max-width: 991px) {
    font-size: 10px;
  }
`;

const Header8 = styled.div`
  color: #fff;
  font: 400 12px Poppins, sans-serif;
  margin-top: 10px;
  letter-spacing: 0.28px;
  align-items: center;
  margin-left: 10px;
  @media (max-width: 991px) {
    font-size: 8px;
    margin-left: 5px;
  }
`;

const CJContainer = styled.div`
  position: relative; /* Make the parent position relative for absolute positioning of overlay */
  min-height: 350px;

  min-width: 900px;
  width: 100%;
  background-color: #282828;
  @media (max-width: 991px) {
    min-width: 600px;
  }
`;

// Styled component for the overlay
const Overlay1 = styled.div`
  position: absolute; /* Position it absolutely within the CJContainer */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  right: 0; /* Stretch to the right */
  bottom: 0; /* Stretch to the bottom */
  display: flex; /* Center the spinner */
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
  background-color: #282828;
  z-index: 1; /* Ensure it's above other content */
`;

const Header7 = styled.div`
  color: #fff;
  font: 550 15px Poppins, sans-serif;

  align-items: center;
  margin-left: 4px;
  margin-right: 4px;
  @media (max-width: 991px) {
    font-size: 12px;
    margin-left: 2px;
    margin-right: 2px;
  }
`;

const Rows = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center; /* Aligns items vertically in the center */
  width: 100%; /* Optional: makes Rows take full width */
   
`;

const RightSection = styled.div`
  display: flex;
  gap: 8px; /* Adjust gap between items if needed */
  align-items: center;
  margin-right:10px;
  margin-left: auto; /* Pushes RightSection to the far right */
`;

const Image1 = styled.img`
  cursor: pointer;
  margin-left: 10px;
`;

const Image1_mob = styled.img`
  cursor: pointer;
  margin-left: 10px;
   width:25px;
  height:25px;
`;


const Header_rowss= styled.div`
 display:flex;
 flex-direction:column;
 gap:4px;
`;

const Header_row_text = styled.div`
  color: #fff;
  font: 600 11px Poppins, sans-serif;
 
`;

const Image2 = styled.img`
  cursor: pointer;
  margin-left: 10px;
  margin-right: 20px;
`;
const Image3 = styled.img`
  cursor: pointer;
  margin-left: 5px;

  @media (max-width: 991px) {
    height: 33px;
  }
`;

const Image4 = styled.img`
  cursor: pointer;
  margin-left: 30px;
`;

const Con = styled.div`
  position: fixed;
  display: flex;
  bottom: 10px; /* Distance from the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust to truly center horizontally */
  z-index: 1000;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  flex-direction: row;
  padding: 5px;
 background-color: rgba(51, 50, 50, 0.75);
  border-radius: 8px; /* Optional rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
  height: 65px;
  width: 500px;
  margin-left: 50px;
 display:none;
  /* Mobile adjustments */
  @media (max-width: 991px) {
    width: 90%; /* Occupy 90% width on mobile */
    margin: 0 auto; /* Center horizontally on mobile */
    left: 50%; /* Keep horizontal centering */
    transform: translateX(-50%); /* Maintain true centering */
    padding: 5px;
    height: 10%;
    margin-left: 30px;
     display:none;
  }
`;




const Play_control = styled.div`
  
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  flex-direction: row;
  padding: 3px;
  margin-top:0px;
  background-color:#333232;
  border-radius: 4px; /* Optional rounded corners */
  margin-left:10px;
   margin-right:5px;
  height: 36px;
  width: auto;
  


`;


const SwitchInputChecked = styled(SwitchInput)`
  &:checked + ${Slider} {
    background-color: #f0984b;
  }

  &:checked + ${Slider}:before {
    transform: translateX(26px);
  }
`;

// const Con = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: row;
//   padding: 5px;
//   background-color: #333232;
//   border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   height: 65px;
//   width: 200px;

//   /* Mobile adjustments */
//   @media (max-width: 991px) {
//     width: 90%;
//     padding: 5px;
//     height: 10%;
//   }
// `;

const Header3 = styled.h3`
  color: #fff;
`;

const SongSection = styled.section`
  display: flex;
  
  margin-top: 10px;
  @media (max-width: 991px) {
    flex-direction: row;
  }
`;

const AlbumCover = styled.img`
  max-width: 350px;
  height: 131px;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100px;
    height: 70px;
    margin-left: 10px;
  }
`;

const Button_back = styled.img`
  max-width: 80px;
  cursor: pointer;
  margin-left: 20px;
  @media (max-width: 991px) {
    display: none;
  }
`;

const Button_back1 = styled.img`
  max-width: 35px;
  cursor: pointer;
  margin-left: 10px;
`;

const SongInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
   @media (max-width: 991px) {
    margin-top: 5px;
  }
`;

const SongInfo3 = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const Songs1 = styled.div`
  display: flex;
  flex-direction: row;
`;

const SongTitle = styled.h1`
  color: #fff;
  font: 600 18px/140% Poppins, sans-serif;
  letter-spacing: -0.28px;
  margin-left: 10px;
  @media (max-width: 991px) {
    font: 600 14px/140% Poppins, sans-serif;
    width: 70%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2; // Adjust line height if needed
    max-height: calc(1.2em * 2); // Line height * number of lines
    margin-top: 0px;
    margin-left: 10px;
  }
`;

const SongActions = styled.div`
  display: flex;
  align-items: center;
  gap: 0px; /* No gap between buttons */
  margin-top: 0px;
`;

const SongActions2 = styled.div`
  display: flex;
  align-items: center;
  gap: 0px; /* No gap between buttons */
  margin-top: 0px;
`;

const SongActions1 = styled.div`
  display: flex;
  align-items: center;
  gap: 0px; /* No gap between buttons */
  margin-top: 12px;
`;
const ButtonImage = styled.img`
  cursor: pointer; /* Ensures pointer cursor on hover */
  width:35px;
  height:35px;
`;

const Image10 = styled.img`
  cursor: pointer; /* Ensures pointer cursor on hover */
`;

const ButtonImage1 = styled.img`
  cursor: pointer; /* Ensures pointer cursor on hover */
  width:35px;
  height:35px;
`;

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  padding: 0px;
  background: transparent; /* Ensures button has no background */
  cursor: pointer;

  /* Ensures images inside this button have the correct size */
  img {
    height: 32px; /* Adjust size as needed */
  }
`;

const DownloadButton0 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  position: relative; /* Needed for positioning the tooltip */
  margin-left:5px;

`;

const ButtonImage0 = styled.img`
  cursor: pointer; /* Ensures pointer cursor on hover */
   width:35px;
  height:35px;
   @media (max-width: 991px) {
    width:25px;
  height:25px;
  }
`;

const ButtonImage01 = styled.img`
  cursor: pointer; /* Ensures pointer cursor on hover */
   width:25px;
  height:25px;
`;



const Tooltip = styled.div`
  position: absolute;
  bottom: 150%; /* Position above the button, adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  border: 2px solid #fff;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 1;
  font-size: 10px;
`;

const Imagearrow = styled.img`
  width: 20px;
  height: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute; /* Position relative to the button */
  top: -30px; /* Adjust position as needed */
  left: 50%;
  transform: translateX(-50%);
`;

const StyledDownloadButton = styled(DownloadButton0)`
  &:hover ${Tooltip} {
    opacity: 1;
  }

  &:hover ${Imagearrow} {
    opacity: 1;
  }
`;







const Headerloop1 = styled.img`
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-right: 7px;
  margin-bottom: 5px;
`;

const Tooltip1 = styled.div`
  position: absolute;
  top: 110%; /* Position below the button */
  left: 50%;
  transform: translateX(-50%);
   background-color: #333232;
  color: white;
  border: 1px solid #fff;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 1000;
  font-size: 10px;
`;

const Imagearrow1 = styled.img`
  width: 13px;
  height: 15px;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute; /* Position relative to the button */
  top: 53px; /* Adjust position to be below the tooltip */
  left: 40%;
  transform: translateX(-50%) rotate(180deg); /* Added rotation here */
`;


const StyledDownloadButton1 = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  &:hover ${Tooltip1} {
    opacity: 1;
  }
  &:hover ${Imagearrow1} {
    opacity: 1;
  }
`;



const CurrentValue = styled.div`
  color: #fff;
`;

const DownloadButton1 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  padding: 0px;
  background: transparent; /* Ensures button has no background */
  cursor: pointer;
  margin-left: 10px;

  img {
    height: 32px; /* Adjust size as needed */
  }
`;

const DownloadButton2 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  margin-left: 0px;
  background: transparent;
  cursor: pointer;

 
`;

const DownloadButton3 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  margin-left: 0px;
  background: transparent;
  cursor: pointer;

  
`;

const DownloadButton4 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  margin-left: 4px;
  background: transparent;
  cursor: pointer;

 
`;
const DownloadButton10 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  margin-left: 0px;
  background: transparent;
  cursor: pointer;

  img {
    height: 32px;
  }
`;

const LyricsSection = styled.section`
  margin-top: 10px;
`;

const LyricsSection1 = styled.section`
  margin-top: 32px;
  display: none;
`;

const ChordList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: left;
  margin-top: 0px;
  margin-left: 0px;
  position: relative; /* Added this line to enable absolute positioning of Ex_arrow */

  @media (max-width: 768px) {
    min-width: 900px;
    width: 100%; /* Ensure it stays full width on mobile */
  }
`;

const Verse = styled.div`
  margin-top: 24px;
  margin-right: 0px;
  color: #fff;
`;

const Sidebar = styled.aside`
  
  width: 25%;
  
  margin-left: 10px;
  justify-content: right;
  margin-top: 15px;
  margin-right: 10px;
   display: ${({ isMinimized }) => (isMinimized ? "block" : "none")};
  @media (max-width: 991px) {
    visibility: hidden; /* Hides it visually */
    position: absolute; /* Removes it from layout flow */
    width: 0; /* Reduces the sidebar width */
    height: 0; /* Removes height */
    overflow: hidden; /* Prevents scrollbars */
    pointer-events: none; /* Prevents interaction in mobile view */
  }
`;

const Ad = styled.div`
  background-color: #fff;
  border-radius: 2px;
  
`;

const RecentlyPlayedList = styled.ul`
  list-style: none;
  padding: 0;

  margin: 12px 0 0;
`;

const Con1 = styled.div`
  position: fixed;
  display: flex;
  bottom: 15px; /* Distance from the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust to truly center horizontally */
  z-index: 1000;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  flex-direction: column;
  padding: 5px;
  background-color: #312f2f; /* Optional background color */
  border-radius: 8px; /* Optional rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
  height: 85px;

  /* Mobile adjustments */
  @media (max-width: 991px) {
    width: 100%; /* Occupy 90% width on mobile */
    margin: 0 auto; /* Center horizontally on mobile */
    left: 50%; /* Keep horizontal centering */
    transform: translateX(-50%); /* Maintain true centering */
    padding: 5px;
    bottom: 0px;
    height: 65px;
  }
`;

const Con2 = styled.div`

   display: flex; /* Ensure flexbox layout */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  flex-direction: row; /* Align items in a row */
  margin-bottom: 5px; /* Space between Con2 and Header61 */
 
  }
`;

const Con3 = styled.div`

  width:45%;
  
   display: flex; /* Use flexbox layout */
  flex-direction: row; /* Align items in a row */
  justify-content: space-between; 
  }
`;

const Con4 = styled.div`
  width:55%;
  
   display: flex; /* Use flexbox layout */
  flex-direction: row; /* Align items in a row */
  justify-content: space-between; 
  }
`;

const RecentlyPlayedItem = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 12px;
  cursor: pointer;
`;

const SongThumbnail = styled.img`
  width: 78px;
  height: auto;
`;

const SongDetails = styled.div`
  flex-direction: column;
  justify-content: top;
`;

const SongName = styled.p`
  color: #fff;
  font: 600 14px/19px Poppins, sans-serif;
  letter-spacing: -0.2px;
  margin: 5;
  width: 380px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Ensure text stays on a single line */
`;

const TransposeButton1 = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? "#F0984B" : "#242323")};
  color: ${(props) => (props.isActive ? "#fff" : "#fff")};
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  @media (max-width: 991px) {
    padding: 8px 12px;
    font-size: 10px;
    white-space: nowrap;
  }
`;

const ButtonContainer1 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-left: 5px;
  margin-right: 10px;
`;

const Header51 = styled.div`
  color: #fff;
  font: 550 15px Poppins, sans-serif;

  align-items: center;
  margin-left: 10px;
  @media (max-width: 991px) {
    font-size: 12px;
    margin-left: 10px;
    margin-top: 7px;
    white-space: nowrap;
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;

const PopupOverlay1 = styled.div`
  z-index: 1000; /* Ensure it's above other content */
`;

const PopupOverlay3 = styled.div`
  position: fixed; /* Ensures the overlay covers the entire screen */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  width: 100vw; /* Full screen width */
  height: 100vh; /* Full screen height */
  z-index: 1000; /* Ensure it's above other content */
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent black for dimming effect */
  display: flex;
  justify-content: center; /* Center the popup horizontally */
  align-items: center; /* Center the popup vertically */
`;



const Container2 = styled.div`
  display: inline-block; /* Ensures content stays inline */
  white-space: nowrap; /* Prevent line wrapping */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Disable vertical scrolling */
  width: 100%; /* Take full width of the parent */
  flex-direction: column;
  min-width: 1300px;

  /* Add padding or margin as per your design needs */

  /* For mobile responsiveness */
  @media (max-width: 768px) {
    width: 100%; /* Ensure it stays full width on mobile */
  }
`;

const StyledImage = styled.img`
  display: inline-block;
  max-width: 120px;
  height: auto;
  margin: 0 10px;

  @media (max-width: 768px) {
    max-width: 80px;
  }
`;

const SpeedControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;
  margin-left: 10px;
  @media (max-width: 991px) {
    margin-left: 0px;
    gap: 4px;
    margin-right: 10px;
  }
`;

const SpeedButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? "blue" : "#242323")};
  color: ${(props) => (props.isActive ? "#fff" : "#fff")};
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  @media (max-width: 991px) {
    padding: 8px 12px;
    font-size: 10px;
    margin-left: 0px;
  }
`;

const Header_rows_row = styled.div`
  display: flex; /* Enable Flexbox */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  margin-left: 10px;
  
`;

const Header_bottom_row = styled.div`
  color: #fff;
  font: 700 12px Poppins, sans-serif;
  align-items: center; /* Not necessary but kept for clarity */
  text-align: center; /* Align text in the center */
   margin-top:3px;
`;

const Header_bottom_row_loop = styled.div`
  color: #fff;
  font: 700 12px Poppins, sans-serif;
  align-items: center; /* Not necessary but kept for clarity */
  text-align: center; /* Align text in the center */
   margin-top:1px;
`;
const Header_bottom_row_2 = styled.div`
  color: #fff;
  font: 700 12px Poppins, sans-serif;
  align-items: center; /* Not necessary but kept for clarity */
  text-align: center; /* Align text in the center */
   margin-top:7px;
`;

const Header_bottom_row_1 = styled.div`
  color: #fff;
  font: 700 12px Poppins, sans-serif;
  align-items: center; /* Not necessary but kept for clarity */
  text-align: center; /* Align text in the center */
  margin-top:3px;
`;

const Header_bottom_row_disabled = styled.div`
  color: #A4A4A4;
  font: 700 12px Poppins, sans-serif;
  align-items: center; /* Not necessary but kept for clarity */
  text-align: center; /* Align text in the center */
    margin-top:3px;
`;

const Header_bottom_row_disabled_loop = styled.div`
  color: #A4A4A4;
  font: 700 12px Poppins, sans-serif;
  align-items: center; /* Not necessary but kept for clarity */
  text-align: center; /* Align text in the center */
    margin-top:1px;
`;

const Header_bottom_row_disabled1 = styled.div`
  color: #A4A4A4;
  font: 700 12px Poppins, sans-serif;
  align-items: center; /* Not necessary but kept for clarity */
  text-align: center; /* Align text in the center */
  margin-top:3px;
`;

const Header_top_row = styled.img`
  cursor: pointer;
  width: 40px;
  height: 40px;
`;

const Header_top_row_loop = styled.img`
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-top:2px;
`;

const SpeedDisplay = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #222;
  color: white;
  border: 2px solid white;
  border-radius: 8px;
  font-size: 18px;
  margin: 0 5px;
`;

const TimelineContainer = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
`;

const TextContainer = styled.div`
  position: absolute;
  top: 0.5rem; // Adjust as needed
  right: 1rem;
  font-size: 0.75rem;
  color: white;
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const BoxedSpan = styled.span`
  cursor: pointer;
  background-color: #333232;
  padding: 0.25rem 0.5rem; // Padding for the box
  border: none; // Rounded corners
  font-weight: bold; // Optional for emphasis
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease; // Smooth transition effect on hover

  &:hover {
    background-color: rgba(255, 255, 255, 0.4); // Darken on hover
  }
`;

const TimeSegment = styled.div`
  position: relative;
  height: 65px;
  
  padding-bottom: 2rem;
  width: 95%;
  overflow: visible; /* Ensure elements don't get clipped */
`;


const TimeSegment100 = styled.div`
  position: relative;
  height: 55px;
  background-color: #282828;
  padding-bottom: 2rem;
  width: 95%;
  overflow: visible; /* Ensure elements don't get clipped */
`;

const CurrentTimeIndicator = styled.div`
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: red;
  top: 0;
  z-index: 10;
`;

const Divider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: white;
  z-index: 1;
`;

const TimeLabel = styled.div`
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  font-size: 0.75rem;
  color: white;
`;

const ItemContainer1 = styled.div`
  left: ${(props) => props.left}%;
  top: ${(props) => props.top}px;
  padding: 0.25rem 0.5rem;
  font-size: 15px;
  background: ${(props) => (props.isActive1 ? "#282828" : "#282828")};
  color: ${(props) => (props.type === "chord" ? "#F0984B" : "#ffffff")};
  z-index: 1; /* Base z-index */

  &:hover {
    z-index: 10; /* Increase z-index on hover */
  }
`;

const ItemContainer = styled.div`
  position: absolute;
  margin-left: 10px;
  left: ${(props) => props.left}%;
  top: ${(props) => props.top}px;
  padding: 0.25rem 0.5rem;
  font-size: 15px;
  background: ${(props) => (props.isActive1 ? "#282828" : "#282828")};
  color: ${(props) => (props.type === "chord" ? "#F0984B" : "#ffffff")};
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  z-index: 1; /* Base z-index */

  &:hover {
    z-index: 10; /* Increase z-index on hover */
  }
`;

const ItemContainer11 = styled.div`
  position: absolute;
  margin-left: 10px;
  left: ${(props) => props.left}%;
  top: ${(props) => props.top}px;
  padding: 0.25rem 0.5rem;
  font-size: 15px;
  background: ${(props) => props.isHighlighted ? "#F0984B" : "#282828"};
  color: ${(props) => (props.type === "chord" ? "#F0984B" : "#ffffff")};
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  z-index: 1;
  &:hover {
    z-index: 10;
  }
`;

const GripButton = styled.button`
  cursor: grab;
  opacity: 1;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

const Container = styled.div`
  display: inline-block; /* Ensures content stays inline */
 
  width: 100%; /* Take full width of the parent */
  flex-direction: column;
  margin-right:10px;
  min-height: 350px;
 overflow-x: hidden;

      ${({ isPortrait }) =>
    isPortrait &&
    css`
     overflow-x: auto;
      min-width: 900px;
    width: 100%; /* Ensure it stays full width on mobile */
    `}

  ${({ isLandscape }) =>
    isLandscape &&
    css`
       min-width: 100px; /* Override min-width to 100% */
      width: 100%; /* Force 100% width */
    `}

     overflow-y: auto; /* Enable vertical scrolling */
  position: relative; /* Ensure proper positioning for scroll */
  max-height:580px;

  /* Hide scrollbars */
  &::-webkit-scrollbar {
    display: none; /* For WebKit browsers */
  }
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  scrollbar-width: none; /* For Firefox */
`;





const Container10 = styled.div`
  display: inline-block; /* Ensures content stays inline */
  flex-direction: column;
  width: 100%;
  min-width: 900px;
  min-height: 350px;

  ${({ isPortrait }) =>
    isPortrait &&
    css`
      overflow-x: auto;
      min-width: 900px;
      width: 100%; /* Ensure it stays full width on mobile */
    `}

  ${({ isLandscape }) =>
    isLandscape &&
    css`
     overflow-x: hidden;
      min-width: 100px; /* Override min-width to 100% */
      width: 98%; /* Force 98% width */
    `}

  /* Define width for laptops */
  @media (min-width: 1024px) and (max-width: 1440px) {
    width: 95%; /* Customize the width for laptops */
    margin: 0 auto; /* Center the container */
  }
`;


// const Container100 = styled.div`
//   display: inline-block; /* Ensures content stays inline */
//   width: 100%;
//   min-width: 900px; /* Minimum width for the container */
//   min-height: 350px; /* Minimum height for the container */
//   overflow-y: auto; /* Enable vertical scrolling */
//   position: relative; /* Ensure proper positioning for scroll */

//   /* Hide scrollbars */
//   &::-webkit-scrollbar {
//     display: none; /* For WebKit browsers */
//   }
//   -ms-overflow-style: none; /* For Internet Explorer and Edge */
//   scrollbar-width: none; /* For Firefox */
// `;


const Container100 = styled.div`
  display: inline-block; /* Ensures content stays inline */
  width: 100%;
  
  min-height: 350px;
  max-height:580px; /* Set a maximum height for the container */
  overflow-y: auto; /* Enable vertical scrolling */
  position: relative; /* Ensure proper positioning for scroll */
 overflow-x: hidden;
  /* Hide scrollbars */
  &::-webkit-scrollbar {
    display: none; /* For WebKit browsers */
  }
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  scrollbar-width: none; /* For Firefox */
`;


const Line = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${(props) => (props.type === "words" ? "10px" : "")};
`;



const Box = styled.div`
  font-size: ${(props) => (props.type === "words" ? 15 : 15)}px;
  width: ${(props) => props.width}px;
  color: ${(props) => (props.type === "words" ? "#FFFFFF" : "#F0984B")};
  display: inline-block;
  padding: 3px;
  border: none;
  margin-bottom: ${(props) => (props.isGrouped ? "14px" : "4px")};
  white-space: nowrap;
  background-color: ${(props) => props.highlighted && "#F0984B"}; // Apply highlight color
`;


const Box1 = styled.div`
  font-size: ${(props) => (props.type === "words" ? 15 : 15)}px;
  width: ${(props) => props.width}px;
  color: ${(props) => (props.type === "words" ? "#FFFFFF" : "#F0984B")};
  display: inline-block;
  padding: 3px;
  border: none;
  margin-bottom: ${(props) => (props.isGrouped ? "14px" : "4px")};
  white-space: nowrap;
`;

const DownloadButton5 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  margin-left: 3px;
  background: transparent;
  cursor: pointer;

  img {
    height: 32px; /* Adjust size as needed */
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 185px;
  left: 200px;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
`;

const MenuItem = styled.div`
  padding: 12px 24px;
  cursor: pointer;
  color: #333;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const FontSizeButton = styled.button`
  background-color: #f0f0f0; /* Light background */
  border: 1px solid #ccc; /* Border for the button */
  color: #333; /* Button text color */
  padding: 8px 16px; /* Padding for the button */
  margin: 0 8px; /* Space between the buttons */
  font-size: 18px; /* Default font size for button text */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Show pointer on hover */

  &:hover {
    background-color: #e0e0e0; /* Slightly darker background on hover */
  }

  &:active {
    background-color: #d0d0d0; /* Even darker background when pressed */
  }
`;

const ImageContainer = styled.div`
margin-left:5px;
margin-top:5px;
  cursor: pointer;
`;

// const SpeedButton = styled.button`
//   background-color: #333; /* Dark background for contrast */
//   color: #fff; /* White text color */
//   border: none;
//   border-radius: 50%;
//   width: 40px;
//   height: 40px;
//   font-size: 18px;
//   cursor: pointer;
//   margin: 0 10px;
//   transition: background-color 0.2s ease;

//   &:hover {
//     background-color: #555; /* Change color slightly on hover */
//   }

//   &:active {
//     background-color: #111; /* Even darker when pressed */
//   }
// `;

const Select4 = styled.select`
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

const SelectWrapper_header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 140px;
  margin-left: 5px;
  margin-right:5px;
  height:36px;
 
  @media (max-width: 991px) {
   
    margin-left: 15px;
    
   
  }
`;


const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 991px) {
    min-width: 150px; /* Full width on mobile */
    margin-left: 15px;
    margin-right: 0;
    margin-top: 5px;
  }
`;



const SelectWrapper11 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 190px;
  margin-left: 15px;
  margin-top: 5px;
  @media (max-width: 991px) {
    min-width: 40px; /* Full width on mobile */
    width:70px;
    margin-left: 15px;
    margin-right: 0;
    margin-top: 5px;
  }
`;



const SelectWrapper111 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100px;
   height:36px;
  margin-left: 5px;
  

  @media (max-width: 991px) {
    min-width: 40px; /* Full width on mobile */
    width:70px;
    margin-left: 15px;
    margin-right: 0;
    margin-top: 5px;
  }
`;

const SelectWrapper3 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 190px;

  @media (max-width: 991px) {
    min-width: 160px; /* Full width on mobile */
  }
`;

const CustomOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
   @media (max-width: 991px) {
    font: 700 12px Poppins, sans-serif;
  }

  &:hover {
    background-color: #f0984b; /* Changes to orange on hover */
  }
`;

const CustomOption_header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
  font-size:12px;

  &:hover {
    background-color: #f0984b; /* Changes to orange on hover */
  }
`;

const CustomOption3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
  color: white;

  &:hover {
    background-color: #f0984b; /* Changes to orange on hover */
  }
`;

const DropdownContainer4 = styled.div`
  position: absolute;
  top: calc(100% - 8px);
  left: 0; /* Align with the left side of SelectButton */
  width: 100%;
  background-color: #333232;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
`;


const DropdownContainer_header = styled.div`
  position: absolute;
  top: 31px;
  left: 0; /* Align with the left side of SelectButton */
  width: 100%;
  background-color: #333232;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
`;

const DropdownContainer40 = styled.div`
  position: absolute;
  left: 0;
  margin-top: 50px;
  width: 100%;
  height: 100%;
  background-color: #333232;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;

  min-height: 300px; /* Adjust as per your requirement */
`;

/* Ensure the dropdown width fits within the desired range */
const DropdownContainer6 = styled.div`
  position: absolute;
  top: calc(100% - 8px);
  left: 0; /* Align with the left side of SelectButton */
  width: 100px;
  background-color: #333232;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
   min-width: 40px; /* Full width on mobile */
    width:70px;
`;


const DropdownContainer60 = styled.div`
  position: absolute;
  top: calc(100% - 8px);
  left: 0; /* Align with the left side of SelectButton */
  width: 100%;
  background-color: #333232;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
   
`;

const DropdownContainer5 = styled.div`
  position: absolute;
  top: calc(100%);
  left: 0; /* Align with the left side of SelectButton */
  width: 100%;
  background-color: #333232;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
`;

const OptionText = styled.span`
  flex-grow: 1;
  color: white;
`;

const OptionText_header = styled.span`
  flex-grow: 1;
  color: white;
  font-size:12px;
`;

const IconsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background-color: #e0e0e0;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const SelectButton = styled.button`
  
  padding: 12px; /* Reduced padding to decrease height */
  margin-left: 15px;
 
  font: 700 14px Poppins, sans-serif;
  padding:12px;
  border-radius: 4px;
  border: 1px solid #fff;
  background-color: #333232;
  color: white;
  text-align: center;
  width: 100%;
  appearance: none; /* Remove default arrow */
  cursor: pointer;

  /* Position the text and arrow */
  background-image: url(${down_drop_arrow}); /* Path to your arrow image */
  background-repeat: no-repeat;
  background-position: right 8px center; /* Adjust the position of the arrow */
  background-size: 15px; /* Size of the arrow */

  /* Flexbox for content alignment */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  &:focus {
    border-color: #fff;
    outline: none;
  }
`;


const Header_right = styled.div`
  
 
 
  font: 700 14px Poppins, sans-serif;
  margin-top: 3px;
 
  color: white;
  text-align: center;
 
`;



const SelectButton_header = styled.button`

   font: 700 12px Poppins, sans-serif;
  padding:12px;
  
  border-radius: 2px;
  border: 1px solid #fff;
  background-color: #333232;
  color: white;
  text-align: center;
  width: 100%;
  appearance: none; /* Remove default arrow */
  cursor: pointer;

  /* Position the text and arrow */
  background-image: url(${down_drop_arrow}); /* Path to your arrow image */
  background-repeat: no-repeat;
  background-position: right 8px center; /* Adjust the position of the arrow */
  background-size: 12px; /* Size of the arrow */

  /* Flexbox for content alignment */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  &:focus {
    border-color: #fff;
    outline: none;
  }
`;

const SelectButton40 = styled.button`
  height: 40px;
  padding: 12px; /* Reduced padding to decrease height */
  margin-left: 15px;
  margin-top: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #fff;
  background-color: #333232;
  color: white;
  text-align: center;
  width: 60px;
  appearance: none; /* Remove default arrow */
  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  &:focus {
    border-color: #fff;
    outline: none;
  }
`;



const InputText22 = styled.div`
  display: inline-block;
  background-color: #333232;
  font-size: 14px;
  color: #ffff;
  padding: 4px;
  min-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ width }) => width || "auto"};
  max-width: 100%; /* Prevent overflow horizontally */
  line-height: 1.5; /* Prevent vertical scroll within the container */
`;

const InputText20 = styled.div`
  display: flex; 
  align-items: center;
  background-color: #333232;
  width:auto;
  border: 1px solid #444;
  border-radius: 4px;
  overflow: hidden; /* Prevent page scrolling */
  max-width: 100%; /* Prevent overflow horizontally */
`;

const commonStyles = `
  display: inline-block;
  background-color: #333232;
  font-size: 15px;
  color: #f0984b;
  padding: 1px;
 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
`;

const InputText2 = styled.div`
  ${commonStyles}
  width: auto;
  max-width: 100%;
  word-wrap: break-word;
  
  /* Ensure content stays within container */
  overflow: hidden; 
  text-overflow: ellipsis;
  
  /* Remove default focus outline */
  outline: none;
  
  /* Prevent accidental scrolling */
  overscroll-behavior: contain;
`;




const commonStyles1 = `
  display: inline-block;
  background-color: #333232;
  font-size: 15px;
  color: #fff;
  padding: 1px;
 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
`;

const InputTextword = styled.div`
  ${commonStyles1}
  width: auto;
  max-width: 100%;
  word-wrap: break-word;
  
  /* Ensure content stays within container */
  overflow: hidden; 
  text-overflow: ellipsis;
  
  /* Remove default focus outline */
  outline: none;
  
  /* Prevent accidental scrolling */
  overscroll-behavior: contain;
`;




const StyledSpan = styled.span`
  display: inline-block;
  
  font-size: 14px;
  color: #f0984b;
  padding: 4px;
 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
`;



const HiddenText = styled.div`
  ${commonStyles}
  visibility: hidden;
  position: absolute;
  white-space: nowrap;
  pointer-events: none;
`;


const InputText10 = styled.div`
  display: flex; /* Ensures children are laid out in a row */
  align-items: center; /* Aligns items vertically in the center */
  background-color: #333232;
  padding: 8px; /* Optional, for spacing */
  gap: 8px; /* Adds spacing between children */
  border: 1px solid #444; /* Subtle border for better visibility */

  border-radius: 4px; /* Optional, rounded corners */
`;

const InputText1 = styled.input`
  width: 70px;
  background-color: #333232;
  font-size: 14px;
  color: #f0984b;
  padding: 4px; /* Add padding inside the input */
`;

const IconButton40 = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  margin-left:10px;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5; /* Indicate disabled state visually */
  }

  img {
    width: 20px;
    height: 20px;
   
  }
`;

const IconButton42 = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  margin-left:5px;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5; /* Indicate disabled state visually */
  }

  img {
    width: 22px;
    height: 22px;
   
  }
`;

const SelectButton_mob = styled.button`
  height: 50px;
  padding: 12px; /* Reduced padding to decrease height */
  margin-left: 15px;
  margin-top: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #fff;
  background-color: #333232;
  color: white;
  text-align: left;
  width: 100%;
  appearance: none; /* Remove default arrow */
  cursor: pointer;

  /* Position the text and arrow */
  background-image: url(${down_drop_arrow}); /* Path to your arrow image */
  background-repeat: no-repeat;
  background-position: right 8px center; /* Adjust the position of the arrow */
  background-size: 15px; /* Size of the arrow */

  /* Flexbox for content alignment */
  display: inline-flex;
  justify-content: left;
  align-items: center;
  margin: 0;

  &:focus {
    border-color: #fff;
    outline: none;
  }
`;

const Edit = styled.img``;

const Trash2 = styled.img``;

const ThumbUpIcon = styled.svg`
  cursor: pointer;
  margin-left: 10px;
`;

const ThumbdownIcon = styled.svg`
  cursor: pointer;
  margin-left: 30px;
`;

const Thumbs1 = styled.div`
  margin-top: 4px;
`;

const Chordsrow = styled.div`
 
  flex-direction: row;
`;

const Ex_arrow = styled.div`
  position: absolute;
  top: 0; /* Aligns to the top */
  right: 0; /* Aligns to the right */
  text-align: center;
  margin-top: 8px; /* You can adjust the margin to give some space from the top */
  margin-right: 8px; /* Adjust the margin for space from the right */
`;

const Retry2 = styled.div`
 
  color:white;
  font-size:14px;
  margin-bottom:10px;
  margin-top:3px;
   @media (max-width: 991px) {
    margin-top:8px;
  }
`;

const StyledDownloadButton1_0 = styled.div`
  position: relative;
  display: flex; /* Use Flexbox for centering */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  cursor: pointer;
  &:hover ${Tooltip1} {
    opacity: 1;
  }
  &:hover ${Imagearrow1} {
    opacity: 1;
  }
`;
const Header62 = styled.div`
  color: #A4A4A4;
  font: 700 12px Poppins, sans-serif;
  text-align: center; /* Center text horizontally */
  display: flex; /* Use flexbox to center content */
  justify-content: center; /* Horizontal centering in flexbox */
  align-items: center; /* Vertical centering in flexbox */
  margin-top:3px;

  border-radius: 4px; /* Add border-radius */
  width: 50px;
  height:36px;
  padding: 0px;
  background-color: #333333;

  @media (max-width: 991px) {
    font-size: 12px;
    margin-left: 0px;
    padding: 12px;
    width: 50px;
    margin-right: 15px;
  }
`;
const SelectWrapper1110 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100px;
   height:36px;
  margin-left: 15px;
  

 
`;
const SelectButton_1 = styled.button`
  height: 40px; /* Set height */
  padding: 0 12px; /* Adjust padding to keep content aligned */
  font: 700 12px Poppins, sans-serif;
    border-radius: 2px;
  border: 1px solid #333232;
 
  background-color: #333232;
  color: white;
  text-align: left; /* Align text to the left */
  width: 80px;
  appearance: none; /* Remove default dropdown arrow */
  cursor: pointer;

  /* Custom arrow */
  background-image: url(${down_drop_arrow}); /* Path to your arrow image */
  background-repeat: no-repeat;
  background-position: right 8px center; /* Position the arrow on the right */
  background-size: 12px; /* Size of the arrow */

  /* Flexbox for consistent alignment */
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:focus {
    border-color: #fff;
    outline: none;
  }
`;


const SelectButton_10 = styled.button`
  height: 40px; /* Set height */
  padding: 0 12px; /* Adjust padding to keep content aligned */
  font: 700 12px Poppins, sans-serif;
    border-radius: 2px;
  border: 1px solid #333232;
 
  background-color: #333232;
   color: #A4A4A4;
  text-align: left; /* Align text to the left */
  width: 80px;
  appearance: none; /* Remove default dropdown arrow */
  cursor: pointer;

  /* Custom arrow */
  background-image: url(${down_drop_arrow}); /* Path to your arrow image */
  background-repeat: no-repeat;
  background-position: right 8px center; /* Position the arrow on the right */
  background-size: 12px; /* Size of the arrow */

  /* Flexbox for consistent alignment */
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:focus {
    border-color: #fff;
    outline: none;
  }
`;




export default New_playlist;
