import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import {
  loginImage,
  jammin,
  close,
  sign_logo,
  divider,
  eyeon,
  eyeoff,
} from "../../assets/images"; // Import your eye icons
import Signup from "../signup/signup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import GoogleSignIn from "../../google"; // Adjust the path accordingly

function Login({ onClose }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isPasswordVisible, setPasswordVisible] = useState(false); // State for password visibility
  const [isSignupPopupVisible, setSignupPopupVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forgot_pwd, setforgot_pwd] = useState(false);
  const [reset_pwd, setreset_pwd] = useState(false);
  const [pwd, setpwd] = useState("");
  const [show1, setShow1] = useState(false);
  const [userEmailForReset, setUserEmailForReset] = useState(""); // This state will hold the email for password reset
  const [showResetButton, setShowResetButton] = useState(true);

  const navigate = useNavigate();

  const handleSignupClick = () => {
    // onClose();
    setSignupPopupVisible(true);
  };

  const handleshow1 = () => {
    setShow1(!show1);
  };

  const handleSignupClosePopup = () => {
    onClose();
    setSignupPopupVisible(false);
  };

  const handleforgot_pwd = () => {      
    setforgot_pwd(true);
  };
  const handleforgot_pwd1 = () => {
    setforgot_pwd(false);
  };

  const handlereset_pwd = () => {
    setreset_pwd(true);
  };
  const handlereset_pwd1 = () => {
    setreset_pwd(false);
  };

  const handleTogglePassword = (e) => {
    e.preventDefault();
    setPasswordVisible(!isPasswordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiUrl}/users/auth/login`,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (
        response.status === 200 &&
        response.data.user &&
        response.data.access_token
      ) {
        localStorage.setItem("access_token", response.data.access_token);
        if(response.data.user.free_ends_at){
          localStorage.setItem("freeTrialEnds",response.data.user.free_ends_at);
        }
        setLoading(false);
        navigate("/user");
      } else {
        setError("Invalid email or password.");
      }
    } catch (error) {
      setError(
        error.response?.data?.message || "An error occurred. Please try again."
      );
      setLoading(false);
    }
  };

  const isFormFilled = email.trim() !== "" && password.trim() !== "";

  function For_pwds({ onClose, setUserEmailForReset }) {
    const [error, setError] = useState("");
    const [email, setEmail] = useState(""); // Change pwd to email to better represent its use

    const handlechange = (e) => {
      const value = e.target.value;

      setEmail(value);
      setpwd(value);
    };
    const otpclick = async (e) => {
      e.preventDefault();

      try {
        const response = await axios.post(
          `${config.apiUrl}/users/auth/forgot`,
          { email: email },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setUserEmailForReset(email);
          setforgot_pwd(false);
          setreset_pwd(true);
        }
      } catch (error) {
        setError(
          error.response?.data?.message ||
            "An error occurred. Please try again."
        );
      }
      setpwd(email);
    };

    return (
      <VerificationContainer>
        <HeaderContainer>
          <HeaderText>Forgot Password ?</HeaderText>
          <CloseButton2 onClick={onClose}>
            <CloseIcon loading="lazy" src={close} alt="Close" />
          </CloseButton2>
        </HeaderContainer>
        <InstructionText>
          Forgot your password? No worries! Enter your email address and we'll
          send you a one-time password to reset your password
        </InstructionText>
        <form>
          <OTPInput
            type="email"
            id="email"
            placeholder="Enter Email"
            aria-label="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Update email state here
          />
          {error && <ErrorToast>{error}</ErrorToast>}
          <VerifyButton type="submit" onClick={otpclick}>
            Send OTP
          </VerifyButton>
        </form>
      </VerificationContainer>
    );
  }

  const ResetPassword = ({ onClose }) => {
    const [error, setError] = useState("");
    const [error1, setError1] = useState("");
    const [new_pwd, setnew_pwd] = useState("");
    const [repwd, setrepwd] = useState("");
    const [code, setcode] = useState("");
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const handleshow2 = () => {
      setShow2(!show2);
    };

    const handleshow3 = () => {
      setShow3(!show3);
    };

    const validatePassword = () => {
      return new_pwd === repwd;
    };

    const reset = async () => {
      if (validatePassword()) {
        try {
          const response = await axios.post(
            `${config.apiUrl}/users/auth/forgot_confirm`,
            {
              email: pwd,
              code: code,
              password: repwd,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 200) {
            setError1("Password changed successfully. Please Login again");
            setShowResetButton(false);
            setTimeout(() => {
              onClose();
            }, 5000);
          }
        } catch (error) {
          setError(
            error.response?.data?.message ||
              "An error occurred. Please try again."
          );
        }
      } else {
        setError("Passwords do not match.");
      }
    };

    return (
      // <Container4>
      <ResetPasswordContainer4>
        {/* <CloseButton4
            src={close}
            alt="Close button"
            onClick={onClose}
        />
        <Title4>Reset Password</Title4> */}
        <HeaderContainer1>
          <HeaderText1>Reset Password</HeaderText1>
          <CloseButton2 onClick={onClose}>
            <CloseIcon loading="lazy" src={close} alt="Close" />
          </CloseButton2>
        </HeaderContainer1>
        <InputRow11>
          {/* <PasswordInput4> */}
          <InputField11
            type="text"
            id="number"
            placeholder="Enter OTP"
            value={code}
            onChange={(e) => setcode(e.target.value)}
          />
          {/* </PasswordInput4> */}
        </InputRow11>
        <InputRow11>
          <InputField11
            type={show2 ? "text" : "password"}
            placeholder="Enter New Password"
            value={new_pwd}
            onChange={(e) => setnew_pwd(e.target.value)}
          />
          {show2 ? (
            <EyeIcon10 loading="lazy" onClick={handleshow2} src={eyeoff} />
          ) : (
            <EyeIcon10 loading="lazy" onClick={handleshow2} src={eyeon} />
          )}
        </InputRow11>

        {/* <InputGroup4>
          <PasswordInput4>
            <Input4
              type="password"
              id="text"
              placeholder="Enter New Password"
              value={new_pwd}
              onChange={(e) => setnew_pwd(e.target.value)}
            />
          </PasswordInput4>
        </InputGroup4> */}

        <InputRow11>
          <InputField11
            type={show3 ? "text" : "password"}
            placeholder="Re-enter New Password"
            value={repwd}
            onChange={(e) => setrepwd(e.target.value)}
          />
          {show3 ? (
            <EyeIcon10 loading="lazy" onClick={handleshow3} src={eyeoff} />
          ) : (
            <EyeIcon10 loading="lazy" onClick={handleshow3} src={eyeon} />
          )}
        </InputRow11>
        {/* <InputGroup4>
          <PasswordInput4>
            <Input4
              type="password"
              id="text"
              placeholder="Re-enter New Password"
              value={repwd}
              onChange={(e) => setrepwd(e.target.value)}
            />
          </PasswordInput4>
        </InputGroup4> */}

        {error && <ErrorToast>{error}</ErrorToast>}
        {error1 && <ErrorToast1>{error1}</ErrorToast1>}

        {/* {showResetButton && <ResetButton4 onClick={reset}>Reset Password</ResetButton4>} */}
        <ResetButton4 onClick={reset}>Reset Password</ResetButton4>
      </ResetPasswordContainer4>
      // </Container4>
    );
  };

  return (
    <MainContainer>
      <Overlay loading={loading}>
        <Spinner />
      </Overlay>
      <ContentContainer>
        <LeftColumn>
          <StyledImg loading="lazy" src={loginImage} />
        </LeftColumn>
        <RightColumn>
          <FormContainer>
            <Header>
              <CloseButton onClick={onClose}>
                <img loading="lazy" src={close} alt="Close" />
              </CloseButton>
              <LogoContainer>
                <LogoImg loading="lazy" src={sign_logo} alt="Logo" />
              </LogoContainer>
              <LoginTitle>Sign In to your account</LoginTitle>
              {config.apiUrl === "https://api.jammin.ai" && (
                <>
                  <Gbutton>
                    <div>
                      <GoogleSignIn/>
                    </div>
                  </Gbutton>
                  <DividerContainer>
                    <DividerIcon loading="lazy" src={divider} />
                    <DividerText>or</DividerText>
                    <DividerIcon loading="lazy" src={divider} />
                  </DividerContainer>
                </>
              )}
            </Header>
            <Form>
              <TextInput
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <InputRow10>
                <InputField10
                  type={show1 ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {show1 ? (
                  <EyeIcon11
                    loading="lazy"
                    onClick={handleshow1}
                    src={eyeoff}
                  />
                ) : (
                  <EyeIcon11 loading="lazy" onClick={handleshow1} src={eyeon} />
                )}
              </InputRow10>
              {/* <TogglePasswordButton onClick={handleTogglePassword}>
          <img src={isPasswordVisible ? eyeoff : eyeon} alt="Toggle Password Visibility" />
        </TogglePasswordButton> */}
              {/* </PasswordContainer> */}
              {error && <ErrorToast>{error}</ErrorToast>}
              <ForgotPasswordLink onClick={handleforgot_pwd}>
                Forgot Password?
              </ForgotPasswordLink>
              <LoginButton onClick={handleLogin} filled={isFormFilled}>
                Sign In
              </LoginButton>
              {/* <DividerContainer>
                <DividerIcon loading="lazy" src={divider} />
                <DividerText>or</DividerText>
                <DividerIcon loading="lazy" src={divider} />
              </DividerContainer>
              <Gbutton>
              <div>
                <GoogleSignIn />
              </div>
              </Gbutton> */}
              {/* <SocialLogin>
                <GoogleButton>
                  <img loading="lazy" src={google} alt="google" />
                </GoogleButton> */}
              {/* </SocialLogin> */}
              <SignupLink>
                Don’t have an account?{" "}
                <SignupNow onClick={handleSignupClick}>Signup</SignupNow>
              </SignupLink>
            </Form>
          </FormContainer>
        </RightColumn>
      </ContentContainer>
      {isSignupPopupVisible && (
        <PopupOverlay>
          <Signup onClose={handleSignupClosePopup} />
        </PopupOverlay>
      )}
      {forgot_pwd && (
        <PopupOverlay>
          <For_pwds
            onClose={handleforgot_pwd1}
            setUserEmailForReset={setUserEmailForReset} // Pass the email state setter to For_pwds
          />
        </PopupOverlay>
      )}
      {reset_pwd && (
        <PopupOverlay>
          <ResetPassword
            onClose={handlereset_pwd1}
            email={userEmailForReset} // Pass the email used in For_pwds to ResetPassword
          />
        </PopupOverlay>
      )}
    </MainContainer>
  );
}

const InputRow10 = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
  flex-direction: column;
  gap: 12px;
  margin-right: 10px;
  position: relative; /* Add this */
  @media (max-width: 991px) {
  }
`;

const InputField10 = styled.input`
  border: 1px solid #ececec;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 16px;
  padding: 17px 0px 17px 16px; /* Add padding-right to make space for the icon */
  color: #212529;
  font: 400 12px/22px Poppins, sans-serif;
  &:first-of-type {
    margin-top: 15px;
  }
  @media (max-width: 991px) {
    gap: 20px;
    margin-left: 20px;
    margin-right: 8px;
  }
`;

const InputRow11 = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
  flex-direction: column;
  gap: 15px;
  position: relative; /* Add this */
  margin-left: 15px;
  margin-right: 15px;
`;

const InputField11 = styled.input`
  border: 1px solid rgba(236, 236, 236, 1);
  border-radius: 4px;
  padding: 17px 16px;
  background-color: #fff;
  font-family: Poppins, sans-serif;
  padding-right: 50px; /* Add extra padding to make space for the eye icon */
`;

const EyeIcon10 = styled.img`
  position: absolute;
  right: 20px;
  top: 10px;
  width: 28px;
  height: 28px;
  cursor: pointer;
`;
const EyeIcon11 = styled.img`
  position: absolute;
  right: 30px;
  top: 30px;
  width: 28px;
  height: 28px;
  cursor: pointer;
`;

const Container4 = styled.main`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  max-width: 428px;
  min-width: 428px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  padding: 15px 16px 52px;
  margin: auto; /* Ensure the container is centered on the page */
  @media (max-width: 991px) {
    padding: 10px 8px;
    justify-content: center;
  }
`;

const ResetPasswordContainer4 = styled.div`
  min-width: 428px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
  @media (max-width: 991px) {
    padding: 10px;
    min-width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const Title4 = styled.h2`
  margin-bottom: 20px;
`;

const InputGroup4 = styled.div`
  margin-bottom: 25px;
  text-align: left;
`;

const CloseButton4 = styled.img`
  position: absolute;
  top: 15px;
  right: 19px;
  width: 34px; /* Adjust size as needed */
  height: 34px; /* Adjust size as needed */
  cursor: pointer;
`;

const Label4 = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
`;

const PasswordInput4 = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
`;

const Input4 = styled.input`
  flex: 1;
  padding: 17px 0px 17px 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const VisibilityButton4 = styled.button`
  margin-left: 10px;
  padding: 10px;
  border: none;
  background: #007bff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #0056b3;
  }
`;

const VerificationCode4 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

const VerificationInput4 = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  width: 100px;
`;

const VerifiedStatus4 = styled.span`
  margin-left: 10px;
  color: green;
  font-weight: bold;
`;

const ResetButton4 = styled.button`
  width: 40%;
  padding: 18px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;

  &:hover {
    background: #0056b3;
  }
`;

// const HeaderContainer = styled.header`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 1px;
//   font-size: 18px;
//   color: #0e0402;
//   font-weight: 600;
//   text-align: center;
//   letter-spacing: -0.14px;
//   line-height: 100%;
//   width: 100%;
//   margin-bottom:20px;
// `;

// const HeaderText = styled.div`
// font-size:20px;
// font-weight:600px;
//   font-family: Poppins, sans-serif;
//   text-align: center;
//   margin-left: 80px;
//   justify-content: center;
//    @media (max-width: 991px) {
//    font-size:20px;
//   }
// `;

const HeaderContainer1 = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  font-size: 18px;
  color: #0e0402;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.14px;
  line-height: 100%;
  width: 100%;
  margin-bottom: 10px;
`;

const HeaderText1 = styled.h1`
  font-family: Poppins, sans-serif;
  text-align: center;
  margin-left: 90px;
  justify-content: center;
`;

// const CloseIcon = styled.img`
//   aspect-ratio: 1;
//   object-fit: auto;
//   object-position: center;
//   width: 24px;
//   align-self: flex-start;
// `;

const InstructionText = styled.p`
  color: #0e0402;
  text-align: center;
  letter-spacing: -0.13px;
  margin-top: 1px;
  font: 13px/130% Poppins, sans-serif;
  width: 100%;
`;

const OTPInput = styled.input`
  border-radius: 4px;
  border: 1px solid #ececec;
  background-color: #fff;
  margin-top: 15px;
  color: #676767;
  padding: 17px 16px;
  font: 500 16px/137.5% "SF Pro Text", -apple-system, Roboto, Helvetica,
    sans-serif;
  width: 90%;
`;

const VerifyButton = styled.button`
  justify-content: center;
  border-radius: 4px;
  background-color: #0072d5;
  margin-top: 44px;
  color: #fff;
  text-align: center;
  padding: 17px 10px;
  font: 500 18px Inter, sans-serif;
  width: 80%;
  border: none;
  margin-left: 35px;
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 17px 10px;
    margin-bottom: 10px;
  }
`;

const VerificationContainer = styled.main`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  max-width: 428px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  padding: 15px 16px 52px;
  margin: auto; /* Ensure the container is centered on the page */
  @media (max-width: 991px) {
    padding: 10px 8px;
    justify-content: center;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  display: ${(props) => (props.loading ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;

const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 0px;
`;

const LogoContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px; // Add some space below the close button
`;

const CloseButton = styled.button`
  position: absolute;
  top: 28px;
  right: 12px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
`;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between; /* Changed to space-between */
  align-items: center;
  font-size: 18px;
  color: #0e0402;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.14px;
  line-height: 100%;
  width: 100%;
  margin-bottom: 20px;
  position: relative; /* Positioning context for centering */
  @media (max-width: 991px) {
    padding: 10px 8px;
    justify-content: center;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const HeaderText = styled.div`
  font-size: 20px;
  margin-top: 10px;
  font-weight: 600; /* Removed 'px' */
  font-family: Poppins, sans-serif;
  text-align: center;
  position: absolute; /* Center text with absolute positioning */
  left: 50%;
  transform: translateX(-50%); /* Center the text */

  @media (max-width: 991px) {
    font-size: 20px;
  }
`;

const CloseButton2 = styled.button`
  background: none;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  padding: 0; /* Adjusted padding to align it properly */
  margin-left: auto; /* Ensure button stays on the right */
`;

const CloseIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

// const CloseButton2 = styled.button`
//   background: none;
//   border: none;
//   cursor: pointer;
//   padding-left: 80px;
// `;
const LogoImg = styled.img`
  max-width: 150px;
`;

const MainContainer = styled.section`
  border-radius: 12px;
  background-color: #fff;
  width: 862px;
  height: 635px;
  margin: 0 auto;
  padding top: 10px;
  overflow: hidden;
  @media (max-width: 991px) {
   width: 96%;
   margin-left: 7px;
   margin-right: 7px;
   padding-top:45px;
   padding-bottom:45px;
   }
`;
const ErrorToast = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 8px;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const ErrorToast1 = styled.div`
  color: green;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 8px;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const DividerContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 16px;
  color: #ababab;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Gbutton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  white-space: nowrap;
  @media (max-width: 991px) {
  }
`;

const DividerIcon = styled.img`
  width: 69px;
  aspect-ratio: 1;
  border: 1px solid #ababab;
  object-fit: cover;
  object-position: center;
  height: 2px;
  background: none;
  border: none;
`;

const DividerText = styled.span`
  font-family: Inter, sans-serif;
  align-self: stretch;
  padding: 0 1px;
`;

// const CloseButton = styled.button`
//   position: absolute;
//   top: 0;
//   right: 0;
//   background: none;
//   border: none;
//   cursor: pointer;
// `;

const ContentContainer = styled.section`
  display: flex;
  height: 100%;
  @media (max-width: 991px) {
     justify-content:center;
  }
`;

const LeftColumn = styled.aside`
  flex: 1;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 991px) {
    display: none;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%; /* Ensure the image fills the entire space */
  object-fit: cover;
`;

const RightColumn = styled.section`
  flex: 1;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 991px) {
    width: 100%;
   }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0px;
  box-sizing: border-box;
  @media (max-width: 991px) {
    width:90%;
    justfy-content:center;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  @media (max-width: 991px) {
    display: flex;
    justfy-content:center;
    width: 100%;
    max-width:none;
  }
`;

// const Header = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: center;

//   width: 100%;
// `;

// const LogoContainer = styled.div`
//   position: relative;
//   display: flex;
//   justify-content: center;
//   width: 100%;
//   padding-left: 0px;
// `;

const LogoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`;

// const CloseButton = styled.button`
//   position: absolute;
//   top: 0;
//   right: 10px;
//   background: none;
//   border: none;
//   cursor: pointer;
// `;

// const LogoImg = styled.img`
//   max-width: 150px; /* Adjust size as needed */
//   margin-top: 0px; /* Adds space between the close button and the logo */
// `;
const LoginTitle = styled.div`
  font: 600 20px/24px Poppins, sans-serif;
  color: #0e0402;
  line-height: 24px;
  margin: 15px 0 0px 10px;
  margin-bottom: 0px;
  padding-right: 20px;
  text-align: center;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

// const Form = styled.form`
//   display: flex;
//   flex-direction: column;
//   padding:0px;
//   width:350px;
//   margin-right:10px;
//   @media (max-width: 991px) {
//     display: flex;
//     flex-direction: column;
//     margin-left: 10px;
//     margin-right: 10px;
//     gap: 20px;
//   }
// `;

const TextInput = styled.input`
  border: 1px solid #ececec;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 16px;
  padding: 17px 0px 17px 16px; /* Add padding-right to make space for the icon */
  color: #212529;
  margin-right: 5px;
  font: 400 12px/22px Poppins, sans-serif;
  &:first-of-type {
    margin-top: 15px;
  }
  @media (max-width: 991px) {
    gap: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const TextInput2 = styled.input`
  border: 1px solid #ececec;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 16px;
  padding: 17px 40px 17px 16px; /* Add padding-right to make space for the icon */
  color: #212529;
  margin-right: 10px;
  font: 400 12px/22px Poppins, sans-serif;
  &:first-of-type {
    margin-top: 34px;
  }
  @media (max-width: 991px) {
    gap: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const PasswordContainer = styled.div`
  position: relative;
  width: 100%;
`;

const TogglePasswordButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
`;
const ForgotPasswordLink = styled.a`
  color: #51606f;
  text-align: center;
  align-self: flex-end;
  margin-top: 12px;
  margin-right: 10px;
  font: 500 12px Poppins, sans-serif;
  cursor: pointer;
`;

const LoginButton = styled.button`
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.filled ? "#2373ED" : "#ececec")};
  margin-top: 16px;
  margin-left: 0px;
  padding: 17px 30px;
  margin-right: 10px;
  color: ${(props) => (props.filled ? "#ffff" : "#ababab")};
  font: 500 18px Inter, sans-serif;
  text-align: center;
  cursor: pointer;
  @media (max-width: 991px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const AlternativeLogin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 20px;
  color: #ababab;
  font-size: 16px;
  font-weight: 400;
  @media (max-width: 991px) {
    padding: 0 2px;
  }
`;

const SocialLogin = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-left: 2px;
`;

const GoogleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const FacebookButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    display: none;
  }
`;

const SignupLink = styled.p`
  text-align: center;
  color: #fffff;
  margin-top: 30px;
  margin-bottom: 70px;
  font: 400 12px/120% Poppins, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const SignupNow = styled.span`
  font-weight: 600;
  color: rgba(32, 201, 151, 1);
  cursor: pointer;
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;

export default Login;
