

export const getToken = () => localStorage.getItem('access_token');
export const getimgurl = () => localStorage.getItem('user_img_url');
export const getfirstname = () => localStorage.getItem('user_first_name');
export const getlastname = () => localStorage.getItem('user_last_name');
export const getemail = () => localStorage.getItem('user_email');
export const getsubscription = () => localStorage.getItem('subscription');
export const getsubscription_date = () => localStorage.getItem('subscription_date');
export const getsubscription_enddate = () => localStorage.getItem('subscription_enddate');
export const getsubscription_status = () => localStorage.getItem('subscription_status');
export const getsubscription_type = () => localStorage.getItem('subscription_type');
export const getsubscription_period = () => localStorage.getItem('period_end');
export const terms_accepted = () => localStorage.getItem('terms_accepted');


export const image_url = () => "https://jammin-stage.s3.amazonaws.com/images";


export const clearToken = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_img_url');
  };


  export const setLoadingStatus = (requestId, status) => {
    const currentStatus = JSON.parse(localStorage.getItem('loadingStatus')) || {};
    currentStatus[requestId] = status;
    localStorage.setItem('loadingStatus', JSON.stringify(currentStatus));
};

export const getLoadingStatus = () => {
    return JSON.parse(localStorage.getItem('loadingStatus')) || {};
};

export const removeLoadingStatus = (requestId) => {
    const currentStatus = JSON.parse(localStorage.getItem('loadingStatus')) || {};
    delete currentStatus[requestId];
    localStorage.setItem('loadingStatus', JSON.stringify(currentStatus));
};

export const languages = [
    { language: "Afrikaans", code: "af" },
    { language: "Albanian", code: "sq" },
    { language: "Amharic", code: "am" },
    { language: "Arabic", code: "ar" },
    { language: "Armenian", code: "hy" },
    { language: "Assamese", code: "as" },
    { language: "Azerbaijani", code: "az" },
    { language: "Bashkir", code: "ba" },
    { language: "Basque", code: "eu" },
    { language: "Belarusian", code: "be" },
    { language: "Bengali", code: "bn" },
    { language: "Bosnian", code: "bs" },
    { language: "Breton", code: "br" },
    { language: "Bulgarian", code: "bg" },
    { language: "Burmese", code: "my" },
    { language: "Catalan", code: "ca" },
    { language: "Chinese", code: "zh" },
    { language: "Croatian", code: "hr" },
    { language: "Czech", code: "cs" },
    { language: "Danish", code: "da" },
    { language: "Dutch", code: "nl" },
    { language: "English", code: "en" },
    { language: "Estonian", code: "et" },
    { language: "Faroese", code: "fo" },
    { language: "Finnish", code: "fi" },
    { language: "French", code: "fr" },
    { language: "Galician", code: "gl" },
    { language: "Georgian", code: "ka" },
    { language: "German", code: "de" },
    { language: "Greek", code: "el" },
    { language: "Gujarati", code: "gu" },
    { language: "Haitian Creole", code: "ht" },
    { language: "Hebrew", code: "he" },
    { language: "Hindi", code: "hi" },
    { language: "Hungarian", code: "hu" },
    { language: "Icelandic", code: "is" },
    { language: "Indonesian", code: "id" },
    { language: "Italian", code: "it" },
    { language: "Japanese", code: "ja" },
    { language: "Javanese", code: "jv" },
    { language: "Kannada", code: "kn" },
    { language: "Kazakh", code: "kk" },
    { language: "Khmer", code: "km" },
    { language: "Korean", code: "ko" },
    { language: "Lao", code: "lo" },
    { language: "Latvian", code: "lv" },
    { language: "Lithuanian", code: "lt" },
    { language: "Macedonian", code: "mk" },
    { language: "Malay", code: "ms" },
    { language: "Malayalam", code: "ml" },
    { language: "Maltese", code: "mt" },
    { language: "Marathi", code: "mr" },
    { language: "Mongolian", code: "mn" },
    { language: "Nepali", code: "ne" },
    { language: "Norwegian", code: "no" },
    { language: "Oriya (Odia)", code: "or" },
    { language: "Pashto", code: "ps" },
    { language: "Persian", code: "fa" },
    { language: "Polish", code: "pl" },
    { language: "Portuguese", code: "pt" },
    { language: "Punjabi", code: "pa" },
    { language: "Romanian", code: "ro" },
    { language: "Russian", code: "ru" },
    { language: "Serbian", code: "sr" },
    { language: "Sinhala", code: "si" },
    { language: "Slovak", code: "sk" },
    { language: "Slovenian", code: "sl" },
    { language: "Somali", code: "so" },
    { language: "Spanish", code: "es" },
    { language: "Sundanese", code: "su" },
    { language: "Swahili", code: "sw" },
    { language: "Swedish", code: "sv" },
    { language: "Tagalog", code: "tl" },
    { language: "Tamil", code: "ta" },
    { language: "Telugu", code: "te" },
    { language: "Thai", code: "th" },
    { language: "Turkish", code: "tr" },
    { language: "Ukrainian", code: "uk" },
    { language: "Urdu", code: "ur" },
    { language: "Uzbek", code: "uz" },
    { language: "Vietnamese", code: "vi" },
    { language: "Welsh", code: "cy" },
    { language: "Xhosa", code: "xh" },
    { language: "Yiddish", code: "yi" },
    { language: "Yoruba", code: "yo" },
    { language: "Zulu", code: "zu" }
]





 
 



